import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  Spinner,
  Accordion,
  ListGroup,
  Badge,
  Dropdown,
  DropdownButton,
  TabContainer,
  FloatingLabel,
  Breadcrumb,
  ButtonGroup,
  Tab,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires } from "../firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "firebase/firestore";
import Spreadsheet from "react-spreadsheet";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDFInvoiceTemplate, {
  OutputType,
  jsPDF,
} from "jspdf-invoice-template";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LineIcon,
  LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  WeiboShareButton,
  WeiboIcon,
} from "react-share";
import Switch from "react-switch";

import { PieChart } from "react-minimal-pie-chart";
import DateTimePicker from "react-datetime-picker";
import uuid from "react-uuid";

export default function EventManagementDashboard() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [setupSuccess, setSetupSuccess] = useState("");
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [registerSourceType, setRegisterSourceType] = useState({
    typeEng: 0,
    typeCheckIn: 0,
    typeTicket: 0,
    typePaid: 0,
    typeTodayTicket: 0,
    typeTodayTicketIncrease: 0,
  });
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]);

  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date()),
    address: {
      fullAddress: "",
    },
    pin: 0,
    status: "live",
    priceType: "FREE",
    price: 0,
    priceCurrency: "CAD",
    priceOriginal: "",
    user: {
      userOrg: "",
      imageURL: "",
    },
  });

  const [eventOptions, setEventOptions] = useState({
    comment: false,
    draw: false,
    max: 500,
    namecard: false,
    picture: false,
    poll: false,
    "register-feedback": false,
    review: true,
    registration: true,
    socialmedia: true,
  });

  const [allEvents, setAllEvents] = useState([]);
  const [uniqueMembers, setUniqueMembers] = useState([]);

  const [eventComments, setEventComments] = useState([]);
  const [eventLogs, setEventLogs] = useState([]);
  const [eventSponsors, setEventSponsors] = useState([]);
  const [eventAgendas, setEventAgendas] = useState([]);

  const [eventDraws, setEventDraws] = useState({});
  const [eventDrawId, setEventDrawId] = useState("");
  const [eventSearchableMembers, setEventSearchableMembers] = useState([]);
  const [eventExternalDrawMembers, setEventxternalDrawMembers] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [eventCheckInMembers, setEventCheckInMembers] = useState([]);
  const [eventTicketMembers, setEventTicketMembers] = useState([]);
  const [eventTickets, setEventTickets] = useState([]);
  const [eventDrawWinnerMembers, setEventDrawWinnerMembers] = useState([]);

  const [eventInvitees, setEventInvitees] = useState([]);
  const [eventInviteeEmails, setEventInviteeEmails] = useState([]);
  const [eventPics, setEventPics] = useState([]);

  const [showInvitees, setShowInvitees] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [showUnInvitees, setShowUnInvitees] = useState(false);
  const [showImportMembers, setShowImportMembers] = useState(false);

  //questions
  //questions
  const questionTitleRef = useRef();
  const questionDescRef = useRef();

  //additional questions
  const [questionList, setQuestionList] = useState([]);
  const [questionType, setQuestionType] = useState(0);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [questionRequired, setQuestionRequired] = useState(false);

  const [success, setSuccess] = useState(false);

  //event member import
  const [file, setFile] = useState();
  const [memberImportMessage, setMemberImportMessage] = useState("");
  const [memberArray, setMemberArray] = useState([]);
  const [memberHeaderSet, setMemberHeaderSet] = useState([]);
  const [eventImportMembers, setEventImportMembers] = useState([]);
  const [memberHeaderOptions, setMemberHeaderOptions] = useState({
    first_name: "",
    last_name: "",
    email: "",
    quantity: 1,
    memberKey: "",
    ticketId: "",
    ticket_id_index: -1,
    first_name_index: -1,
    last_name_index: -1,
    email_index: -1,
    quantity_index: -1,
    memberKey_index: -1,
  });

  const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  const fileReader = new FileReader();
  const handleCSVChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCSVSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const csvHeader = csvOutput
          .slice(0, csvOutput.indexOf("\n"))
          .split(",");
        const csvRows = csvOutput
          .slice(csvOutput.indexOf("\n") + 1)
          .split("\n");

        const array = csvRows.map((i) => {
          const values = i.split(",");
          const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
          }, {});
          return obj;
        });
        const headerKeys = Object.keys(Object.assign({}, ...array));

        setMemberHeaderSet(headerKeys);
        setMemberArray(array);
        setMemberHeaderOptions({
          first_name: "",
          last_name: "",
          email: "",
          first_name_index: -1,
          last_name_index: -1,
          email_index: -1,
        });
      };

      fileReader.readAsText(file);
    }
  };

  function registerTicket(ticket, eTitle, eId, eOrgName, userName, uid) {
    if (ticket === "") {
      return;
    }
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const ticketInfo = {
      validated: true,
      eventTitle: eTitle,
      eventId: eId,
      fromUID: uid,
      eventOrgName: eOrgName,
      userName,
      timestamp: timestampNow,
    };
    fires.collection("event_tickets").doc(ticket).set(ticketInfo);
    fires
      .collection("events")
      .doc(id)
      .collection("tickets")
      .doc(ticket)
      .set(ticketInfo);
  }

  const handleCSVMemberImport = () => {
    var cmemberCount = 1;
    var membersToImport = {};
    var emailsCollected = [];

    var currenttEmail = "";

    memberArray.map((item) => {
      var mvalues = [];
      var fName = "";
      var lName = "";
      var eemail = "";
      var cticketId = "";
      var tickets = [];

      mvalues = Object.values(item);
      //first name
      if (
        memberHeaderOptions.first_name_index >= 0 &&
        memberHeaderOptions.first_name_index < mvalues.length
      ) {
        if (mvalues[memberHeaderOptions.first_name_index] != null) {
          fName = mvalues[memberHeaderOptions.first_name_index];
          fName = fName.replace(/['"]+/g, "").trim();
        }
      }
      if (
        memberHeaderOptions.last_name_index >= 0 &&
        memberHeaderOptions.last_name_index < mvalues.length
      ) {
        if (mvalues[memberHeaderOptions.last_name_index] != null) {
          lName = mvalues[memberHeaderOptions.last_name_index];
          lName = lName.replace(/['"]+/g, "").trim();
        }
      }
      if (
        memberHeaderOptions.email_index >= 0 &&
        memberHeaderOptions.email_index < mvalues.length
      ) {
        if (mvalues[memberHeaderOptions.email_index] != null) {
          eemail = mvalues[memberHeaderOptions.email_index];
          eemail = eemail.replace(/['"]+/g, "").trim();
        }
      }
      if (
        memberHeaderOptions.ticket_id_index >= 0 &&
        memberHeaderOptions.ticket_id_index < mvalues.length
      ) {
        if (mvalues[memberHeaderOptions.ticket_id_index] != null) {
          cticketId = mvalues[memberHeaderOptions.ticket_id_index];
          cticketId = cticketId.replace(/['"]+/g, "").trim();
        }
      }

      if (eemail !== "") {
        if (emailsCollected.length === 0) {
          //first member
          currenttEmail = eemail;
          cmemberCount = 1;
          emailsCollected.push(eemail);
          membersToImport[eemail] = {
            email: eemail,
            firstName: fName,
            lastName: lName,
            count: cmemberCount,
            tickets: [cticketId],
          };
        } else {
          //next member
          if (currenttEmail === eemail) {
            var lastItem = membersToImport[eemail];
            var lastTickets = [];
            if (lastItem.tickets != null) {
              lastTickets = lastItem.tickets;
              lastTickets.push(cticketId);
            }
            lastItem["tickets"] = lastTickets;
            cmemberCount = cmemberCount + 1;
            lastItem["count"] = cmemberCount;
            membersToImport[eemail] = lastItem;
            currenttEmail = eemail;
          } else {
            currenttEmail = eemail;
            cmemberCount = 1;
            emailsCollected.push(eemail);
            membersToImport[eemail] = {
              email: eemail,
              firstName: fName,
              lastName: lName,
              count: cmemberCount,
              tickets: [cticketId],
            };
          }
        }
      }
    });

    var importMembers = Object.values(membersToImport).sort((a, b) => {
      return a.email >= b.email;
    });

    setEventImportMembers(importMembers);
    setMemberImportMessage(
      "已导入" + Object.keys(membersToImport).length + "个成员"
    );
  };

  const handleCSVMemberImportConfirmed = () => {
    if (eventImportMembers.length > 0) {
      eventImportMembers.forEach((memItm) => {
        if (memItm.email !== null && memItm.email !== "") {
          fires
            .collection("events")
            .doc(id)
            .collection("members")
            .doc(memItm.email)
            .get()
            .then((getSnap) => {
              if (getSnap.exists) {
                return;
              }
              var memCount = memItm.count;

              var cTicket = "";

              var tickets = [];
              if (memItm.tickets == null) {
                cTicket = createTicketId();
                tickets.push(cTicket);
                if (memCount > 1) {
                  for (let i = 0; i < memCount - 1; i++) {
                    let newT = createTicketId();
                    tickets.push(newT);
                  }
                }
              } else {
                tickets = memItm.tickets;
                if (tickets.length > 0) {
                  cTicket = tickets[0];
                }
              }

              var timestampNow = firebase.firestore.Timestamp.fromDate(
                new Date()
              );
              var memberSet = {
                email: memItm.email,
                firstName: memItm.firstName,
                lastName: memItm.lastName,
                name: memItm.firstName + " " + memItm.lastName,
                checkinStatus: false,
                handsup: false,
                thumbup: false,
                thumbdown: false,
                handsclap: false,
                ticketId: cTicket,
                invitedBy: "",
                tickets: tickets,
                quantity: memItm.count,
                wechat: "",
                work: "",
                timestamp: timestampNow,
              };
              fires
                .collection("events")
                .doc(id)
                .collection("draw-members")
                .doc(memItm.email)
                .set(memberSet);
              fires
                .collection("events")
                .doc(id)
                .collection("members")
                .doc(memItm.email)
                .set(memberSet)
                .then(() => {
                  registerTicket(
                    cTicket,
                    eventData.title,
                    id,
                    orgData.name,
                    memItm.firstName + " " + memItm.lastName,
                    ""
                  );
                });
            });
        }
      });
    }

    setShowImportMembers(false);
  };

  const eventInviteeEmailRef = useRef();
  const eventInviteeFirstNameRef = useRef();
  const eventInviteeLastNameRef = useRef();

  const eventMemberEmailRef = useRef();
  const eventMemberFirstNameRef = useRef();
  const eventMemberLastNameRef = useRef();
  const eventMemberInviteeEmailRef = useRef();
  const eventMemberNumberRef = useRef();
  const eventMemberTicketIdRef = useRef();
  const [eventMemberMessage, setEventMemberMessage] = useState("");
  const [eventMemberLanguageSelected, setEventMemberLanguageSelected] =
    useState("zh");

  const eventCheckinAddEmailRef = useRef();
  const eventCheckinAddFirstNameRef = useRef();
  const eventCheckinAddLastNameRef = useRef();

  const fullScreenHandle = useFullScreenHandle();

  const [links, setLinks] = useState([]);

  const [linkCounts, setLinkCounts] = useState({});

  const maxW = isMobile ? "100%" : "100%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;

  const [inviteeDisplayType, setInviteeDisplayType] = useState(0);

  const [orgData, setOrgData] = useState({});

  let { id } = useParams();

  const [eventFormData, setEventFormData] = useState([]);

  const [orderedBy, setOrderedBy] = useState("name");

  const [messageCounter, setMessageCounter] = useState(0);

  const [sponsorMessage, setSponsorMessage] = useState("");
  const [sponsorModifyIndex, setSponsorModifyIndex] = useState(-1);
  const [sponsorMofifySet, setSponsorModifySet] = useState({
    name: "",
    description: "",
    imageURL: "",
    order: -1,
    url: "",
  });
  //sponsor info
  const eventSponsorNameRef = useRef();
  const eventSponsorDescriptionRef = useRef();
  const eventSponsorBannerRef = useRef();
  const eventSponsorURLRef = useRef();

  const [agendaModifyIndex, setAgendaModifyIndex] = useState(-1);
  const [agendaMofifySet, setAgendaModifySet] = useState({
    title: "",
    description: "",
    location: "",
    date: firebase.firestore.Timestamp.fromDate(new Date()),
    order: -1,
  });
  //agenda
  const [agendaMessage, setAgendaMessage] = useState("");
  const eventAgendaTitleRef = useRef();
  const eventAgendaDescriptionRef = useRef();
  const eventAgendaRoomRef = useRef();
  const [eventAgendaTime, setEventAgendaTime] = useState(
    firebase.firestore.Timestamp.fromDate(new Date())
  );

  //emails
  const [eventEmailLang, setEventEmailLang] = useState("zh");
  const [eventEmailList, setEventEmailList] = useState([]);
  const [eventEmailCount, setEventEmailCount] = useState(0);
  const [eventEmailSaved, setEventEmailSaved] = useState({
    en: {
      title: "",
      content: "",
      emails: [],
    },
    zh: {
      title: "",
      content: "",
      emails: [],
    },
  });
  const [eventEmailSent, setEventEmailSent] = useState([]);
  const eventEmailTitleRef = useRef();
  const eventEmailContentRef = useRef();
  const [showEmailPreview, setShowEmailPreview] = useState(false);

  const [csvData, setCSVData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"],
  ]);

  const [csvEmailData, setCSVEmailData] = useState([]);
  const [csvExternalLuckyDrawData, setCSVExternalLuckyDrawData] = useState([]);

  const [csvViewsData, setCSVViewsData] = useState([]);

  var invoice_props = {
    outputType: OutputType.Save,
    returnJsPDFDocObject: true,
    fileName: "Invoice Template",
    orientationLandscape: false,
    compress: true,
    logo: {
      src: "",
      type: "PNG", //optional, when src= data:uri (nodejs case)
      width: 50, //aspect ratio = width/height
      height: 50,
      margin: {
        top: 0, //negative or positive num, from the current position
        left: 0, //negative or positive num, from the current position
      },
    },
    stamp: {
      inAllPages: true, //by default = false, just in the last page
      src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
      type: "JPG", //optional, when src= data:uri (nodejs case)
      width: 20, //aspect ratio = width/height
      height: 20,
      margin: {
        top: 0, //negative or positive num, from the current position
        left: 0, //negative or positive num, from the current position
      },
    },
    business: {
      name: "LETJOY INTERACTIVE",
      address: "4789 Yonge Street, Toronto, ON",
      phone: "+(1)647-000-0000",
      email: "admin@letjoy.app",
      email_1: "sam@letjoy.app",
      website: "https://letjoy.app",
    },
    contact: {
      label: "Invoice issued for:",
      name: "Client Name",
      address: "Albania, Tirane, Astir",
      phone: "(+355) 069 22 22 222",
      email: "client@website.al",
      otherInfo: "www.website.al",
    },
    invoice: {
      label: "Invoice #: ",
      num: 1000,
      invDate: "Payment Date: 01/01/2023 18:12",
      invGenDate: "Invoice Date: 02/02/2023 10:17",
      headerBorder: false,
      tableBodyBorder: false,
      header: [
        {
          title: "#",
          style: {
            width: 10,
          },
        },
        {
          title: "Title",
          style: {
            width: 30,
          },
        },
        {
          title: "Description",
          style: {
            width: 80,
          },
        },
        { title: "Price" },
        { title: "Quantity" },
        { title: "Unit" },
        { title: "Total" },
      ],
      table: Array.from(Array(10), (item, index) => [
        index + 1,
        "LETJOY EVENT",
        "LETJOY EVENT Ticket",
        0,
        1,
        "1",
        0,
      ]),
      additionalRows: [
        {
          col1: "Total:",
          col2: "145,250.50",
          col3: "ALL",
          style: {
            fontSize: 14, //optional, default 12
          },
        },
        {
          col1: "VAT:",
          col2: "20",
          col3: "%",
          style: {
            fontSize: 10, //optional, default 12
          },
        },
        {
          col1: "SubTotal:",
          col2: "116,199.90",
          col3: "ALL",
          style: {
            fontSize: 10, //optional, default 12
          },
        },
      ],
      invDescLabel: "Invoice Note",
      invDesc: "-",
    },
    footer: {
      text: "LETJOY INTERACTIVE ALL RIGHT RESERVED",
    },
    pageEnable: true,
    pageLabel: "Page ",
  };

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const routeToBack = () => {
    let path = "/";
    window.location.href = path;
  };

  function createTicketId() {
    const fNumber = Math.floor(1000 + Math.random() * 9000);
    const sNumber = Math.floor(1000 + Math.random() * 9000);
    const tNumber = Math.floor(1000 + Math.random() * 9000);
    const ttNumber = Math.floor(1000 + Math.random() * 9000);
    const idString =
      fNumber.toString() +
      "-" +
      sNumber.toString() +
      "-" +
      tNumber.toString() +
      "-" +
      ttNumber.toString();
    return idString;
  }

  function createTicketPDF(ticketId) {
    //add front and line
    if (ticketId !== "") {
      var pdfObject = jsPDFInvoiceTemplate(invoice_props);
    }
  }

  function registerTicket(ticket, eTitle, eId, eOrgName) {
    if (ticket === "") {
      return;
    }
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var ticketInfo = {
      validated: true,
      eventTitle: eTitle,
      eventId: eId,
      eventOrgName: eOrgName,
      timestamp: timestampNow,
    };
    fires.collection("event_tickets").doc(ticket).set(ticketInfo);
  }

  function sendEmailTo(
    email,
    orgName,
    eventTitle,
    eventStartDate,
    eventAddress,
    userName,
    ticketId,
    eventId,
    ticketIds
  ) {
    //send english

    const messageSubject =
      "Event from <" + orgName + ">" + " has been scheduled - " + eventTitle;

    const htmlSubject =
      "<h2>" + orgName + " event has been scheduled successfully</h2>";
    const body01 = "<p>Hello " + userName + ",</p><br>";
    const body02 =
      "<p>You have been successfully scheduled event <b>" +
      eventTitle +
      "</b>. Please note your ticket ID is「" +
      ticketId +
      "」</p>";
    const body03 = "<p>The following is the event information</p>";
    const body04 = "<p>Date/Time: <b>" + eventStartDate + "</b></p>";
    const body05 = "<p>Address: <b>" + eventAddress + "</b></p>";
    const body06 = "<p>Ticket: <b>" + ticketId + "</b></p>";
    const body07 =
      "<p>Please visit <a href='https://tickets.evtgo.com/" +
      eventId +
      "/en" +
      "'>Event Link</a></p><br>";
    const body08 =
      "<p>Discover more event like this，please download <a href='https://leyuan.page.link/app'>Letjoy APP</a></p>";
    const body09 = "<br><p><b>" + orgName + "</b></p>";

    const htmlString =
      htmlSubject +
      body01 +
      body02 +
      body03 +
      body04 +
      body05 +
      body06 +
      body07 +
      body08 +
      body09;

    if (email != "") {
      const messageObj = {
        subject: messageSubject,
        html: htmlString,
      };
      fires
        .collection("mail")
        .add({
          to: [email],
          message: messageObj,
        })
        .then((docRef) => {
          const sentId = docRef.id;
          fires
            .collection("events")
            .doc(id)
            .collection("members")
            .doc(email)
            .update({ mailSentId: sentId });
        });
    }

    // const messageSubject = "<" + orgName + ">"+"的活动 - "+eventTitle+" 马上就要开始啦"

    // const htmlSubject = "<h2>您即将参加" + orgName + "的活动<b>"+eventTitle+"</b></h2>"
    // const body01 = "<p>您好 " + userName + ",</p><br>"
    // const body02 = "<p>您的活动<b>" + eventTitle + "</b>马上就要开始啦，收到这封信意味着您将参加活动并有机会参与抽奖。请认准本次的门票号码「" + ticketId + "」</p>"
    // const body03 = "<p>以下是本次活动的详细信息</p>"
    // const body04 = "<p>时间: <b>" + eventStartDate + "</b></p>"
    // const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
    // const body06 = "<p>门票: <b>" + ticketId + "</b></p>"
    // const bodyAdd = "<p>门票数量: <b>" + (ticketIds.length+1) + "</b></p>"
    // var ticketBody = ""
    // ticketIds.map((ticketN) => {
    //     ticketBody += "<p> "+ticketN+" </p>"
    // })
    // const body07 = "<h4>详细内容请点击<a href='https://tickets.evtgo.com/" + eventId  +"'>活动链接</a>并于活动当天展示此信息签到</h4><br>"
    // const body08 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
    // const body09 = "<br><h3><b>" + orgName + "</b></h3>"
    // const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + bodyAdd + body06 + ticketBody + body07 + body08 + body09

    // if (email != ''){
    //     const messageObj = {
    //         'subject':messageSubject,
    //         'html':htmlString
    //     }
    //     fires
    //     .collection('mail')
    //     .add({
    //         'to':[email],
    //         'message':messageObj
    //     }).then((docRef)=>{
    //         const sentId = docRef.id
    //         fires
    //         .collection('events')
    //         .doc(id)
    //         .collection('members')
    //         .doc(email)
    //         .update({mailSentId: sentId})
    //     })
    //   }
  }

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const isYesterday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() - 1 &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  function loadAllLogsRealtime() {
    //logs
    fires
      .collection("events")
      .doc(id)
      .collection("logs")
      .orderBy("timestamp", "desc")
      .limit(20)
      .onSnapshot((snapshotA) => {
        var allLogs = [];
        snapshotA.docs.forEach((doc) => {
          const logId = doc.id;
          const logData = doc.data();
          allLogs.push({ ...logData, id: logId });
        });
        setEventLogs(allLogs);
      });
  }

  function loadAllMessages() {
    //messages
    fires
      .collection("events")
      .doc(id)
      .collection("messages")
      .get()
      .then((snapshotA) => {
        var allComments = [];
        snapshotA.docs.forEach((doc) => {
          const commentId = doc.id;
          const commentData = doc.data();

          var userName = "";
          var profileURL = "";
          if (commentData.fromName != null) {
            userName = commentData.fromName;
          }
          if (commentData.profileURL != null) {
            profileURL = commentData.profileURL;
          }

          allComments.push({
            ...commentData,
            id: commentId,
            fromName: userName,
            profileURL: profileURL,
          });
        });
        setEventComments(allComments);
      });
  }

  function loadAllPics() {
    //pictures
    fires
      .collection("events")
      .doc(id)
      .collection("pictures")
      .get()
      .then((snapshotA) => {
        var allPics = [];
        snapshotA.docs.forEach((doc) => {
          const picId = doc.id;
          const picData = doc.data();
          allPics.push({ ...picData, id: picId });
        });
        setEventPics(allPics);
      });
  }

  function loadInviteeRealtime() {
    //invitees
    fires
      .collection("events")
      .doc(id)
      .collection("invitees")
      .onSnapshot((snapshotA) => {
        var allInvitees = [];
        var allEmails = [];
        snapshotA.docs.forEach((doc) => {
          const inviteeEmail = doc.id;
          const inviteeData = doc.data();
          allInvitees.push({ ...inviteeData, email: inviteeEmail });
          allEmails.push(inviteeEmail);
        });
        setEventInvitees(allInvitees);
        setEventInviteeEmails(allEmails);
      });
  }

  function loadExternalDrawMembers() {
    fires
      .collection("events")
      .doc(id)
      .collection("draw-members")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshotAA) => {
        var drawMs = [];
        var csvLuckyD = [];
        csvLuckyD.push([
          "name",
          "first-name",
          "last-name",
          "email",
          "phone",
          "gender",
          "ticket",
        ]);
        snapshotAA.docs.forEach((doc) => {
          const emailId = doc.id;
          const drawMData = doc.data();
          drawMs.push({ ...drawMData });

          var nname = "";
          var nfirstName = "";
          var nlastName = "";
          var ngender = "";
          var nemail = "";
          var nphone = "";
          var nticket = "";

          if (drawMData.name != null) {
            nname = drawMData.name;
          }
          if (drawMData.firstName != null) {
            nfirstName = drawMData.firstName;
          }
          if (drawMData.lastName != null) {
            nlastName = drawMData.lastName;
          }
          if (drawMData.email != null) {
            nemail = drawMData.email;
          }
          if (drawMData.gender != null) {
            ngender = drawMData.gender;
          }
          if (drawMData.phone != null) {
            nphone = drawMData.phone;
          }
          if (drawMData.ticket != null) {
            nticket = drawMData.ticket;
          }

          csvLuckyD.push([
            nname,
            nfirstName,
            nlastName,
            emailId,
            nphone,
            ngender,
            nticket,
          ]);
        });
        setEventxternalDrawMembers(drawMs);
        setCSVExternalLuckyDrawData(csvLuckyD);
      });
  }

  function loadEventLogs() {
    fires
      .collection("events")
      .doc(id)
      .collection("logs")
      .get()
      .then((snapshotAA) => {
        var logs = [];
        snapshotAA.docs.forEach((doc) => {
          const logId = doc.id;
          const logData = doc.data();
          logs.push({ ...logData });
        });
      });
  }

  function loadEventForms() {
    fires
      .collection("events")
      .doc(id)
      .collection("forms")
      .get()
      .then((snapshotAA) => {
        var forms = [];
        snapshotAA.docs.forEach((doc) => {
          const formId = doc.id;
          const formData = doc.data();
          forms.push({ ...formData, id: formId });
        });
        forms.sort((a, b) => {
          return a.order - b.order;
        });
        setEventFormData(forms);
        setQuestionList(forms);
      });
  }

  function loadAllMembersRealTime(filterBy, orderBy) {
    //members
    fires
      .collection("events")
      .doc(id)
      .collection("tickets")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshot_tickets) => {
        var tickets = [];
        snapshot_tickets.docs.forEach((ticketItm) => {
          const ticketData = ticketItm.data();
          tickets.push(ticketData);
        });
        setEventTickets(tickets);
      });

    fires
      .collection("events")
      .doc(id)
      .collection("members")
      .orderBy(filterBy, orderBy)
      .onSnapshot((snapshotA) => {
        var allMembers = [];
        var checkinMembers = [];
        var ticketMembers = [];
        var drawWinnerMembers = [];

        var registerSources = registerSourceType;
        var registerSourceEng = 0;
        var registerSourceCheckIn = 0;
        var registerSourceTicket = 0;
        var registerSourcePaid = 0;
        var registerSourceToday = 0;
        var registerSourceYesterday = 0;

        snapshotA.docs.forEach((doc) => {
          const likeId = doc.id;
          const likeData = doc.data();

          var userName = "";
          var userProfileURL = "";
          if (likeData.userName != null) {
            userName = likeData.userName;
          }
          if (likeData.userProfileURL != null) {
            userProfileURL = likeData.userProfileURL;
          }

          if (likeData.timestamp != null) {
            let ticketDate = likeData.timestamp.toDate();
            if (isToday(ticketDate)) {
              registerSourceToday += 1;
            }
            if (isYesterday(ticketDate)) {
              registerSourceYesterday += 1;
            }
          }

          if (likeData.checkinStatus != null) {
            if (likeData.checkinStatus) {
              checkinMembers.push({ ...likeData });
              registerSourceCheckIn += 1;
            }
          }
          if (likeData.ticketId != null) {
            ticketMembers.push({ ...likeData });
            registerSourceTicket += 1;
          }
          if (likeData.drawOptions != null) {
            if (
              likeData.drawOptions != "" ||
              likeData.drawOptions != "no-show"
            ) {
              drawWinnerMembers.push({ ...likeData });
            }
          }

          if (likeData.lang != null) {
            if (likeData.lang != "") {
              if (likeData.lang == "en") {
                registerSourceEng += 1;
              }
            }
          }

          if (likeData.paid != null) {
            if (likeData.paid) {
              registerSourcePaid += 1;
            }
          }

          allMembers.push({ ...likeData });
        });
        setEventMembers(allMembers);
        setEventCheckInMembers(checkinMembers);
        setEventTicketMembers(ticketMembers);
        setEventDrawWinnerMembers(drawWinnerMembers);

        registerSources.typeEng = registerSourceEng;
        registerSources.typeCheckIn = registerSourceCheckIn;
        registerSources.typeTicket = registerSourceTicket;
        registerSources.typePaid = registerSourcePaid;
        registerSources.typeTodayTicket = registerSourceToday;
        registerSources.typeTodayTicketIncrease =
          registerSourceToday - registerSourceYesterday;

        setRegisterSourceType(registerSources);

        var memberData = [];
        memberData.push([
          "name",
          "first_name",
          "last_name",
          "email",
          "check_in",
          "quantity",
          "ticket_id",
          "register_time",
          "checkin_time",
          "checkin_source",
          "lang",
          "invitedBy",
          "work",
          "wechat",
          "url",
          "form-question",
        ]);
        allMembers.map((memItm) => {
          var checki = "no";
          if (memItm.checkinStatus) {
            checki = "yes";
          }
          var checkinTime = "";
          var registerTime = "";
          var lang = "zh";
          var invitedBy = "";
          var checkinSource = "";
          var forms = [];
          var formqna = "";

          if (memItm.checkinTimestamp != null) {
            checkinTime = memItm.checkinTimestamp.toDate().toLocaleString("zh");
          }
          if (memItm.timestamp != null) {
            registerTime = memItm.timestamp.toDate().toLocaleString("zh");
          }
          var quantity = 1;
          if (memItm.quantity != null) {
            quantity = memItm.quantity;
          }
          if (memItm.lang != null) {
            lang = memItm.lang == "" ? "zh" : memItm.lang;
          } else {
            lang = "zh";
          }
          if (memItm.source != null) {
            checkinSource = memItm.source;
          }
          if (memItm.invitedBy != null) {
            if (memItm.invitedBy != "" && memItm.invitedBy != memItm.email) {
              invitedBy = memItm.invitedBy;
            } else {
              invitedBy = "";
            }
          } else {
            invitedBy = "";
          }
          const userUrl = "https://tickets.evtgo.com/user/" + memItm.email;

          if (memItm.forms != null) {
            forms = memItm.forms;
            forms.forEach((formItm) => {
              formqna += formItm.title + " - " + formItm.value + "\n";
            });
          }

          memberData.push([
            memItm.name,
            memItm.firstName,
            memItm.lastName,
            memItm.email,
            checki,
            quantity,
            memItm.ticketId,
            registerTime,
            checkinTime,
            checkinSource,
            lang,
            invitedBy,
            memItm.work,
            memItm.wechat,
            userUrl,
            formqna,
          ]);
        });
        setCSVData(memberData);
      });
  }

  function loadAllViews() {
    //views
    fires
      .collection("events")
      .doc(id)
      .collection("views")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshotA) => {
        var allViews = [];
        var todayView = [];

        var viewTypes = [];

        var csvViewTempData = [];
        csvViewTempData.push(["ua", "device", "platform", "timestamp", "lang"]);

        snapshotA.docs.forEach((doc) => {
          const viewId = doc.id;
          const viewData = doc.data();
          var uaString = "";
          var deviceString = "";
          var platformString = "";
          var timestampString = "";
          var langStr = "";

          if (viewData.fromUA != null) {
            if (viewData.fromUA.includes("safari")) {
              uaString = "Safari浏览器";
            } else if (viewData.fromUA.includes("micromessenger")) {
              uaString = "微信";
            } else if (viewData.fromUA.includes("instagram")) {
              uaString = "Instagram应用";
            } else if (viewData.fromUA.includes("fb")) {
              uaString = "Facebook应用";
            } else if (viewData.fromUA.includes("linkedin")) {
              uaString = "领英应用LinkedIn";
            } else if (viewData.fromUA.includes("snapchat")) {
              uaString = "Snapchat";
            } else if (viewData.fromUA.includes("twitter")) {
              uaString = "Twitter";
            } else {
              uaString = "其他";
            }
          }
          if (viewData.device != null) {
            deviceString = viewData.device;
          }
          if (viewData.platform != null) {
            platformString = viewData.platform;
          }
          if (viewData.lang != null) {
            langStr = viewData.lang;
          }
          if (viewData.timestamp != null) {
            timestampString = viewData.timestamp.toDate().toLocaleString();
          }

          allViews.push({ ...viewData, vid: viewId, uaString: uaString });
          csvViewTempData.push([
            uaString,
            deviceString,
            platformString,
            timestampString,
            langStr,
          ]);
        });

        setCSVViewsData(csvViewTempData);

        var wx = 0;
        var linkedin = 0;
        var brows = 0;
        var inst = 0;
        var fb = 0;

        allViews.forEach((vItm) => {
          const vItmDate = vItm.timestamp.toDate();
          const vURL = vItm.linkId;
          var vUA = "";
          var uaString = "";

          if (vItm.fromUA != null) {
            vUA = vItm.fromUA;

            if (vUA.includes("safari")) {
              uaString = "Safari浏览器";
              brows += 1;
            } else if (vUA.includes("micromessenger")) {
              uaString = "微信";
              wx += 1;
            } else if (vUA.includes("instagram")) {
              uaString = "Instagram应用";
              inst += 1;
            } else if (vUA.includes("fb")) {
              uaString = "Facebook应用";
              fb += 1;
            } else if (vUA.includes("linkedin")) {
              uaString = "领英应用LinkedIn";
              linkedin += 1;
            } else {
            }
          }
        });

        viewTypes.push(wx);
        viewTypes.push(linkedin);
        viewTypes.push(brows);
        viewTypes.push(inst);
        viewTypes.push(fb);

        setViewTypes(viewTypes);

        setViews(allViews);

        setTodayViews(todayView);
      });
  }

  async function loadSuperAdminEventInformation() {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshotB) => {
        if (snapshotB.exists) {
          var aData = {};
          var artiData = snapshotB.data();
          aData = artiData;
          setEventData(aData);

          var draws = {};
          if (artiData.drawItems != null) {
            draws = artiData.drawItems;
          }
          if (Object.keys(draws).length > 0){
            const draId = Object.keys(draws)[0]
            setEventDrawId(draId)
          }

          setEventDraws(draws);

          var optionss = eventOptions;
          if (artiData.options != null) {
            optionss = artiData.options;
          }
          setEventOptions(optionss);

          var sponsorss = eventSponsors;
          if (artiData.sponsor != null) {
            sponsorss = artiData.sponsor;
          }
          setEventSponsors(sponsorss);

          var agendass = eventAgendas;
          if (artiData.agenda != null) {
            agendass = artiData.agenda;
          }
          setEventAgendas(agendass);

          loadEventForms();

          const orgId = aData.orgId;
          if (orgId != null) {
            if (orgId != "") {
              fires
                .collection("groups")
                .doc(orgId)
                .get()
                .then((snapshotA) => {
                  if (snapshotA.exists) {
                    const orgD = snapshotA.data();
                    setOrgData({ ...orgD });
                    loadAllMessages();
                    loadAllPics();
                    loadAllViews();
                    loadExternalDrawMembers();

                    //real-time
                    loadInviteeRealtime();
                    loadAllLogsRealtime();
                    loadAllMembersRealTime("name", "asc");
                  } else {
                    routeToBack();
                  }
                });
            } else {
              //event orgId empty
              routeToBack();
            }
          } else {
            routeToBack();
          }
        }
      });
  }
  async function loadRegularEventInformation(uorgId) {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshotB) => {
        if (snapshotB.exists) {
          var aData = {};
          var artiData = snapshotB.data();
          aData = artiData;
          setEventData(aData);

          var draws = {};
          if (artiData.drawItems != null) {
            draws = artiData.drawItems;
          }
          if (Object.keys(draws).length > 0){
            const draId = Object.keys(draws)[0]
            setEventDrawId(draId)
          }
          setEventDraws(draws);

          var optionss = eventOptions;
          if (artiData.options != null) {
            optionss = artiData.options;
          }
          setEventOptions(optionss);

          var sponsorss = eventSponsors;
          if (artiData.sponsor != null) {
            sponsorss = artiData.sponsor;
          }
          setEventSponsors(sponsorss);

          var agendass = eventAgendas;
          if (artiData.agenda != null) {
            agendass = artiData.agenda;
          }
          setEventAgendas(agendass);

          loadEventForms();

          const orgId = aData.orgId;
          if (orgId != null) {
            if (orgId != "") {
              if (uorgId == orgId) {
                fires
                  .collection("groups")
                  .doc(orgId)
                  .get()
                  .then((snapshotA) => {
                    if (snapshotA.exists) {
                      const orgD = snapshotA.data();
                      setOrgData({ ...orgD });
                      loadAllMessages();
                      loadAllPics();
                      loadAllViews();
                      loadExternalDrawMembers();

                      //real-time
                      loadInviteeRealtime();
                      loadAllLogsRealtime();
                      loadAllMembersRealTime("name", "asc");
                    } else {
                      routeToBack();
                    }
                  });
              } else {
                //id is not match
                routeToBack();
              }
            } else {
              //event orgId empty
              routeToBack();
            }
          } else {
            routeToBack();
          }
        }
      });
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var uorgId = userData.orgId;
          fires
            .collection("admin")
            .doc(uid)
            .get()
            .then((snap_A) => {
              if (snap_A.exists) {
                //super admin
                loadSuperAdminEventInformation();
              } else {
                //check events
                loadRegularEventInformation(uorgId);
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
          routeToLogout();
        }
      });
  }, []);

  function updateViewCount() {
    const cViewCount = views.length;
    fires.collection("events").doc(id).update({ viewCount: cViewCount });
  }

  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        <Container
          className="d-flex justify-content-center"
          style={{ minHeight: "100vh", minWidth: "100vw" }}
        >
          <div
            className="w-100"
            style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
          >
            <Card className="border-0">
              <Card.Body className="align-items-center justify-content-center">
                <div className="d-flex justify-content-between align-items-start">
                  <Card.Title
                    className="text-center"
                    style={{
                      fontSize: "45px",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    管理活动
                  </Card.Title>
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="dark"
                      style={{ marginRight: "15px" }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      刷新
                    </Button>

                    <CSVLink
                      filename={"event-attendence" + ".csv"}
                      data={csvData}
                      className="btn btn-dark"
                      style={{ color: "white", marginRight: "15px" }}
                    >
                      下载参与者数据
                    </CSVLink>

                    <CSVLink
                      filename={"event-views-" + ".csv"}
                      data={csvViewsData}
                      className="btn btn-dark"
                      style={{ color: "white", marginRight: "15px" }}
                    >
                      下载点击量数据
                    </CSVLink>

                    <DropdownButton
                      style={{ marginRight: "10px" }}
                      title="活动选项"
                      id=""
                      hidden={true}
                      variant="outline-dark"
                      onSelect={(e) => {
                        if (e == "end-event") {
                          const endConfirm = window.confirm(
                            "您确定要结束活动吗？结束活动并创建Email List"
                          );
                          if (endConfirm) {
                            var emailList = [];
                            eventMembers.map((eveItm) => {
                              if (eveItm.email != null) {
                                if (
                                  eveItm.checkinStatus &&
                                  !emailList.includes(eveItm.email)
                                ) {
                                  emailList.push({
                                    name: eveItm.name,
                                    email: eveItm.email,
                                  });
                                }
                              }
                            });
                            if (orgData.id != null) {
                              fires
                                .collection("groups")
                                .doc(orgData.id)
                                .collection("emails")
                                .add({
                                  emails: emailList,
                                  eventId: id,
                                  eventTitle: eventData.title,
                                  eventStartDate: eventData.startDate,
                                  eventEndData: eventData.endDate,
                                })
                                .then((eventR) => {
                                  const emailListId = eventR.id;
                                  var timestampNow =
                                    firebase.firestore.Timestamp.fromDate(
                                      new Date()
                                    );
                                  fires
                                    .collection("events")
                                    .doc(id)
                                    .update({
                                      status: "ended",
                                      statusTimestamp: timestampNow,
                                      endedTime: timestampNow,
                                    })
                                    .then(() => {
                                      window.location.href =
                                        "/send-emails/" + emailListId;
                                    });
                                });
                            }
                          }
                        } else if (e == "create-event-ticket") {
                          const ticket = createTicketId();
                          createTicketPDF(ticket);
                        } else if (e == "create-article-marketing") {
                        } else if (e == "create-article-growth") {
                        } else if (e == "add-fake-users") {
                        }
                      }}
                    >
                      <Dropdown.Item eventKey="create-article-marketing">
                        写推广文案(AI)
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="create-article-growth">
                        写复盘分析(AI)
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="add-fake-users">
                        添加虚拟用户(50已签到)
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="create-event-ticket">
                        创建活动收据
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        eventKey="end-event"
                        style={{ color: "red" }}
                      >
                        结束活动并发送反馈
                      </Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                      style={{ marginRight: "10px" }}
                      title="排序"
                      id=""
                      variant="outline-dark"
                      onSelect={(e) => {
                        if (e == "filter-by-name") {
                          setOrderedBy("name");
                          loadAllMembersRealTime("name", "asc");
                        } else if (e == "filter-by-timestamp") {
                          setOrderedBy("timestamp");
                          loadAllMembersRealTime("timestamp", "desc");
                        }
                      }}
                    >
                      <Dropdown.Item
                        eventKey="filter-by-name"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div className="fw-bold">按名字排序</div>
                        <div className="fw-bold">
                          {orderedBy == "name" ? "✓" : ""}
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="filter-by-timestamp"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div className="fw-bold">按时间排序</div>
                        <div className="fw-bold">
                          {orderedBy == "timestamp" ? "✓" : ""}
                        </div>
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>

                <Breadcrumb style={{ marginTop: "15px" }}>
                  <Breadcrumb.Item href="/">{orgData.name}</Breadcrumb.Item>
                  <Breadcrumb.Item href="/dashboard#events">
                    全部活动
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{eventData.title}</Breadcrumb.Item>
                </Breadcrumb>
                <Tab.Container
                  id="list-group-tabs-example"
                  defaultActiveKey={
                    window.location.href.includes("#")
                      ? "#" + window.location.href.split("#")[1]
                      : "#basic-info"
                  }
                >
                  <Row style={{ marginTop: "30px" }}>
                    <Col sm={2} style={{}}>
                      <ListGroup
                        className="border-0"
                        defaultActiveKey="#basic-info"
                        style={{}}
                      >
                        <ListGroup.Item className="border-0" style={{}}>
                          <DropdownButton
                            className="border-0"
                            style={{
                              marginTop: "15px",
                              marginBottom: "15px",
                              height: "40px",
                            }}
                            title={
                              eventData.status == "live"
                                ? "公开活动"
                                : eventData.status == "ended"
                                ? "已结束"
                                : "私人活动"
                            }
                            id=""
                            variant={
                              eventData.status == "ended" ? "danger" : "success"
                            }
                            onSelect={(e) => {
                              if (e == "public") {
                                fires
                                  .collection("events")
                                  .doc(id)
                                  .update({ status: "live" })
                                  .then(() => {
                                    setAgendaMessage("打开活动");
                                  });
                              } else if (e == "private") {
                                fires
                                  .collection("events")
                                  .doc(id)
                                  .update({ status: "private" })
                                  .then(() => {
                                    setAgendaMessage("关闭活动");
                                  });
                              }
                            }}
                          >
                            <Dropdown.Item eventKey="public">
                              设置为公开活动
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="private">
                              设置为私人活动
                            </Dropdown.Item>
                          </DropdownButton>
                          <Card.Link
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                            href={"https://tickets.evtgo.com/" + id}
                          >
                            {eventData.title}
                          </Card.Link>
                          <Card.Text>
                            {eventData.startDate.toDate().toDateString()}
                          </Card.Text>
                          <Card.Link href={"https://tickets.evtgo.com/" + id}>
                            查看活动
                          </Card.Link>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          <Card.Text
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "22px",
                            }}
                          >
                            活动
                          </Card.Text>
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#basic-info"
                          className="border-0 d-flex justify-content-between align-items-center"
                          style={{}}
                        >
                          基本信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#event-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          活动信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#event-sponsor-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          赞助商信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#event-agenda-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          日程信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#event-registration-questions"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          活动注册问卷
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#ticket-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          出票信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#checkin-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          签到信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#luckydraw-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          抽奖信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#luckydraw-info-external"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          外部抽奖信息
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#comments-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          评论和留言
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#pics-info"
                          className="border-0 d-flex justify-content-between align-items-start"
                          style={{}}
                        >
                          照片墙
                        </ListGroup.Item>

                        <ListGroup.Item className="border-0">
                          <Card.Text
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "22px",
                            }}
                          >
                            数据与统计
                          </Card.Text>
                        </ListGroup.Item>

                        <ListGroup.Item
                          action
                          href="#data-analytics"
                          className="border-0"
                          style={{}}
                        >
                          全部数据
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#data-analytics-impression"
                          className="border-0"
                          style={{}}
                        >
                          展示数据
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#data-analytics-registration"
                          className="border-0"
                          style={{}}
                        >
                          报名数据
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          href="#data-analytics-journey"
                          className="border-0"
                          style={{}}
                        >
                          实时展示
                        </ListGroup.Item>

                        {/* <ListGroup.Item  className="border-0">
              <Card.Text style={{color: 'black', fontWeight: 'bold', fontSize: '22px'}}>支付，票务和税务</Card.Text>
            </ListGroup.Item>

            <ListGroup.Item  action href="#payment-methods" className="border-0" style={{}}>
                支付方式
            </ListGroup.Item>
            <ListGroup.Item  action href="#payment-tickets" className="border-0" style={{}}>
                票据
            </ListGroup.Item>

            <ListGroup.Item  className="border-0">
              <Card.Text style={{color: 'black', fontWeight: 'bold', fontSize: '22px'}}>市场和客户</Card.Text>
            </ListGroup.Item>

            <ListGroup.Item  action href="#marketing" className="border-0" style={{}}>
                市场分析
            </ListGroup.Item>
            <ListGroup.Item  action href="#marketing-email" className="border-0" style={{}}>
                发送电子邮件
            </ListGroup.Item>
            <ListGroup.Item  action href="#marketing-social" className="border-0" style={{}}>
                分享到社交媒体
            </ListGroup.Item>
            <ListGroup.Item  action href="#customers" className="border-0" style={{}}>
                客户管理
            </ListGroup.Item>

            <ListGroup.Item  className="border-0">
              <Card.Text style={{color: 'black', fontWeight: 'bold', fontSize: '22px'}}>其他</Card.Text>
            </ListGroup.Item>

            <ListGroup.Item  action href="#dangers" className="border-0" style={{}}>
                <Card.Text style={{color: 'red', fontWeight: 'bold', fontSize: '15px'}}>删除活动</Card.Text>
            </ListGroup.Item> */}
                      </ListGroup>
                    </Col>

                    <Col sm={10}>
                      <Tab.Content>
                        <Tab.Pane eventKey="#basic-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              基本信息
                            </Card.Text>
                            <Button
                              variant="dark"
                              style={{}}
                              onClick={() => {
                                window.location.href =
                                  "/modify-event/" + eventData.id;
                              }}
                            >
                              修改基本信息
                            </Button>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              可修改活动基本信息，包括活动名称，地点，时间等，点击修改基本信息会跳到修改页面
                            </Card.Text>
                            
                          </div>

                          <Form style={{ marginTop: "15px" }}>
                            <Form.Group
                              id="event-title"
                              style={{ marginTop: "25px" }}
                            >
                              <FloatingLabel
                                controlId="floatingInput"
                                label="活动名称"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  rows={1}
                                  disabled={true}
                                  placeholder="xxx-xxx"
                                  defaultValue={eventData.title}
                                  required
                                />
                              </FloatingLabel>
                            </Form.Group>

                            <Form.Group
                              id="event-description"
                              style={{ marginTop: "5px" }}
                            >
                              <FloatingLabel
                                controlId="floatingInput"
                                label="活动介绍"
                                className="mb-3"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={25}
                                  disabled={true}
                                  defaultValue={eventData.description}
                                  required
                                />
                              </FloatingLabel>
                            </Form.Group>

                            <Form.Group
                              id="event-description"
                              style={{ marginTop: "5px" }}
                            >
                              <FloatingLabel
                                controlId="floatingInput"
                                label="活动地址"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  disabled={true}
                                  defaultValue={eventData.address.fullAddress}
                                />
                              </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-start" style={{marginTop: 15}}>
                            <Card.Link
                              hidden={eventDrawId == ""}
                              style={{
                                fontSize: "22px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                              }}
                              href={`/live-event-dashboard/${id}`}
                            >
                              前往抽奖页面
                            </Card.Link>

                            </Form.Group>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#event-info">
                          {setupSuccess && (
                            <Alert variant="success">{setupSuccess}</Alert>
                          )}
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              活动信息
                            </Card.Text>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              可修改活动信息，包括设置活动类型，开启名片访问，评论，添加照片，上传社交媒体等
                            </Card.Text>
                          </div>

                          <div style={{ marginTop: "15px" }}>
                            <Row md={2}>
                              <Col>
                                <Card style={{ marginTop: "15px" }}>
                                  <Card.Body>
                                    <div className="d-flex align-items-start justify-content-between">
                                      <Card.Text
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        参与人数上限
                                      </Card.Text>
                                      <Card.Text
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        {eventOptions.max}
                                      </Card.Text>
                                    </div>
                                    <div style={{ padding: "8px" }}>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          const maxN =
                                            window.prompt(
                                              "请输入您需要的参与人数最大值？"
                                            );
                                          var maxNum = 500;
                                          maxNum = Number(maxN);
                                          if (maxNum > 0) {
                                            //update
                                            var currentOptions = eventOptions;
                                            currentOptions.max = maxNum;
                                            fires
                                              .collection("events")
                                              .doc(id)
                                              .update({
                                                options: currentOptions,
                                              })
                                              .then((updated) => {
                                                window.location.reload();
                                              });
                                          }
                                        }}
                                      >
                                        修改
                                      </Button>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>

                              <Col>
                                <Card style={{ marginTop: "15px" }}>
                                  <Card.Body>
                                    <div className="d-flex align-items-start justify-content-between">
                                      <Card.Text
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        允许评论
                                      </Card.Text>
                                      <Card.Text
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        {eventOptions.comment ? "允许" : "拒绝"}
                                      </Card.Text>
                                    </div>
                                    <div style={{ padding: "8px" }}>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          const maxN =
                                            window.confirm(
                                              "您想要允许开启评论吗？"
                                            );
                                          var currentOptions = eventOptions;
                                          currentOptions.comment = maxN;
                                          fires
                                            .collection("events")
                                            .doc(id)
                                            .update({
                                              options: currentOptions,
                                            })
                                            .then((updated) => {
                                              window.location.reload();
                                            });
                                        }}
                                      >
                                        修改
                                      </Button>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>

                              <Col>
                                <Card style={{ marginTop: "15px" }}>
                                  <Card.Body>
                                    <div className="d-flex align-items-start justify-content-between">
                                      <Card.Text
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        允许传照片
                                      </Card.Text>
                                      <Card.Text
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        {eventOptions.picture ? "允许" : "拒绝"}
                                      </Card.Text>
                                    </div>
                                    <div style={{ padding: "8px" }}>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          const maxN =
                                            window.confirm(
                                              "您想要允许上传照片吗？"
                                            );
                                          var currentOptions = eventOptions;
                                          currentOptions.picture = maxN;
                                          fires
                                            .collection("events")
                                            .doc(id)
                                            .update({
                                              options: currentOptions,
                                            })
                                            .then((updated) => {
                                              window.location.reload();
                                            });
                                        }}
                                      >
                                        修改
                                      </Button>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#event-sponsor-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              赞助商信息({eventSponsors.length})
                            </Card.Text>

                            <div>
                              <Button
                                variant="dark"
                                style={{}}
                                onClick={() => {
                                  window.location.href =
                                    "/modify-event/" + eventData.id;
                                }}
                              >
                                修改信息
                              </Button>
                            </div>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              可修改活动基本信息，包括活动名称，地点，时间等，点击修改基本信息会跳到修改页面
                            </Card.Text>
                          </div>

                          <Row>
                            <Col sm={8}>
                              <ListGroup>
                                <ListGroup.Item
                                  className=""
                                  style={{ backgroundColor: "#EEE" }}
                                >
                                  <Row md={5}>
                                    <Col>
                                      <Card.Text>赞助商名称</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>赞助商介绍</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>赞助商图片</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>赞助商网站</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>设置</Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                                {eventSponsors.map((sponsorItm) => {
                                  return (
                                    <ListGroup.Item className="" style={{}}>
                                      <Row md={5}>
                                        <Col>
                                          <Card.Text
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {sponsorItm.order + 1}.{" "}
                                            {sponsorItm.name}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Link
                                            onClick={() => {
                                              if (
                                                sponsorItm.description != ""
                                              ) {
                                                window.alert(
                                                  sponsorItm.description
                                                );
                                              } else {
                                                window.alert("没有内容");
                                              }
                                            }}
                                          >
                                            查看介绍
                                          </Card.Link>
                                        </Col>
                                        <Col>
                                          <Card.Link href={sponsorItm.imageURL}>
                                            {sponsorItm.imageURL != ""
                                              ? "图片链接"
                                              : "无图片"}
                                          </Card.Link>
                                        </Col>
                                        <Col>
                                          <Card.Link href={sponsorItm.url}>
                                            {sponsorItm.url != ""
                                              ? "链接"
                                              : "无链接"}
                                          </Card.Link>
                                        </Col>
                                        <Col>
                                          <div>
                                            <Card.Link
                                              style={{ color: "black" }}
                                              onClick={() => {
                                                const sponsorIndex =
                                                  sponsorItm.order;
                                                if (
                                                  sponsorIndex > -1 &&
                                                  sponsorIndex <
                                                    eventSponsors.length
                                                ) {
                                                  setSponsorModifyIndex(
                                                    sponsorIndex
                                                  );
                                                  setSponsorModifySet(
                                                    sponsorItm
                                                  );
                                                  setSponsorMessage(
                                                    "准备修改[" +
                                                      sponsorItm.name +
                                                      "]"
                                                  );
                                                } else {
                                                  //not found
                                                }
                                              }}
                                            >
                                              修改
                                            </Card.Link>
                                            <Card.Link
                                              style={{ color: "red" }}
                                              onClick={() => {
                                                const sponsorIndex =
                                                  sponsorItm.order;
                                                if (
                                                  sponsorIndex > -1 &&
                                                  sponsorIndex <
                                                    eventSponsors.length
                                                ) {
                                                  const deletionConfirm =
                                                    window.confirm(
                                                      "您确定要删除该赞助商 " +
                                                        sponsorItm.name +
                                                        " 吗？"
                                                    );
                                                  if (deletionConfirm) {
                                                    var currentSponsors =
                                                      eventSponsors;
                                                    currentSponsors.splice(
                                                      sponsorIndex,
                                                      1
                                                    );
                                                    var orderI = 0;
                                                    var newSponsor = [];
                                                    currentSponsors.map(
                                                      (sponsorI) => {
                                                        sponsorI.order = orderI;
                                                        newSponsor.push(
                                                          sponsorI
                                                        );
                                                        orderI = orderI + 1;
                                                      }
                                                    );
                                                    fires
                                                      .collection("events")
                                                      .doc(id)
                                                      .update({
                                                        sponsor: newSponsor,
                                                      })
                                                      .then(() => {
                                                        setEventSponsors(
                                                          newSponsor
                                                        );
                                                        setMessageCounter(
                                                          messageCounter + 1
                                                        );
                                                        setSponsorMessage(
                                                          "赞助商已删除[" +
                                                            messageCounter +
                                                            "]"
                                                        );
                                                      });
                                                  }
                                                } else {
                                                  window.alert("无法删除");
                                                }
                                              }}
                                            >
                                              删除
                                            </Card.Link>
                                          </div>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  );
                                })}
                              </ListGroup>
                            </Col>
                            <Col sm={4}>
                              <Form>
                                {sponsorMessage && (
                                  <Alert variant="success">
                                    {sponsorMessage}
                                  </Alert>
                                )}
                                <Form.Group id="exp-image">
                                  <img
                                    src={sponsorMofifySet.imageURL}
                                    style={{
                                      width: "100%",
                                      maxHeight: "300px",
                                    }}
                                  />
                                </Form.Group>
                                <Form.Group id="exp-title" style={{}}>
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商名称 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    ref={eventSponsorNameRef}
                                    defaultValue={sponsorMofifySet.name}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-description"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商介绍 *
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={8}
                                    ref={eventSponsorDescriptionRef}
                                    defaultValue={sponsorMofifySet.description}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-start-date"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商banner图片链接 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    ref={eventSponsorBannerRef}
                                    defaultValue={sponsorMofifySet.imageURL}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-start-date"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商网站 *
                                  </Form.Label>
                                  <Form.Control
                                    type="url"
                                    rows={1}
                                    ref={eventSponsorURLRef}
                                    defaultValue={sponsorMofifySet.url}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="action"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Button
                                    variant="success"
                                    style={{
                                      width: "100%",
                                      marginBottom: "15px",
                                    }}
                                    onClick={() => {
                                      const sponsorName =
                                        eventSponsorNameRef.current.value;
                                      const sponsorDescription =
                                        eventSponsorDescriptionRef.current
                                          .value;
                                      const sponsorBanner =
                                        eventSponsorBannerRef.current.value;
                                      const sponsorURL =
                                        eventSponsorURLRef.current.value;
                                      var currentSponsors = eventSponsors;

                                      if (sponsorMofifySet.order == -1) {
                                        currentSponsors.push({
                                          name: sponsorName,
                                          description: sponsorDescription,
                                          imageURL: sponsorBanner,
                                          order: eventSponsors.length,
                                          url: sponsorURL,
                                        });
                                        setEventSponsors(currentSponsors);
                                        fires
                                          .collection("events")
                                          .doc(id)
                                          .update({ sponsor: currentSponsors })
                                          .then(() => {
                                            //load sponsors
                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setSponsorMessage(
                                              "信息已填写[" +
                                                messageCounter +
                                                "]"
                                            );
                                            eventSponsorNameRef.current.value =
                                              "";
                                            eventSponsorDescriptionRef.current.value =
                                              "";
                                            eventSponsorBannerRef.current.value =
                                              "";
                                            eventSponsorURLRef.current.value =
                                              "";
                                            setSponsorModifyIndex(-1);
                                            setSponsorModifySet({
                                              name: "",
                                              description: "",
                                              imageURL: "",
                                              order: -1,
                                              url: "",
                                            });
                                          });
                                      } else {
                                        currentSponsors[
                                          sponsorMofifySet.order
                                        ] = {
                                          name: sponsorName,
                                          description: sponsorDescription,
                                          imageURL: sponsorBanner,
                                          order: currentSponsors.length,
                                          url: sponsorURL,
                                        };
                                        setEventSponsors(currentSponsors);
                                        fires
                                          .collection("events")
                                          .doc(id)
                                          .update({ sponsor: currentSponsors })
                                          .then(() => {
                                            //load sponsors
                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setSponsorMessage(
                                              "信息已填写[" +
                                                messageCounter +
                                                "]"
                                            );
                                            eventSponsorNameRef.current.value =
                                              "";
                                            eventSponsorDescriptionRef.current.value =
                                              "";
                                            eventSponsorBannerRef.current.value =
                                              "";
                                            eventSponsorURLRef.current.value =
                                              "";
                                            setSponsorModifyIndex(-1);
                                            setSponsorModifySet({
                                              name: "",
                                              description: "",
                                              imageURL: "",
                                              order: -1,
                                              url: "",
                                            });
                                          });
                                      }
                                    }}
                                  >
                                    {sponsorMofifySet.order == -1
                                      ? "添加赞助商"
                                      : "修改赞助商<" +
                                        (sponsorMofifySet.order + 1) +
                                        ">"}
                                  </Button>
                                  <Button
                                    hidden={sponsorMofifySet.order == -1}
                                    variant="dark"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      setMessageCounter(messageCounter + 1);
                                      setSponsorMessage(
                                        "信息已填写[" + messageCounter + "]"
                                      );
                                      eventSponsorNameRef.current.value = "";
                                      eventSponsorDescriptionRef.current.value =
                                        "";
                                      eventSponsorBannerRef.current.value = "";
                                      eventSponsorURLRef.current.value = "";
                                      setSponsorModifyIndex(-1);
                                      setSponsorModifySet({
                                        name: "",
                                        description: "",
                                        imageURL: "",
                                        order: -1,
                                        url: "",
                                      });
                                    }}
                                  >
                                    取消修改
                                  </Button>
                                </Form.Group>
                              </Form>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#event-agenda-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              日程安排({eventAgendas.length})
                            </Card.Text>
                            <Button
                              variant="dark"
                              style={{}}
                              onClick={() => {
                                window.location.href =
                                  "/modify-event/" + eventData.id;
                              }}
                            >
                              修改基本信息
                            </Button>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              可修改活动基本信息，包括活动名称，地点，时间等，点击修改基本信息会跳到修改页面
                            </Card.Text>
                          </div>
                          <Row>
                            <Col sm={8}>
                              <ListGroup>
                                <ListGroup.Item
                                  className=""
                                  style={{ backgroundColor: "#EEE" }}
                                >
                                  <Row md={5}>
                                    <Col>
                                      <Card.Text>名称</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>介绍</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>时间</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>地点</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>设置</Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                                {eventAgendas.map((agendaItm) => {
                                  return (
                                    <ListGroup.Item className="" style={{}}>
                                      <Row md={5}>
                                        <Col>
                                          <Card.Text
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {agendaItm.order + 1}.{" "}
                                            {agendaItm.title}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Link
                                            onClick={() => {
                                              if (
                                                agendaItm.description != null
                                              ) {
                                                window.alert(
                                                  agendaItm.description
                                                );
                                              } else {
                                              }
                                            }}
                                          >
                                            查看介绍
                                          </Card.Link>
                                        </Col>
                                        <Col>
                                          <Card.Text
                                            style={{ fontWeight: "normal" }}
                                          >
                                            {agendaItm.date
                                              .toDate()
                                              .toLocaleString()}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text
                                            style={{ fontWeight: "normal" }}
                                          >
                                            {agendaItm.location}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <div>
                                            <Card.Link
                                              style={{ color: "black" }}
                                              onClick={() => {
                                                const agendaIndex =
                                                  agendaItm.order;
                                                if (
                                                  agendaIndex > -1 &&
                                                  agendaIndex <
                                                    eventAgendas.length
                                                ) {
                                                  setAgendaModifyIndex(
                                                    agendaItm.order
                                                  );
                                                  setAgendaModifySet(agendaItm);
                                                  setAgendaMessage(
                                                    "准备修改[" +
                                                      agendaItm.title +
                                                      "]"
                                                  );
                                                } else {
                                                  //not found
                                                  eventAgendaTitleRef.current.value =
                                                    "";
                                                  eventAgendaDescriptionRef.current.value =
                                                    "";
                                                  eventAgendaRoomRef.current.value =
                                                    "";

                                                  setAgendaModifyIndex(-1);
                                                  setAgendaModifySet({
                                                    title: "",
                                                    description: "",
                                                    location: "",
                                                    date: firebase.firestore.Timestamp.fromDate(
                                                      new Date()
                                                    ),
                                                    order: -1,
                                                  });
                                                }
                                              }}
                                            >
                                              修改
                                            </Card.Link>
                                            <Card.Link
                                              style={{ color: "red" }}
                                              onClick={() => {}}
                                            >
                                              删除
                                            </Card.Link>
                                          </div>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  );
                                })}
                              </ListGroup>
                            </Col>
                            <Col sm={4}>
                              <Form>
                                {agendaMessage && (
                                  <Alert variant="success">
                                    {agendaMessage}
                                  </Alert>
                                )}
                                <Form.Group id="exp-title" style={{}}>
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    日程标题 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    ref={eventAgendaTitleRef}
                                    defaultValue={agendaMofifySet.title}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-description"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    日程详情 *
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={4}
                                    ref={eventAgendaDescriptionRef}
                                    defaultValue={agendaMofifySet.description}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-description"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    房间或备注
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={4}
                                    ref={eventAgendaRoomRef}
                                    defaultValue={agendaMofifySet.location}
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-start-date"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    开始时间 *
                                  </Form.Label>
                                  <br />
                                  <DateTimePicker
                                    value={eventAgendaTime.toDate()}
                                    required
                                    onChange={(date) => {
                                      setEventAgendaTime(
                                        firebase.firestore.Timestamp.fromDate(
                                          date
                                        )
                                      );
                                    }}
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Button
                                    variant="dark"
                                    style={{ width: "100%", marginTop: "15px" }}
                                    onClick={() => {
                                      const agendaTitle =
                                        eventAgendaTitleRef.current.value;
                                      const agendaDescription =
                                        eventAgendaDescriptionRef.current.value;
                                      const agendaRoom =
                                        eventAgendaRoomRef.current.value;
                                      const agendaDate = eventAgendaTime;
                                      var currentAgendas = eventAgendas;

                                      if (agendaMofifySet.order == -1) {
                                        currentAgendas.push({
                                          title: agendaTitle,
                                          description: agendaDescription,
                                          location: agendaRoom,
                                          date: agendaDate,
                                          order: currentAgendas.length,
                                        });
                                        fires
                                          .collection("events")
                                          .doc(id)
                                          .update({ agenda: currentAgendas })
                                          .then(() => {
                                            setEventAgendas(currentAgendas);
                                          });
                                      } else {
                                        currentAgendas[agendaMofifySet.order] =
                                          {
                                            title: agendaTitle,
                                            description: agendaDescription,
                                            location: agendaRoom,
                                            date: agendaDate,
                                            order: agendaMofifySet.order,
                                          };
                                        fires
                                          .collection("events")
                                          .doc(id)
                                          .update({ agenda: currentAgendas })
                                          .then(() => {
                                            setEventAgendas(currentAgendas);
                                          });
                                      }

                                      setMessageCounter(messageCounter + 1);
                                      setAgendaMessage(
                                        "信息已填写[" + messageCounter + "]"
                                      );

                                      eventAgendaTitleRef.current.value = "";
                                      eventAgendaDescriptionRef.current.value =
                                        "";
                                      eventAgendaRoomRef.current.value = "";

                                      setAgendaModifyIndex(-1);
                                      setAgendaModifySet({
                                        title: "",
                                        description: "",
                                        location: "",
                                        date: firebase.firestore.Timestamp.fromDate(
                                          new Date()
                                        ),
                                        order: -1,
                                      });
                                    }}
                                  >
                                    {agendaMofifySet.order == -1
                                      ? "创建日程"
                                      : "修改日程< " +
                                        (agendaMofifySet.order + 1) +
                                        " >"}
                                  </Button>
                                  <Button
                                    hidden={agendaMofifySet.order == -1}
                                    variant="dark"
                                    style={{ width: "100%", marginTop: "15px" }}
                                    onClick={() => {
                                      setMessageCounter(messageCounter + 1);
                                      setAgendaMessage(
                                        "信息已填写[" + messageCounter + "]"
                                      );

                                      setAgendaModifyIndex(-1);
                                      setAgendaModifySet({
                                        title: "",
                                        description: "",
                                        location: "",
                                        date: firebase.firestore.Timestamp.fromDate(
                                          new Date()
                                        ),
                                        order: -1,
                                      });
                                    }}
                                  >
                                    取消修改
                                  </Button>
                                </Form.Group>
                              </Form>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#event-registration-questions">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              活动注册问卷
                            </Card.Text>
                          </div>
                          <Row>
                            <Col sm={8}>
                              <ListGroup>
                                <ListGroup.Item
                                  className=""
                                  style={{ backgroundColor: "#EEE" }}
                                >
                                  <Row md={5}>
                                    <Col>
                                      <Card.Text>标题</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>提示</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>问卷类型</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>答案类型</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>答案选择</Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                                {eventFormData.map((formItm) => {
                                  return (
                                    <ListGroup.Item className="" style={{}}>
                                      <Row md={5}>
                                        <Col>
                                          <Card.Text>{formItm.title}</Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text>
                                            {formItm.placeholder}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text>
                                            {formItm.optionType}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text>
                                            {formItm.valueType}
                                          </Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text>
                                            {formItm.options}
                                          </Card.Text>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  );
                                })}
                              </ListGroup>
                            </Col>

                            <Col sm={4}>
                              <Form>
                                <Card.Title className="text-center">
                                  添加报名问题
                                </Card.Title>
                                <Form.Group
                                  id="speaker-name"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    报名问题标题 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    defaultValue=""
                                    ref={questionTitleRef}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="speaker-intro"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    报名问题介绍 (Placeholder) *
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={5}
                                    defaultValue=""
                                    ref={questionDescRef}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="speaker-name"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    是否为必填项 *
                                  </Form.Label>
                                  <div>
                                    <ButtonGroup>
                                      <Button
                                        variant={
                                          questionRequired
                                            ? "dark"
                                            : "outline-dark"
                                        }
                                        onClick={() => {
                                          setQuestionRequired(true);
                                          setMessageCounter(messageCounter + 1);
                                          setSuccess("必填");
                                        }}
                                      >
                                        必填
                                      </Button>
                                      <Button
                                        variant={
                                          questionRequired
                                            ? "outline-dark"
                                            : "dark"
                                        }
                                        onClick={() => {
                                          setQuestionRequired(false);
                                          setMessageCounter(messageCounter + 1);
                                          setSuccess("不必填");
                                        }}
                                      >
                                        不必填
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </Form.Group>
                                <Form.Group
                                  id="speaker-profile"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    报名问题类型 *
                                  </Form.Label>
                                  <br />
                                  <ButtonGroup>
                                    <Button
                                      variant={
                                        questionType == 0
                                          ? "dark"
                                          : "outline-dark"
                                      }
                                      onClick={() => {
                                        setQuestionType(0);
                                        setMessageCounter(messageCounter + 1);
                                        setSuccess("多选");
                                      }}
                                    >
                                      多选
                                    </Button>
                                    <Button
                                      variant={
                                        questionType == 1
                                          ? "dark"
                                          : "outline-dark"
                                      }
                                      onClick={() => {
                                        setQuestionType(1);
                                        setMessageCounter(messageCounter + 1);
                                        setSuccess("问卷");
                                      }}
                                    >
                                      问卷
                                    </Button>
                                  </ButtonGroup>
                                </Form.Group>
                                <Form.Group
                                  id="speaker-profile"
                                  style={{ marginTop: "15px" }}
                                  hidden={questionType == 1}
                                >
                                  <Form.Label
                                    style={{ fontWeight: "bold" }}
                                    hidden={questionType == 1}
                                  >
                                    报名问题选项 *
                                  </Form.Label>
                                  <br />
                                  <Button
                                    variant="dark"
                                    hidden={questionType == 1}
                                    onClick={() => {
                                      var newAnswer =
                                        window.prompt("请输入选项");
                                      if (newAnswer != "") {
                                        var answers = questionAnswers;
                                        if (!answers.includes(newAnswer)) {
                                          answers.push(newAnswer);
                                        }
                                        setQuestionAnswers(answers);
                                        setMessageCounter(messageCounter + 1);
                                        setSuccess(
                                          "已添加[" + messageCounter + "]"
                                        );
                                      }
                                    }}
                                  >
                                    添加选项
                                  </Button>
                                  <ListGroup
                                    style={{ marginTop: "10px" }}
                                    hidden={questionType == 1}
                                  >
                                    {questionAnswers.map((ansItm) => {
                                      return (
                                        <ListGroup.Item
                                          className="d-flex align-items-start justify-content-between"
                                          hidden={questionType == 1}
                                        >
                                          <Form.Label
                                            style={{ fontWeight: "bold" }}
                                            hidden={questionType == 1}
                                          >
                                            {ansItm}
                                          </Form.Label>
                                          <Button
                                            variant="danger"
                                            hidden={questionType == 1}
                                            onClick={() => {
                                              var fIndex =
                                                questionAnswers.indexOf(ansItm);
                                              if (
                                                fIndex >= 0 &&
                                                fIndex < questionAnswers.length
                                              ) {
                                                var newQ = questionAnswers;
                                                newQ.splice(fIndex, 1);
                                                setQuestionAnswers(newQ);
                                                setMessageCounter(
                                                  messageCounter + 1
                                                );
                                                setSuccess(
                                                  "已删除[" +
                                                    messageCounter +
                                                    "]"
                                                );
                                              }
                                            }}
                                          >
                                            删除
                                          </Button>
                                        </ListGroup.Item>
                                      );
                                    })}
                                  </ListGroup>
                                </Form.Group>
                                <Button
                                  className="w-100"
                                  variant="success"
                                  style={{ marginTop: "20px" }}
                                  onClick={() => {
                                    let qTitle = questionTitleRef.current.value;
                                    let qDesc = questionDescRef.current.value;
                                    var formUUID = uuid();

                                    if (qTitle != "" && qTitle != null) {
                                      const questionItm = {
                                        id: formUUID,
                                        optionType:
                                          questionType == 0
                                            ? "options"
                                            : "text",
                                        options: questionAnswers,
                                        order: questionList.length,
                                        placeholder: qDesc,
                                        title: qTitle,
                                        required: questionRequired,
                                        valueType: "text",
                                        timestamp: timestampNow,
                                      };
                                      if (
                                        questionType == 0 &&
                                        questionAnswers.length == 0
                                      ) {
                                        window.alert("请添加问题和回答");
                                      } else {
                                        setQuestionAnswers([]);
                                        setQuestionRequired(false);
                                        setQuestionType(0);

                                        fires
                                          .collection("events")
                                          .doc(id)
                                          .collection("forms")
                                          .doc(formUUID)
                                          .set(questionItm)
                                          .then((err) => {
                                            loadEventForms();
                                          });

                                        questionTitleRef.current.value = "";
                                        questionDescRef.current.value = "";
                                      }
                                    } else {
                                      window.alert("请添加问题");
                                    }
                                  }}
                                >
                                  添加问题
                                </Button>
                              </Form>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#ticket-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              已出票({eventTickets.length} / {eventOptions.max})
                            </Card.Text>
                            <div>
                              <Button
                                variant="dark"
                                hidden={true}
                                style={{ marginRight: "15px" }}
                                onClick={() => {
                                  setShowImportMembers(true);
                                }}
                              >
                                导入参与者(csv)
                              </Button>
                              <Button
                                variant="dark"
                                style={{ marginRight: "15px" }}
                                onClick={() => {
                                  window.location.href = "/event-ticket/" + id;
                                }}
                              >
                                添加参与者
                              </Button>
                              <Button
                                variant="dark"
                                style={{ marginRight: "15px" }}
                                onClick={() => {
                                  window.location.href = "/event-ticket/" + id;
                                }}
                              >
                                开始出票
                              </Button>
                              <Button
                                variant="dark"
                                style={{ marginRight: "15px" }}
                                onClick={() => {
                                  window.location.href = "/event-checkin/" + id;
                                }}
                              >
                                开始签到
                              </Button>
                            </div>
                          </div>

                          <Row md={3} style={{ marginTop: "15px" }}>
                            {eventTickets.map((ticketItm) => {
                              var tickets = [];
                              if (ticketItm.tickets != null) {
                                tickets = ticketItm.tickets;
                              }
                              return (
                                <Col>
                                  <Card style={{ marginTop: "15px" }}>
                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        marginRight: "15px",
                                      }}
                                    >
                                      <Card.Text style={{ color: "green" }}>
                                        已出票({ticketItm.ticketId})
                                      </Card.Text>
                                      <Button
                                        variant="dark"
                                        onClick={() => {
                                          window.open(
                                            "https://tickets.evtgo.com/ticket-id/" +
                                              ticketItm.ticketId
                                          );
                                        }}
                                      >
                                        查看票据
                                      </Button>
                                    </div>
                                    <Card.Title
                                      style={{
                                        marginLeft: "15px",
                                        fontSize: "45px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {ticketItm.userName}
                                    </Card.Title>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "5px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      数量: {tickets.length}
                                    </Card.Text>
                                    {tickets.map((tickId) => {
                                      return (
                                        <Card.Link
                                          hidden={tickets.length <= 1}
                                          style={{
                                            marginLeft: "15px",
                                            marginTop: "5px",
                                            marginBottom: "15px",
                                            color: "black",
                                          }}
                                          href={
                                            "https://tickets.evtgo.com/ticket-id/" +
                                            tickId
                                          }
                                        >
                                          {tickId}
                                        </Card.Link>
                                      );
                                    })}
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#checkin-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              已签到({eventCheckInMembers.length})
                            </Card.Text>
                          </div>
                          <Row md={3} style={{ marginTop: "15px" }}>
                            {eventCheckInMembers.map((memItm) => {
                              return (
                                <Col>
                                  <Card style={{ marginTop: "15px" }}>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        color: "green",
                                      }}
                                    >
                                      已签到({memItm.ticketId})
                                    </Card.Text>
                                    <Card.Title
                                      style={{
                                        marginLeft: "15px",
                                        fontSize: "45px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {memItm.name}
                                    </Card.Title>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      {memItm.email}
                                    </Card.Text>
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#luckydraw-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              抽奖奖品
                            </Card.Text>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              {Object.keys(eventDraws).length}个奖品。
                              {eventDrawWinnerMembers.length}人得奖
                            </Card.Text>
                          </div>
                          <Form.Group className="d-flex align-items-start" style={{marginTop: 15, marginBottom: 35}}>
                            <Card.Link
                              hidden={eventDrawId == ""}
                              style={{
                                fontSize: "22px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                              }}
                              href={`/live-event-dashboard/${id}`}
                            >
                              前往抽奖页面
                            </Card.Link>

                            </Form.Group>
                          <ListGroup>
                            <ListGroup.Item
                              className=""
                              style={{ backgroundColor: "#EEE" }}
                            >
                              <Row md={4}>
                                <Col>
                                  <Card.Text>奖品名称</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>数量</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>剩余</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>任务</Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            {Object.keys(eventDraws).map((drawKey) => {
                              const drawItm = eventDraws[drawKey];
                              var drawName = "";
                              var drawTotal = "0";
                              var drawRemaining = 0;
                              var drawType = "";
                              if (drawItm.name != null) {
                                drawName = drawItm.name;
                              }
                              if (drawItm.total != null) {
                                drawTotal = drawItm.total;
                              }
                              if (drawItm.remaining != null) {
                                drawRemaining = drawItm.remaining;
                              }
                              if (drawItm.type != null) {
                                drawType = drawItm.type;
                              }

                              return (
                                <ListGroup.Item>
                                  <Row md={4}>
                                    <Col>
                                      <Card.Text>{drawName}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{drawTotal}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{drawRemaining}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Button
                                        variant="dark"
                                        hidden={drawType != "external"}
                                        onClick={() => {
                                          if (drawType == "external") {
                                            window.location.href =
                                              "/live-lucky-draw-dashboard/" +
                                              id +
                                              "/" +
                                              drawKey;
                                          } else {
                                          }
                                        }}
                                      >
                                        进入抽奖现场
                                      </Button>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>

                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              已中奖名单({eventDrawWinnerMembers.length})
                            </Card.Text>
                          </div>
                          <Row md={3} style={{ marginTop: "15px" }}>
                            {eventDrawWinnerMembers.map((memItm) => {
                              var drawItmName = "";
                              if (memItm.drawOptions != null) {
                                if (memItm.drawOptions != "") {
                                  if (
                                    Object.keys(eventDraws).includes(
                                      memItm.drawOptions
                                    )
                                  ) {
                                    const drawItm =
                                      eventDraws[memItm.drawOptions];
                                    if (drawItm.name != null) {
                                      drawItmName = drawItm.name;
                                    }
                                  }
                                }
                              }

                              return (
                                <Col>
                                  <Card style={{ marginTop: "15px" }}>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                      }}
                                    >
                                      获奖🏆({memItm.ticketId})
                                    </Card.Text>
                                    <Card.Title
                                      style={{
                                        marginLeft: "15px",
                                        fontSize: "45px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {memItm.name}
                                    </Card.Title>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      奖品 {drawItmName}
                                    </Card.Text>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      {memItm.email}
                                    </Card.Text>
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#luckydraw-info-external">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              外部抽奖报名单({eventExternalDrawMembers.length})
                            </Card.Text>
                            <CSVLink
                              filename={"event-luckydraw" + ".csv"}
                              data={csvExternalLuckyDrawData}
                              className="btn btn-dark"
                              style={{ color: "white", marginTop: "15px" }}
                            >
                              导出名单
                            </CSVLink>
                          </div>
                          <ListGroup style={{ marginTop: "15px" }}>
                            <ListGroup.Item
                              className=""
                              style={{ backgroundColor: "#EEE" }}
                            >
                              <Row md={6}>
                                <Col>
                                  <Card.Text>名字</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>电子邮箱</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>电话</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>性别</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>抽奖号</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>抽奖情况</Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            {eventExternalDrawMembers.map((memItm) => {
                              var winnerId = "-";
                              if (memItm.drawWinnerId != null) {
                                if (memItm.drawWinnerId != "") {
                                  winnerId = memItm.drawWinnerId;
                                }
                              }
                              return (
                                <ListGroup.Item>
                                  <Row md={6}>
                                    <Col>
                                      <Card.Text>
                                        {memItm.firstName +
                                          " " +
                                          memItm.lastName}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.email}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.phone}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.gender}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.ticket}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{winnerId}</Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#comments-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              评论和留言({eventComments.length})
                            </Card.Text>
                          </div>

                          <Row md={3} style={{ marginTop: "15px" }}>
                            {eventComments.map((commentItm) => {
                              var publicMessage = false;
                              if (commentItm.approved != null) {
                                publicMessage = commentItm.approved;
                              }
                              return (
                                <Col>
                                  <Card style={{ marginTop: "15px" }}>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                      }}
                                    >
                                      {publicMessage ? "[可见] " : "[不可见] "}
                                      {commentItm.timestamp
                                        .toDate()
                                        .toLocaleDateString()}
                                    </Card.Text>
                                    <Card.Title
                                      style={{
                                        marginLeft: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {commentItm.name}的留言
                                    </Card.Title>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        fontWeight: "bold",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      {commentItm.message}
                                    </Card.Text>
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      {commentItm.email}
                                    </Card.Text>
                                    <div
                                      style={{
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <Card.Link
                                        style={{
                                          marginLeft: "15px",
                                          marginBottom: "15px",
                                          color: "black",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                          if (commentItm.id != null) {
                                            if (commentItm.id != "") {
                                              fires
                                                .collection("events")
                                                .doc(id)
                                                .collection("messages")
                                                .doc(commentItm.id)
                                                .update({ approved: true })
                                                .then(() => {
                                                  loadAllMessages();
                                                });
                                            }
                                          }
                                        }}
                                      >
                                        设置为可见
                                      </Card.Link>
                                      <Card.Link
                                        style={{
                                          marginLeft: "15px",
                                          marginBottom: "15px",
                                          color: "black",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                          if (commentItm.id != null) {
                                            if (commentItm.id != "") {
                                              fires
                                                .collection("events")
                                                .doc(id)
                                                .collection("messages")
                                                .doc(commentItm.id)
                                                .update({ approved: false })
                                                .then(() => {
                                                  loadAllMessages();
                                                });
                                            }
                                          }
                                        }}
                                      >
                                        设置为不可见
                                      </Card.Link>
                                    </div>
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#pics-info">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              照片墙({eventPics.length})
                            </Card.Text>
                          </div>
                          <Row md={3} style={{ marginTop: "15px" }}>
                            {eventPics.map((picItm) => {
                              return (
                                <Col>
                                  <Card style={{ margin: "15px" }}>
                                    <Card.Img
                                      style={{ width: "100%" }}
                                      variant="top"
                                      src={picItm.imageURL}
                                    />
                                    <Card.Text
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      上传自 {picItm.name}
                                    </Card.Text>
                                    <Card.Text style={{ marginLeft: "15px" }}>
                                      {picItm.timestamp
                                        .toDate()
                                        .toLocaleString()}
                                    </Card.Text>
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#data-analytics-journey">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              实时展示
                            </Card.Text>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              最近的20次数据将会实时展示在此处
                            </Card.Text>
                          </div>

                          <ListGroup style={{ marginTop: "15px" }}>
                            <ListGroup.Item
                              className="align-items-start"
                              style={{ backgroundColor: "#EEE" }}
                            >
                              <Card.Text
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                  color: "#000000",
                                }}
                              >
                                上次更新: {new Date().toLocaleString()}
                              </Card.Text>
                              <Spinner animation="grow" variant="success" />
                            </ListGroup.Item>
                            {eventLogs.map((logItm) => {
                              var logTypeStr = "";
                              var logUserEmail = "";
                              var logOperationDescription = "";

                              if (logItm.type != null) {
                                if (logItm.type == "join") {
                                  logTypeStr = " <活动报名> ";
                                } else if (logItm.type == "operation") {
                                  logTypeStr = "操作";
                                  if (logItm.typeDescription != null) {
                                    if (logItm.typeDescription == "handraise") {
                                      logOperationDescription = "<举手>";
                                    } else if (
                                      logItm.typeDescription == "thumbup"
                                    ) {
                                      logOperationDescription = "<点赞>";
                                    } else if (
                                      logItm.typeDescription == "thumbdown"
                                    ) {
                                      logOperationDescription = "<踩>";
                                    } else if (
                                      logItm.typeDescription == "handclap"
                                    ) {
                                      logOperationDescription = "<鼓掌>";
                                    } else {
                                      logOperationDescription = "<其他>";
                                    }
                                  }
                                } else if (logItm.type == "checkin") {
                                  logTypeStr = "<活动签到>";
                                }
                              }
                              if (logItm.email != null) {
                                logUserEmail = logItm.email;
                              }
                              return (
                                <ListGroup.Item className="align-items-start">
                                  <Card.Text
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "normal",
                                      color: "#000000",
                                    }}
                                  >
                                    {logItm.timestamp.toDate().toLocaleString()}
                                  </Card.Text>
                                  <Card.Text
                                    style={{
                                      marginTop: "15px",
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                  >
                                    {logUserEmail}完成了{logTypeStr}{" "}
                                    {logOperationDescription != ""
                                      ? logOperationDescription
                                      : ""}
                                  </Card.Text>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#data-analytics">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              数据统计
                            </Card.Text>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              可修改活动信息，包括设置公共活动，结束活动等
                            </Card.Text>
                          </div>
                          <Row md={3} style={{ marginTop: "30px" }}>
                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  活动展示
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {views.length}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  活动参与者人数
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventMembers.length}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  活动出票人数
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventTicketMembers.length}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  活动签到人数
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventCheckInMembers.length}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  活动中奖人数
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventDrawWinnerMembers.length}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  活动邀请人数
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventInviteeEmails.length}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  评论
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventComments.length}个
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  照片
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventPics.length}张
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  -
                                </Card.Text>
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#data-analytics-impression">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              展示数据({views.length}次)
                            </Card.Text>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            ></Card.Text>
                          </div>

                          <Card style={{ marginTop: "15px" }}>
                            <Card.Title
                              style={{ marginLeft: "15px", marginTop: "15px" }}
                            >
                              流量分析
                            </Card.Title>
                            <div className="d-flex align-items-center justify-content-center">
                              <PieChart
                                label={({ dataEntry }) => dataEntry.title}
                                labelStyle={{
                                  fontSize: "5px",
                                  fontWeight: "normal",
                                }}
                                style={{ width: "500px", height: "500px" }}
                                data={[
                                  {
                                    title: "微信",
                                    value: viewTypes[0],
                                    color: "#09B83E",
                                  },
                                  {
                                    title: "领英",
                                    value: viewTypes[1],
                                    color: "#0072b1",
                                  },
                                  {
                                    title: "浏览器",
                                    value: viewTypes[2],
                                    color: "#3B3C36",
                                  },
                                  {
                                    title: "Instagram",
                                    value: viewTypes[3],
                                    color: "#feda75",
                                  },
                                  {
                                    title: "FB",
                                    value: viewTypes[4],
                                    color: "#3b5998",
                                  },
                                ]}
                                totalValue={views.length}
                              />
                            </div>
                          </Card>

                          <Row md={3} style={{ marginTop: "5px" }}>
                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  来自微信
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {viewTypes[0]}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (viewTypes[0] / views.length) * 100
                                  )}
                                  % 流量
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  来自领英LinkedIn
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {viewTypes[1]}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (viewTypes[1] / views.length) * 100
                                  )}
                                  % 流量
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  来自浏览器
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {viewTypes[2]}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (viewTypes[2] / views.length) * 100
                                  )}
                                  % 流量
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  来自Instgram
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {viewTypes[3]}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (viewTypes[3] / views.length) * 100
                                  )}
                                  % 流量
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  来自FB
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {viewTypes[4]}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (viewTypes[4] / views.length) * 100
                                  )}
                                  % 流量
                                </Card.Text>
                              </Card>
                            </Col>
                          </Row>

                          <ListGroup style={{ marginTop: "15px" }}>
                            <ListGroup.Item
                              className=""
                              style={{ backgroundColor: "#EEE" }}
                            >
                              <Row md={4}>
                                <Col>
                                  <Card.Text>时间</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>设备</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>平台</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>源头</Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            {views.map((vItm) => {
                              return (
                                <ListGroup.Item>
                                  <Row md={4}>
                                    <Col>
                                      <Card.Text>
                                        {vItm.timestamp
                                          .toDate()
                                          .toLocaleString()}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{vItm.device}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{vItm.platform}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{vItm.uaString}</Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#data-analytics-registration">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              报名数据({eventMembers.length})
                            </Card.Text>
                          </div>
                          <div className="align-items-start">
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                              }}
                            ></Card.Text>
                          </div>

                          <Row md={2} style={{ marginTop: "5px" }}>
                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  语言分布
                                </Card.Title>
                                <div className="d-flex align-items-center justify-content-center">
                                  <PieChart
                                    labelStyle={{
                                      fontSize: "5px",
                                      fontWeight: "normal",
                                    }}
                                    label={({ dataEntry }) => dataEntry.title}
                                    style={{ width: "90%", height: "90%" }}
                                    data={[
                                      {
                                        title: "英语",
                                        value: registerSourceType.typeEng,
                                        color: "#E38627",
                                      },
                                      {
                                        title: "中文",
                                        value:
                                          eventMembers.length -
                                          registerSourceType.typeEng,
                                        color: "#C13C37",
                                      },
                                    ]}
                                    totalValue={eventMembers.length}
                                  />
                                </div>

                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  英语:{" "}
                                  {Math.round(
                                    (registerSourceType.typeEng /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 中文:{" "}
                                  {Math.round(
                                    ((eventMembers.length -
                                      registerSourceType.typeEng) /
                                      eventMembers.length) *
                                      100
                                  )}
                                  %
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  签到
                                </Card.Title>
                                <div className="d-flex align-items-center justify-content-center">
                                  <PieChart
                                    labelStyle={{
                                      fontSize: "5px",
                                      fontWeight: "normal",
                                    }}
                                    label={({ dataEntry }) => dataEntry.title}
                                    style={{ width: "90%", height: "90%" }}
                                    data={[
                                      {
                                        title:
                                          registerSourceType.typeCheckIn == 0
                                            ? ""
                                            : "已签到",
                                        value: registerSourceType.typeCheckIn,
                                        color: "#E38627",
                                      },
                                      {
                                        title:
                                          eventMembers.length -
                                            registerSourceType.typeCheckIn ==
                                          0
                                            ? ""
                                            : "未签到",
                                        value:
                                          eventMembers.length -
                                          registerSourceType.typeCheckIn,
                                        color: "#C13C37",
                                      },
                                    ]}
                                    totalValue={eventMembers.length}
                                  />
                                </div>

                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  已签到:{" "}
                                  {Math.round(
                                    (registerSourceType.typeCheckIn /
                                      eventMembers.length) *
                                      100
                                  )}
                                  %
                                </Card.Text>
                              </Card>
                            </Col>
                          </Row>

                          <Row md={3} style={{ marginTop: "5px" }}>
                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  语言(中文)
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventMembers.length -
                                    registerSourceType.typeEng}
                                  人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    ((eventMembers.length -
                                      registerSourceType.typeEng) /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 总人数
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  语言(英语)
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {registerSourceType.typeEng}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (registerSourceType.typeEng /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 总人数
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  已完成签到
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {registerSourceType.typeCheckIn}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (registerSourceType.typeCheckIn /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 总人数
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  已出票
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {registerSourceType.typeTicket}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (registerSourceType.typeTicket /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 总人数
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  今日报名人数
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {registerSourceType.typeTodayTicket}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (registerSourceType.typeTodayTicket /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 总人数
                                </Card.Text>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  {registerSourceType.typeTodayTicketIncrease >
                                  0
                                    ? "今日增长人数"
                                    : "今日对比昨日"}
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {registerSourceType.typeTodayTicketIncrease}人
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {Math.round(
                                    (registerSourceType.typeTodayTicketIncrease /
                                      eventMembers.length) *
                                      100
                                  )}
                                  % 总人数
                                </Card.Text>
                              </Card>
                            </Col>
                          </Row>

                          <ListGroup style={{ marginTop: "15px" }}>
                            <ListGroup.Item
                              className=""
                              style={{ backgroundColor: "#EEE" }}
                            >
                              <Row md={6}>
                                <Col>
                                  <Card.Text>报名时间</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>姓名</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>电子邮箱</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>签到情况</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>票据</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>语言</Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            {eventMembers.map((memItm) => {
                              var langId = "";
                              var invitedEmail = "";
                              if (memItm.lang != null) {
                                langId = memItm.lang;
                              }
                              if (memItm.invitedBy != null) {
                                invitedEmail = memItm.invitedBy;
                              }
                              return (
                                <ListGroup.Item>
                                  <Row md={6}>
                                    <Col>
                                      <Card.Text>
                                        {memItm.timestamp
                                          .toDate()
                                          .toLocaleString()}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.name}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>
                                        {invitedEmail == ""
                                          ? ""
                                          : "(受邀请) - " + invitedEmail}{" "}
                                        {memItm.email}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>
                                        {memItm.checkinStatus
                                          ? "已签到"
                                          : "未签到"}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.ticketId}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>
                                        {langId == ""
                                          ? "中文"
                                          : langId == "en"
                                          ? "英语"
                                          : "中文"}
                                      </Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#payment-tickets">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              票据
                            </Card.Text>
                            <Button
                              variant="dark"
                              onClick={() => {
                                window.location.href =
                                  "/generate-receipt/" + id;
                              }}
                            >
                              创建活动收据
                            </Button>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#marketing">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              市场和推广
                            </Card.Text>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#marketing-social">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              分享到社交媒体
                            </Card.Text>
                          </div>

                          <Row md={6} style={{ marginTop: "60px" }}>
                            <Col className="d-flex justify-content-center align-items-center">
                              <FacebookShareButton
                                url={"https://tickets.evtgo.com/" + id}
                              >
                                <FacebookIcon size={32} round={true} />
                              </FacebookShareButton>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                              <WeiboShareButton
                                title={eventData.title}
                                image={eventData.photoURL}
                                url={"https://tickets.evtgo.com/" + id}
                              >
                                <WeiboIcon size={32} round={true} />
                              </WeiboShareButton>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                              <WhatsappShareButton
                                title={eventData.title}
                                image={eventData.photoURL}
                                url={"https://tickets.evtgo.com/" + id}
                              >
                                <WhatsappIcon size={32} round={true} />
                              </WhatsappShareButton>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                              <LinkedinShareButton
                                title={eventData.title}
                                image={eventData.photoURL}
                                url={"https://tickets.evtgo.com/" + id}
                              >
                                <LinkedinIcon size={32} round={true} />
                              </LinkedinShareButton>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                              <TwitterShareButton
                                title={eventData.title}
                                image={eventData.photoURL}
                                url={"https://tickets.evtgo.com/" + id}
                              >
                                <TwitterIcon size={32} round={true} />
                              </TwitterShareButton>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                              <LineShareButton
                                title={eventData.title}
                                image={eventData.photoURL}
                                url={"https://tickets.evtgo.com/" + id}
                              >
                                <LineIcon size={32} round={true} />
                              </LineShareButton>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#marketing-email">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              发送电子邮件
                            </Card.Text>
                          </div>
                          <Row>
                            <Col md={8}>
                              <Form style={{ marginTop: "15px" }}>
                                <Form.Group
                                  className="d-flex align-items-center justify-content-center"
                                  id="select-tyoe"
                                >
                                  <ButtonGroup className="align-items-center justify-content-center">
                                    <Button
                                      variant={
                                        eventEmailLang == "en"
                                          ? "dark"
                                          : "outline-dark"
                                      }
                                      onClick={() => {
                                        setEventEmailLang("en");
                                        var eveMemCount = 0;
                                        var emailLs = [];

                                        var emailHeader = [];
                                        emailHeader.push([
                                          "email",
                                          "name",
                                          "lang",
                                        ]);
                                        eventMembers.map((eveMem) => {
                                          if (eveMem.lang == "en") {
                                            eveMemCount += 1;
                                            emailLs.push(eveMem.email);
                                            emailHeader.push([
                                              eveMem.email,
                                              eveMem.name,
                                              eveMem.lang,
                                            ]);
                                          }
                                        });
                                        setEventEmailList(emailLs);
                                        setEventEmailCount(eveMemCount);
                                        setCSVEmailData(emailHeader);
                                      }}
                                    >
                                      English
                                    </Button>
                                    <Button
                                      variant={
                                        eventEmailLang == "zh"
                                          ? "dark"
                                          : "outline-dark"
                                      }
                                      onClick={() => {
                                        setEventEmailLang("zh");
                                        var eveMemCount = 0;
                                        var emailLs = [];

                                        var emailHeader = [];
                                        emailHeader.push([
                                          "email",
                                          "name",
                                          "lang",
                                        ]);
                                        eventMembers.map((eveMem) => {
                                          if (eveMem.lang == "zh") {
                                            eveMemCount += 1;
                                            emailLs.push(eveMem.email);
                                            emailHeader.push([
                                              eveMem.email,
                                              eveMem.name,
                                              eveMem.lang,
                                            ]);
                                          }
                                        });
                                        setEventEmailList(emailLs);
                                        setEventEmailCount(eveMemCount);
                                        setCSVEmailData(emailHeader);
                                      }}
                                    >
                                      简体中文
                                    </Button>
                                    <Button
                                      variant={
                                        eventEmailLang == ""
                                          ? "dark"
                                          : "outline-dark"
                                      }
                                      onClick={() => {
                                        setEventEmailLang("");
                                        var eveMemCount = 0;
                                        var emailLs = [];
                                        eventMembers.map((eveMem) => {
                                          eveMemCount += 1;
                                          emailLs.push(eveMem.email);
                                        });
                                        setEventEmailList(emailLs);
                                        setEventEmailCount(eveMemCount);
                                        setEventEmailCount(eventMembers.length);
                                      }}
                                    >
                                      全部
                                    </Button>
                                  </ButtonGroup>
                                </Form.Group>

                                <Form.Group
                                  id="action"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label className="text-center">
                                    选择语言以便发送给不同客户
                                  </Form.Label>
                                  <Button
                                    variant="success"
                                    style={{ width: "100%", marginTop: "15px" }}
                                    onClick={() => {
                                      var emailList = [];
                                      eventMembers.map((memItm) => {
                                        var langId = "";
                                        var memEmail = "";
                                        if (memItm.email != null) {
                                          memEmail = memItm.email;
                                        }
                                        if (memItm.lang != null) {
                                          langId = memItm.lang;
                                        } else {
                                          langId = "zh";
                                        }
                                        if (eventEmailLang == "") {
                                          emailList.push({
                                            name: memItm.name,
                                            email: memEmail,
                                            lang: langId,
                                          });
                                        } else {
                                          if (langId == eventEmailLang) {
                                            emailList.push({
                                              name: memItm.name,
                                              email: memEmail,
                                              lang: langId,
                                            });
                                          }
                                        }
                                      });
                                      if (orgData.id != null) {
                                        fires
                                          .collection("groups")
                                          .doc(orgData.id)
                                          .collection("emails")
                                          .add({
                                            emails: emailList,
                                            eventId: id,
                                            eventTitle: eventData.title,
                                            lang: eventEmailLang,
                                            eventStartDate: eventData.startDate,
                                            eventEndData: eventData.endDate,
                                          })
                                          .then((eventR) => {
                                            const emailListId = eventR.id;
                                            window.location.href =
                                              "/send-emails/" + emailListId;
                                          });
                                      }
                                    }}
                                  >
                                    {eventEmailLang == "en"
                                      ? "创建英文电子邮件列表(" +
                                        eventEmailCount +
                                        "个)"
                                      : eventEmailLang == "zh"
                                      ? "创建中文电子邮箱列表(" +
                                        eventEmailCount +
                                        "个)"
                                      : "创建电子邮箱列表(" +
                                        eventEmailCount +
                                        "个)"}
                                  </Button>

                                  <CSVLink
                                    filename={
                                      "event-email-" + eventEmailLang + ".csv"
                                    }
                                    data={csvEmailData}
                                    className="btn btn-dark"
                                    style={{
                                      color: "white",
                                      marginTop: "15px",
                                    }}
                                  >
                                    下载Email List
                                  </CSVLink>
                                </Form.Group>
                              </Form>
                            </Col>
                            <Col md={4}>
                              <ListGroup style={{ marginTop: "15px" }}>
                                <ListGroup.Item
                                  className=""
                                  style={{ backgroundColor: "#EEE" }}
                                >
                                  <Row md={3}>
                                    <Col>
                                      <Card.Text>姓名</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>电子邮箱</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>语言</Card.Text>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                                {eventMembers.map((memItm) => {
                                  var langId = "";
                                  if (memItm.lang != null) {
                                    langId = memItm.lang;
                                  }
                                  return (
                                    <ListGroup.Item>
                                      <Row md={3}>
                                        <Col>
                                          <Card.Text>{memItm.name}</Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text>{memItm.email}</Card.Text>
                                        </Col>
                                        <Col>
                                          <Card.Text>
                                            {langId == ""
                                              ? "中文"
                                              : langId == "en"
                                              ? "英语"
                                              : "中文"}
                                          </Card.Text>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  );
                                })}
                              </ListGroup>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#customers">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#000000",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              客户管理
                            </Card.Text>
                          </div>

                          <ListGroup style={{ marginTop: "15px" }}>
                            <ListGroup.Item
                              className=""
                              style={{ backgroundColor: "#EEE" }}
                            >
                              <Row md={6}>
                                <Col>
                                  <Card.Text>报名时间</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>姓名</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>电子邮箱</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>票据</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>语言</Card.Text>
                                </Col>
                                <Col>
                                  <Card.Text>设置</Card.Text>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            {eventMembers.map((memItm) => {
                              var langId = "";
                              if (memItm.lang != null) {
                                langId = memItm.lang;
                              }
                              return (
                                <ListGroup.Item>
                                  <Row md={6}>
                                    <Col>
                                      <Card.Text>
                                        {memItm.timestamp
                                          .toDate()
                                          .toLocaleString()}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.name}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.email}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>{memItm.ticketId}</Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Text>
                                        {langId == ""
                                          ? "中文"
                                          : langId == "en"
                                          ? "英语"
                                          : "中文"}
                                      </Card.Text>
                                    </Col>
                                    <Col>
                                      <Card.Link
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                        onClick={() => {}}
                                      >
                                        {"修改资料"}
                                      </Card.Link>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Tab.Pane>

                        <Tab.Pane eventKey="#dangers">
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text
                              className="text-center"
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                              }}
                            >
                              删除活动
                            </Card.Text>
                          </div>
                          <DropdownButton
                            alignRight
                            title={"永久删除活动"}
                            id="dropdown-menu-align-right"
                            variant={"danger"}
                            onSelect={(e) => {
                              if (e == "delete") {
                                var confirmA =
                                  window.confirm("你想要删除这个活动吗?");
                                if (confirmA) {
                                  fires
                                    .collection("events")
                                    .doc(id)
                                    .delete()
                                    .then(() => {
                                      window.location.href = "/";
                                    });
                                }
                              }
                            }}
                            style={{ padding: "8px" }}
                          >
                            <Dropdown.Item
                              eventKey="delete"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              永久删除活动
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="ended">
                              结束活动
                            </Dropdown.Item>
                          </DropdownButton>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>

                <Modal
                  show={showEmailPreview}
                  onHide={() => {
                    setShowEmailPreview(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>预览邮件</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Accordion
                      defaultActiveKey="email-en"
                      style={{ marginTop: "15px" }}
                    >
                      <Accordion.Item eventKey="email-en">
                        <Accordion.Header>英语邮件</Accordion.Header>
                        <Accordion.Body>
                          <ListGroup>
                            <ListGroup.Item className="align-items-start">
                              <div className="fw-normal">标题</div>
                              <div className="fw-bold">
                                {eventEmailSaved.en.title}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="align-items-start">
                              <div className="fw-normal">正文</div>
                              <div className="fw-bold">
                                {eventEmailSaved.en.content}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="align-items-start">
                              <div className="fw-normal">邮件数</div>
                              <div className="fw-bold">
                                {eventEmailSaved.en.emails.length}
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup style={{ marginTop: "5px" }}>
                            {eventEmailSaved.en.emails.map((emailI) => {
                              return (
                                <ListGroup.Item className="align-items-start">
                                  <div className="fw-normal">{emailI}</div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="email-zh">
                        <Accordion.Header>中文邮件</Accordion.Header>
                        <Accordion.Body>
                          <ListGroup>
                            <ListGroup.Item className="align-items-start">
                              <div className="fw-normal">标题</div>
                              <div className="fw-bold">
                                {eventEmailSaved.zh.title}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="align-items-start">
                              <div className="fw-normal">正文</div>
                              <div className="fw-bold">
                                {eventEmailSaved.zh.content}
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="align-items-start">
                              <div className="fw-normal">邮件数</div>
                              <div className="fw-bold">
                                {eventEmailSaved.zh.emails.length}
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup style={{ marginTop: "5px" }}>
                            {eventEmailSaved.zh.emails.map((emailI) => {
                              return (
                                <ListGroup.Item className="align-items-start">
                                  <div className="fw-normal">{emailI}</div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="success"
                      onClick={() => {
                        var emailSent = eventEmailSent;
                        eventEmailSaved.zh.emails.forEach((emailI) => {
                          if (
                            eventEmailSaved.zh.title != "" &&
                            eventEmailSaved.zh.content != ""
                          ) {
                            if (emailI != "") {
                              const messageObj = {
                                subject: eventEmailSaved.zh.title,
                                text: eventEmailSaved.zh.content,
                              };
                              fires
                                .collection("mail")
                                .add({
                                  to: [emailI],
                                  message: messageObj,
                                })
                                .then((docRef) => {
                                  const sentId = docRef.id;

                                  emailSent.push(emailI);
                                  setEventEmailSent(emailSent);
                                });
                            }
                          }
                        });
                        eventEmailSaved.en.emails.forEach((emailI) => {
                          if (
                            eventEmailSaved.en.title != "" &&
                            eventEmailSaved.en.content != ""
                          ) {
                            if (emailI != "") {
                              const messageObj = {
                                subject: eventEmailSaved.en.title,
                                text: eventEmailSaved.en.content,
                              };
                              fires
                                .collection("mail")
                                .add({
                                  to: [emailI],
                                  message: messageObj,
                                })
                                .then((docRef) => {
                                  const sentId = docRef.id;

                                  emailSent.push(emailI);
                                  setEventEmailSent(emailSent);
                                });
                            }
                          }
                        });
                      }}
                    >
                      发送邮件
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowEmailPreview(false);
                      }}
                    >
                      关闭
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showInvitees}
                  onHide={() => {
                    setShowInvitees(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>添加邀请人</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card.Text>
                      您可以添加受邀人员名单，这个名单将保证您的活动参与者中不会出现未受邀的成员
                    </Card.Text>
                    <Form hidden={inviteeDisplayType == 1}>
                      <Form.Group
                        id="invitee-email"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          受邀请人的邮箱 *
                        </Form.Label>
                        <Form.Control
                          type="email"
                          rows={1}
                          ref={eventInviteeEmailRef}
                          required
                        />
                      </Form.Group>
                      <Row md={2}>
                        <Col>
                          <Form.Group
                            id="invitee-fn"
                            style={{ marginTop: "5px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              受邀请人的名 First Name
                            </Form.Label>
                            <Form.Control
                              type="name"
                              rows={1}
                              ref={eventInviteeFirstNameRef}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            id="invitee-ln"
                            style={{ marginTop: "5px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              受邀请人的姓 Last Name
                            </Form.Label>
                            <Form.Control
                              type="name"
                              rows={1}
                              ref={eventInviteeLastNameRef}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "25px" }}
                      >
                        <Button
                          variant="success"
                          onClick={() => {
                            const emailU = eventInviteeEmailRef.current.value;
                            const firstNameU =
                              eventInviteeFirstNameRef.current.value;
                            const lastNameU =
                              eventInviteeLastNameRef.current.value;

                            var timestampNow =
                              firebase.firestore.Timestamp.fromDate(new Date());
                            if (emailU != "") {
                              const inviteeSet = {
                                email: emailU,
                                firstName: firstNameU,
                                lastName: lastNameU,
                                timestamp: timestampNow,
                              };
                              fires
                                .collection("events")
                                .doc(id)
                                .collection("invitees")
                                .doc(emailU)
                                .set(inviteeSet)
                                .then(() => {
                                  eventInviteeEmailRef.current.value = "";
                                  eventInviteeFirstNameRef.current.value = "";
                                  eventInviteeLastNameRef.current.value = "";
                                });
                            }
                          }}
                        >
                          添加邀请人
                        </Button>
                      </Form.Group>
                    </Form>

                    <Accordion
                      defaultActiveKey={
                        inviteeDisplayType == 1 ? "show-invitee" : "A"
                      }
                      style={{ marginTop: "45px" }}
                    >
                      <Accordion.Item eventKey="show-invitee">
                        <Accordion.Header>
                          受邀人员({eventInvitees.length})
                        </Accordion.Header>
                        <Accordion.Body>
                          <ListGroup>
                            {eventInvitees.map((inviteeItm) => {
                              return (
                                <ListGroup.Item>
                                  <div className="d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">
                                      {inviteeItm.email}
                                    </div>
                                    <div className="fw-normal">
                                      {inviteeItm.firstName}{" "}
                                      {inviteeItm.lastName}
                                    </div>
                                  </div>
                                  <div style={{ marginTop: "10px" }}>
                                    <Card.Link
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        if (inviteeItm.email != "") {
                                          const agreeDelete =
                                            window.confirm(
                                              "你确定要删除这个人吗？"
                                            );
                                          if (agreeDelete) {
                                            fires
                                              .collection("events")
                                              .doc(id)
                                              .collection("invitees")
                                              .doc(inviteeItm.email)
                                              .delete(() => {});
                                          }
                                        }
                                      }}
                                    >
                                      删除
                                    </Card.Link>
                                  </div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowInvitees(false);
                      }}
                    >
                      关闭
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showImportMembers}
                  onHide={() => {
                    setShowImportMembers(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>从Excel导入活动参与者</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {memberImportMessage && (
                      <Alert variant="success">{memberImportMessage}</Alert>
                    )}
                    <div>
                      <input
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={handleCSVChange}
                      />
                      <Button
                        variant="dark"
                        onClick={(e) => {
                          handleCSVSubmit(e);
                        }}
                      >
                        导入CSV
                      </Button>
                    </div>
                    <Form hidden={memberArray.length == 0}>
                      <Form.Group style={{ marginTop: "15px" }}>
                        <Form.Label>
                          * 名字(First Name)({memberHeaderOptions.first_name})
                        </Form.Label>
                        <DropdownButton
                          className="border-0"
                          title={
                            "名字(First Name)" +
                            " - " +
                            memberHeaderOptions.first_name
                          }
                          id=""
                          variant={"outline-dark"}
                          onSelect={(e) => {
                            var memberHeaderOptionss = memberHeaderOptions;
                            if (e == "letjoy-cancel-selection") {
                              memberHeaderOptionss.first_name = "";
                            } else {
                              if (e.includes("SEPERATOR")) {
                                memberHeaderOptionss.first_name =
                                  e.split("SEPERATOR")[0];
                                memberHeaderOptionss.first_name_index =
                                  parseInt(e.split("SEPERATOR")[1]);
                              }
                            }
                            setMemberHeaderOptions(memberHeaderOptionss);
                            setMemberImportMessage(
                              "姓名FN已设置 - " +
                                memberHeaderOptionss.first_name
                            );
                          }}
                        >
                          {memberHeaderSet.map((headerKey, indexx) => {
                            return (
                              <Dropdown.Item
                                eventKey={headerKey + "SEPERATOR" + indexx}
                              >
                                {headerKey}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="letjoy-cancel-selection">
                            清除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>
                      <Form.Group style={{ marginTop: "15px" }}>
                        <Form.Label>
                          * 名字(Last Name)({memberHeaderOptions.last_name})
                        </Form.Label>
                        <DropdownButton
                          className="border-0"
                          title={
                            "名字(Last Name)" +
                            " - " +
                            memberHeaderOptions.last_name
                          }
                          id=""
                          variant={"outline-dark"}
                          onSelect={(e) => {
                            var memberHeaderOptionss = memberHeaderOptions;
                            if (e == "letjoy-cancel-selection") {
                              memberHeaderOptionss.last_name = "";
                            } else {
                              if (e.includes("SEPERATOR")) {
                                memberHeaderOptionss.last_name =
                                  e.split("SEPERATOR")[0];
                                memberHeaderOptionss.last_name_index = parseInt(
                                  e.split("SEPERATOR")[1]
                                );
                              }
                            }
                            setMemberHeaderOptions(memberHeaderOptionss);
                            setMemberImportMessage(
                              "姓名LN已设置 - " + memberHeaderOptionss.last_name
                            );
                          }}
                        >
                          {memberHeaderSet.map((headerKey, indexx) => {
                            return (
                              <Dropdown.Item
                                eventKey={headerKey + "SEPERATOR" + indexx}
                              >
                                {headerKey}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="letjoy-cancel-selection">
                            清除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>
                      <Form.Group style={{ marginTop: "15px" }}>
                        <Form.Label>
                          * 邮箱({memberHeaderOptions.email})
                        </Form.Label>
                        <DropdownButton
                          className="border-0"
                          title={"邮箱" + " - " + memberHeaderOptions.email}
                          id=""
                          variant={"outline-dark"}
                          onSelect={(e) => {
                            var memberHeaderOptionss = memberHeaderOptions;
                            if (e == "letjoy-cancel-selection") {
                              memberHeaderOptionss.email = "";
                            } else {
                              if (e.includes("SEPERATOR")) {
                                memberHeaderOptionss.email =
                                  e.split("SEPERATOR")[0];
                                memberHeaderOptionss.email_index = parseInt(
                                  e.split("SEPERATOR")[1]
                                );
                              }
                            }
                            setMemberHeaderOptions(memberHeaderOptionss);
                            setMemberImportMessage(
                              "Email 已设置 - " + memberHeaderOptionss.email
                            );
                          }}
                        >
                          {memberHeaderSet.map((headerKey, indexx) => {
                            return (
                              <Dropdown.Item
                                eventKey={headerKey + "SEPERATOR" + indexx}
                              >
                                {headerKey}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="letjoy-cancel-selection">
                            清除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>

                      <Form.Group style={{ marginTop: "15px" }}>
                        <Form.Label>
                          * 号码({memberHeaderOptions.ticketId})
                        </Form.Label>
                        <DropdownButton
                          className="border-0"
                          title={
                            "门票信息" + " - " + memberHeaderOptions.ticketId
                          }
                          id=""
                          variant={"outline-dark"}
                          onSelect={(e) => {
                            var memberHeaderOptionss = memberHeaderOptions;
                            if (e == "letjoy-cancel-selection") {
                              memberHeaderOptionss.ticketId = "";
                              memberHeaderOptionss.ticket_id_index = -1;
                            } else {
                              if (e.includes("SEPERATOR")) {
                                memberHeaderOptionss.ticketId =
                                  e.split("SEPERATOR")[0];
                                memberHeaderOptionss.ticket_id_index = parseInt(
                                  e.split("SEPERATOR")[1]
                                );
                              }
                            }
                            setMemberHeaderOptions(memberHeaderOptionss);
                            setMemberImportMessage(
                              "门票号已设置 - " + memberHeaderOptionss.ticketId
                            );
                          }}
                        >
                          {memberHeaderSet.map((headerKey, indexx) => {
                            return (
                              <Dropdown.Item
                                eventKey={headerKey + "SEPERATOR" + indexx}
                              >
                                {headerKey}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="letjoy-cancel-selection">
                            清除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>

                      <Form.Group style={{ marginTop: "15px" }}>
                        <Form.Label>
                          * 数量({memberHeaderOptions.quantity})
                        </Form.Label>
                        <DropdownButton
                          className="border-0"
                          title={"数量" + " - " + memberHeaderOptions.quantity}
                          id=""
                          variant={"outline-dark"}
                          onSelect={(e) => {
                            var memberHeaderOptionss = memberHeaderOptions;
                            if (e == "letjoy-cancel-selection") {
                              memberHeaderOptionss.quantity = "";
                              memberHeaderOptionss.quantity_index = -1;
                            } else {
                              if (e.includes("SEPERATOR")) {
                                memberHeaderOptionss.quantity =
                                  e.split("SEPERATOR")[0];
                                memberHeaderOptionss.quantity_index = parseInt(
                                  e.split("SEPERATOR")[1]
                                );
                              }
                            }
                            setMemberHeaderOptions(memberHeaderOptionss);
                            setMemberImportMessage(
                              "数量已设置 - " + memberHeaderOptionss.quantity
                            );
                          }}
                        >
                          {memberHeaderSet.map((headerKey, indexx) => {
                            return (
                              <Dropdown.Item
                                eventKey={headerKey + "SEPERATOR" + indexx}
                              >
                                {headerKey}
                              </Dropdown.Item>
                            );
                          })}
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="letjoy-cancel-selection">
                            清除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>

                      <Form.Group style={{ marginTop: "25px" }}>
                        <Button
                          variant="dark"
                          style={{ width: "100%" }}
                          onClick={() => {
                            handleCSVMemberImport();
                          }}
                        >
                          开始导入
                        </Button>
                      </Form.Group>
                    </Form>

                    <ListGroup style={{ marginTop: "25px" }}>
                      {eventImportMembers.map((memItm) => {
                        const fn = memItm.firstName;
                        const ln = memItm.lastName;
                        const email = memItm.email;
                        const count = memItm.count;
                        const ids = memItm.tickets;
                        return (
                          <ListGroup.Item
                            className="align-items-start"
                            style={{ width: "100%" }}
                          >
                            <div>
                              {fn + " " + ln} ({count}人)
                            </div>
                            <div>{email}</div>
                            <div>{ids.join("\n")}</div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="success"
                      hidden={eventImportMembers.length == 0}
                      onClick={() => {
                        handleCSVMemberImportConfirmed();
                      }}
                    >
                      导入
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowImportMembers(false);
                      }}
                    >
                      关闭
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showAddMembers}
                  onHide={() => {
                    setShowAddMembers(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>添加活动参与者</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card.Text></Card.Text>
                    <Form>
                      {eventMemberMessage && (
                        <Alert variant="success">{eventMemberMessage}</Alert>
                      )}
                      <Form.Group
                        id="member-email"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          参与者的邮箱/ID *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          rows={1}
                          ref={eventMemberEmailRef}
                          required
                        />
                      </Form.Group>
                      <Row md={2}>
                        <Col>
                          <Form.Group
                            id="member-fn"
                            style={{ marginTop: "5px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              参与者的名 First Name
                            </Form.Label>
                            <Form.Control
                              type="name"
                              rows={1}
                              ref={eventMemberFirstNameRef}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            id="member-ln"
                            style={{ marginTop: "5px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              参与者的姓 Last Name
                            </Form.Label>
                            <Form.Control
                              type="name"
                              rows={1}
                              ref={eventMemberLastNameRef}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group
                        id="member-invitee-email"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          (选填) 邀请人的邮箱
                        </Form.Label>
                        <Form.Control
                          type="email"
                          rows={1}
                          ref={eventMemberInviteeEmailRef}
                        />
                      </Form.Group>
                      <Form.Group
                        id="member-email"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          (选填) 参与者的ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          rows={1}
                          ref={eventMemberTicketIdRef}
                        />
                      </Form.Group>
                      <Form.Group
                        id="member-email"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          参与者的数量 *
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={1}
                          ref={eventMemberNumberRef}
                          min={1}
                          defaultValue={1}
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        id="member-language"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          语言 *
                        </Form.Label>
                        <DropdownButton
                          style={{ marginTop: "5px" }}
                          title={
                            eventMemberLanguageSelected == "en"
                              ? "English"
                              : eventMemberLanguageSelected == "zh"
                              ? "中文"
                              : "语言"
                          }
                          id=""
                          variant="outline-dark"
                          onSelect={(e) => {
                            if (e == "en") {
                              setEventMemberLanguageSelected("en");
                              setEventMemberMessage("设置语言为英语");
                            } else {
                              setEventMemberLanguageSelected("zh");
                              setEventMemberMessage("设置语言为中文");
                            }
                          }}
                        >
                          <Dropdown.Item key="en">English</Dropdown.Item>
                          <Dropdown.Item key="zh">中文</Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>

                      <Form.Group
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "25px" }}
                      >
                        <Button
                          variant="success"
                          onClick={() => {
                            const emailU = eventMemberEmailRef.current.value;
                            const firstNameU =
                              eventMemberFirstNameRef.current.value;
                            const lastNameU =
                              eventMemberLastNameRef.current.value;
                            const inviteeEmail =
                              eventMemberInviteeEmailRef.current.value;
                            const eventMemberNumber =
                              eventMemberNumberRef.current.value;
                            const eventMemberId =
                              eventMemberTicketIdRef.current.value;

                            var allTickets = [];
                            if (eventMemberNumber > 1) {
                              for (let i = 0; i < eventMemberNumber - 1; i++) {
                                const cTicket = createTicketId();
                                allTickets.push(cTicket);
                              }
                            }
                            var ticketId = "";
                            if (eventMemberId != "" || eventMemberId != null) {
                              ticketId = eventMemberId;
                            } else {
                              ticketId = createTicketId();
                            }

                            var timestampNow =
                              firebase.firestore.Timestamp.fromDate(new Date());
                            if (emailU != "") {
                              var memberSet = {
                                email: emailU,
                                firstName: firstNameU,
                                lastName: lastNameU,
                                name: firstNameU + " " + lastNameU,
                                checkinStatus: false,
                                handsup: false,
                                thumbup: false,
                                thumbdown: false,
                                handsclap: false,
                                handsup: false,
                                ticketId: ticketId,
                                invitedBy: inviteeEmail,
                                tickets: allTickets,
                                quantity: allTickets.length + 1,
                                wechat: "",
                                work: "",
                                timestamp: timestampNow,
                              };
                              fires
                                .collection("events")
                                .doc(id)
                                .collection("members")
                                .doc(emailU)
                                .set(memberSet)
                                .then(() => {
                                  eventMemberEmailRef.current.value = "";
                                  eventMemberFirstNameRef.current.value = "";
                                  eventMemberLastNameRef.current.value = "";
                                  eventMemberInviteeEmailRef.current.value = "";
                                  eventMemberTicketIdRef.current.value = "";
                                  const fullName = firstNameU + " " + lastNameU;

                                  //register ticket
                                  allTickets.forEach((tickId) => {
                                    registerTicket(
                                      tickId,
                                      eventData.title,
                                      eventData.id,
                                      eventData.name,
                                      firstNameU + " " + lastNameU,
                                      ""
                                    );
                                  });

                                  //add to invite list
                                  const inviteeSet = {
                                    email: emailU,
                                    firstName: firstNameU,
                                    lastName: lastNameU,
                                    timestamp: timestampNow,
                                  };
                                  fires
                                    .collection("events")
                                    .doc(id)
                                    .collection("invitees")
                                    .doc(emailU)
                                    .set(inviteeSet)
                                    .then(() => {});

                                  //send email
                                  // const dateString = eventData.startDate.toDate().toLocaleDateString("zh") + ' ' + eventData.startDate.toDate().toLocaleTimeString("zh")
                                  // const addr = eventData.address.fullAddress == '' ? '网络直播' : eventData.address.fullAddress
                                  // sendEmailTo(
                                  //     emailU,
                                  //     orgData.name,
                                  //     eventData.title,
                                  //     dateString,
                                  //     addr,
                                  //     fullName,
                                  //     ticketId,
                                  //     id,
                                  //     allTickets)
                                });
                            }
                          }}
                        >
                          添加参与者
                        </Button>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowAddMembers(false);
                      }}
                    >
                      关闭
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </FullScreen>
    </>
  );
}
