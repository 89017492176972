import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Tab,
  Modal,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  DropdownButton,
  Dropdown,
  Badge,
  ButtonGroup,
  FloatingLabel,
  Spinner,
  Accordion,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires } from "../firebase";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { sendNewMemberEmail, stripeAPICreatePrice } from "../contexts/API";

import { useAudioRecorder } from "react-audio-voice-recorder";

import uuid from "react-uuid";
import firebase from "firebase";
import { QRCodeCanvas } from "qrcode.react";
import { Stepper, Step } from "react-form-stepper";
import Geocode from "react-geocode";
import { CSVLink } from "react-csv";
import SuperAdminAlert from "./SuperAdminAlert";
import { getNow } from "./Helper";

export default function AccountDashboard() {
  const { currentUser, logout } = useAuth();

  const [obError, setOBError] = useState("");

  const [actionSuccess, setActionSuccess] = useState("");

  const [allServices, setAllServices] = useState([]);

  const [posts, setPosts] = useState([]);
  const [gData, setGData] = useState({});
  const [viewTys, setViewTys] = useState([]);
  const [orgLinks, setOrgLinks] = useState([]);
  const [orgServices, setOrgServices] = useState([]);
  const [orgFeatures, setOrgFeatures] = useState([]);
  const [orgTeam, setOrgTeam] = useState([]);
  const [orgQuotes, setOrgQuotes] = useState([]);
  const [orgFans, setOrgFans] = useState([]);
  const [orgAddress, setOrgAddress] = useState([]);
  const [orgPriceList, setOrgPriceList] = useState([]);
  const [orgMembers, setOrgMembers] = useState([]);
  const [orgPosts, setOrgPosts] = useState([]);
  const [orgExps, setOrgExps] = useState([]);
  const [orgEvents, setOrgEvents] = useState([]);
  const [orgInteractions, setOrgInteractions] = useState([]);
  const [orgFeedbacks, setOrgFeedbacks] = useState([]);
  const [orgLocations, setOrgLocations] = useState([]);
  const [orgEmailLists, setOrgEmailLists] = useState([]);
  const [orgMemberRequests, setOrgMemberRequests] = useState([]);

  //plan details
  const [planInfo, setPlanInfo] = useState({
    email: "",
    bean: 200,
    type: "free",
    typeCN: "免费",
    expiredOn: getNow(),
    timestamp: getNow(),
  });

  //user data
  const [userDetails, setUserDetails] = useState({});

  //team member invitation
  const inviteEmailRef = useRef();
  const inviteNameRef = useRef();

  //followerMember
  const inviteNewMemberEmailRef = useRef();
  const inviteNewMemberFirstNameRef = useRef();
  const inviteNewMemberLastNameRef = useRef();

  const qrRef = useRef();

  const [uploadS, setUploadS] = useState("请点击录音键开始录音");

  const [eventCreateMessage, setEventCreateMessage] = useState("");

  const [selectedEventId, setSelectedEventId] = useState("");

  //admin control
  const [adminStatus, setAdminStatus] = useState(false);
  const [adminData, setAdminData] = useState({
    email: '',
    roles: [],
    name: '',
  })
  //when this one is on, load all the events
  const [superAdminInEffect, setSuperAdminInEffect] = useState(false);
  const [groupIds, setGroupIds] = useState([]);
  const [groupAllData, setGroupAllData] = useState({});
  const [allEvents, setAllEvents] = useState({});

  const [gStyles, setGStyles] = useState({
    youtube: false,
    feedback: false,
    gallery: false,
    article: false,
    event: false,
    youtubeId: "",
    feedbacks: [{ id: "" }],
    qa: false,
    qaId: "",
    voice: false,
    voiceURL: "",
    navigation: false,
  });

  const maxW = isMobile ? "100%" : "100%";
  const recorderControls = useAudioRecorder();
  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  var id = currentUser.uid;
  const [userLang, setUserLang] = useState("zh");

  //modal team member
  const [showAddTeam, setShowAddTeam] = useState(false);
  const handleCloseAddTeam = () => setShowAddTeam(false);
  const handleShowAddTeam = () => setShowAddTeam(true);

  //modal membership
  const [showAddMember, setShowAddMember] = useState(false);
  const handleCloseAddMember = () => setShowAddMember(false);
  const handleShowAddMember = () => setShowAddMember(true);

  //modal membership request
  const [showMemberRequest, setShowMemberRequest] = useState(false);
  const handleCloseMemberRequest = () => setShowMemberRequest(false);
  const handleShowMemberRequest = () => setShowMemberRequest(true);

  const [showAddEvent, setShowAddEvent] = useState(false);
  const handleCloseAddEvent = () => setShowAddEvent(false);
  const handleShowAddEvent = () => setShowAddEvent(true);
  const [newEventStep, setNewEventStep] = useState(0);

  //add new
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const handleCloseAddNewItem = () => setShowAddNewItem(false);
  const handleShowAddNewItem = () => setShowAddNewItem(true);

  //event report
  const [showCreateEventReport, setShowCreateEventReport] = useState(false);
  const handleCloseCreateEventReportItem = () =>
    setShowCreateEventReport(false);
  const handleShowCreateEventReportItem = () => setShowCreateEventReport(true);
  const [currentEventDetails, setCurrentEventDetails] = useState({
    approved: false,
    title: "",
    description: "",
  });
  const [currentEventViews, setCurrentEventViews] = useState({
    total: 0,
    today: 0,
    sourceTypes: [],
    sources: {
      wechat: 0,
      browser: 0,
      linkedin: 0,
      fb: 0,
    },
  });

  const [currentEventTickets, setCurrentEventTickets] = useState({
    total: 0,
    today: 0,
    sources: {
      paid: 0,
      paidToday: 0,
      free: 0,
      freeToday: 0,
    },
  });

  async function loadCurrentEventWithDetailsReport(eventItm) {
    let cEventId = "";
    if (eventItm.id != null) {
      cEventId = eventItm.id;
    }
    if (cEventId != "") {
      fires
        .collection("events")
        .doc(cEventId)
        .collection("views")
        .get()
        .then((snapData) => {
          let totalViews = snapData.docs.length;
          let todayViews = 0;
          snapData.docs.forEach((doc) => {
            const viewD = doc.data();
            let timestampp = timestampNow;
            if (viewD.timestamp != null) {
              timestampp = viewD.timestamp;
            }
            if (isToday(timestampp.toDate())) {
              todayViews += 1;
            }
            if (viewD.fromUA != null) {
            }
          });
          setCurrentEventViews({
            total: totalViews,
            today: todayViews,
            sourceTypes: [],
            sources: {
              wechat: 0,
              browser: 0,
              linkedin: 0,
              fb: 0,
            },
          });
        });
      fires
        .collection("events")
        .doc(cEventId)
        .collection("tickets")
        .get()
        .then((snapData) => {
          let totalTickets = snapData.docs.length;
          let todayTickets = 0;
          let todayPaid = 0;
          let todayFree = 0;
          let totalPaid = 0;
          let totalFree = 0;
          snapData.docs.forEach((doc) => {
            const tD = doc.data();
            let timestampp = timestampNow;
            if (tD.timestamp != null) {
              timestampp = tD.timestamp;
            }
            if (isToday(timestampp.toDate())) {
              todayTickets++;
              if (tD.paymentId == null) {
                todayFree += 1;
              } else if (tD.paymentId === "") {
                todayFree += 1;
              } else {
                //paid
                todayPaid += 1;
              }
            }
            if (tD.paymentId == null) {
              totalFree += 1;
            } else if (tD.paymentId === "") {
              totalFree += 1;
            } else {
              //paid
              totalPaid += 1;
            }
          });
          setCurrentEventTickets({
            total: totalTickets,
            today: todayTickets,
            sources: {
              paid: totalPaid,
              paidToday: todayPaid,
              free: totalFree,
              freeToday: todayFree,
            },
          });
        });
    }
  }

  async function createEventAnalyticsAndSendEmailToUser() {}

  //new price
  const [showAddNewPrice, setShowAddNewPrice] = useState(false);
  const handleCloseAddNewPrice = () => setShowAddNewPrice(false);
  const handleShowAddNewPrice = () => setShowAddNewPrice(true);

  //event report
  const [showCreateReport, setShowCreateReport] = useState(false);
  const handleCloseCreateReport = () => setShowCreateReport(false);
  const handleShowCreateReport = () => setShowCreateReport(true);

  const [csvData, setCSVData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"],
  ]);

  const [eventContent, setEventContent] = useState({
    approved: false,
    address: {
      fullAddress: "",
      name: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zipCode: "",
      unit: "",
      online: false,
    },
    agenda: [],
    sponsor: [],
    invitees: [],
    inviteeSet: [],
    drawItems: {},
    country: "",
    title: "",
    description: "",
    descriptions: [],
    orgId: "",
    fromUID: "",
    type: "free-event",
    priceType: "FREE",
    price: 0,
    priceOriginal: 0,
    priceCurrency: "CAD",
    priceEMTEmail: "",
    priceId: "",
    productId: "",
    startDate: timestampNow,
    endDate: timestampNow,
    duration: "",
    location: "",
    photoURL: "",
    photoURLs: [],
    status: "live",
    pin: "",
    live: false,
    liveURL: "",
    ticket: false,
    ticketURL: "",
    user: { userName: "", userOrg: "", imageURL: "", uid: "" },
    topics: [],
    options: {
      poll: false,
      draw: false,
      namecard: false,
      review: false,
      comment: true,
      picture: true,
      "register-feedback": false,
      max: 500,
    },
    questions: [],
    viewCount: 0,
    timestamp: timestampNow,
  });

  const routeChangeProfile = () => {
    let path = "/update-profile";
    window.location.href = path;
  };
  const routeChangeGroupInfo = () => {
    let path = "/update-group-info";
    window.location.href = path;
  };
  const routeToDataAnalytic = () => {
    let path = "/data-analytic";
    window.location.href = path;
  };
  const routeToContactInfo = () => {
    let path = "/group-contact-info";
    window.location.href = path;
  };
  const routeToQuotes = () => {
    let path = "/group-quotes";
    window.location.href = path;
  };
  const routeToTeams = () => {
    let path = "/teams";
    window.location.href = path;
  };
  const routeToLinks = () => {
    let path = "/create-edit-links";
    window.location.href = path;
  };
  const routeToCreateQnA = () => {
    let path = "/create-interaction";
    window.location.href = path;
  };
  const routeToCreateForms = () => {
    let path = "/create-forms";
    window.location.href = path;
  };
  const routeToCreateExp = () => {
    let path = "/create-exp";
    window.location.href = path;
  };
  const routeToCreateLiveEvent = () => {
    let path = "/create-live-event";
    window.location.href = path;
  };
  const routeToCreateEvent = () => {
    let path = "/create-event";
    window.location.href = path;
  };
  const routeToResources = () => {
    let path = "/resources";
    window.location.href = path;
  };
  const routeToExps = () => {
    let path = "/experiences";
    window.location.href = path;
  };
  const routeToCreatePost = () => {
    let path = "/create-post";
    window.location.href = path;
  };
  const routeToCreateLinks = () => {
    let path = "/create-edit-links";
    window.location.href = path;
  };
  const routeToPost = () => {
    let path = "/posts";
    window.location.href = path;
  };
  const routeToEvents = () => {
    let path = "/events";
    window.location.href = path;
  };

  const routeToEventCheckIn = (eId) => {
    let path = "/event-checkin/" + eId;
    window.location.href = path;
  };

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const handleActionSelect = (e) => {
    if (e == "logout") {
      routeToLogout();
    } else if (e == "account-modify") {
      routeChangeProfile();
    }
  };

  // download QR code
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    const aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  function isImgUrl(url) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  }

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };
  const isThisWeek = (date) => {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  };

  const createAllNewModal = () => {
    return (
      <Modal show={showAddNewItem} onHide={handleCloseAddNewItem}>
        <Modal.Header closeButton>
          <Modal.Title>新建</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            className="w-100"
            variant="success"
            style={{ marginTop: "15px" }}
            onClick={() => {}}
          >
            邀请团队成员
          </Button>
          <Button
            className="w-100"
            variant="success"
            style={{ marginTop: "15px" }}
            onClick={() => {}}
          >
            邀请成为会员
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddNewItem}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const [stripeLoad, setStripeLoad] = useState(false);
  const createEventReportModel = () => {
    return (
      <Modal
        show={showCreateEventReport}
        onHide={handleCloseCreateEventReportItem}
      >
        <Modal.Header closeButton>
          <Modal.Title>{currentEventDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              className="fw-bold"
              style={{ marginTop: "15px", marginBottom: "25px" }}
            >
              活动点击量
            </div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">总点击</div>
            <div className="fw-bold">{currentEventViews.total}</div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">今日点击</div>
            <div className="fw-bold">{currentEventViews.today}</div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">点击来源</div>
            <div className="fw-bold">-</div>
          </div>

          <div>
            <div
              className="fw-bold"
              style={{ marginTop: "15px", marginBottom: "25px" }}
            >
              活动购票情况
            </div>
          </div>

          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">总票数</div>
            <div className="fw-bold">{currentEventTickets.total}</div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">今日总票数</div>
            <div className="fw-bold">{currentEventTickets.today}</div>
          </div>
          <div>
            <div className="fw-bold">总票数分类</div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">付款票</div>
            <div className="fw-bold">{currentEventTickets.sources.paid}</div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">免费票</div>
            <div className="fw-bold">{currentEventTickets.sources.free}</div>
          </div>
          <div>
            <div className="fw-bold">今日票数分类</div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">付款票</div>
            <div className="fw-bold">
              {currentEventTickets.sources.paidToday}
            </div>
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div className="fw-normal">免费票</div>
            <div className="fw-bold">
              {currentEventTickets.sources.freeToday}
            </div>
          </div>
          <ListGroup
            style={{ marginTop: "25px" }}
            hidden={adminAllPaymentsPerEventId.length == 0}
          >
            <ListGroup.Item
              style={{ backgroundColor: "#EEE" }}
              hidden={adminAllPaymentsPerEventId.length == 0}
            >
              <Row md={4}>
                <Col>
                  <Card.Text>姓名</Card.Text>
                </Col>
                <Col>
                  <Card.Text>邮箱</Card.Text>
                </Col>
                <Col>
                  <Card.Text>数量</Card.Text>
                </Col>
                <Col>
                  <Card.Text>总价</Card.Text>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item
              style={{ backgroundColor: "#EEE" }}
              hidden={adminAllPaymentsPerEventId.length == 0}
            >
              <Row md={4}>
                <Col>
                  <Card.Text>-</Card.Text>
                </Col>
                <Col>
                  <Card.Text>-</Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {adminAllPaymentDataPerEventId.totalQuantity}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    $
                    {Math.round(adminAllPaymentDataPerEventId.totalSales / 100)}
                  </Card.Text>
                </Col>
              </Row>
            </ListGroup.Item>
            {adminAllPaymentsPerEventId.map((paymentItm) => {
              let userEmail = paymentItm.email;
              let userName = paymentItm.name;
              if (paymentItm.registerEmail != null) {
                userEmail = paymentItm.registerEmail;
              }
              if (paymentItm.registerName != null) {
                userName = paymentItm.registerName;
              }
              return (
                <ListGroup.Item>
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="fw-normal">报名人姓名</div>
                    <div className="fw-bold">{userName}</div>
                  </div>
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="fw-normal">报名人邮箱</div>
                    <div className="fw-bold">{userEmail}</div>
                  </div>
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="fw-normal">数量</div>
                    <div className="fw-bold">{paymentItm.quantity}</div>
                  </div>
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="fw-normal">付款总额</div>
                    <div className="fw-bold">
                      ${Math.round(paymentItm.amount / 100)}{" "}
                      {paymentItm.currency.toUpperCase()}
                    </div>
                  </div>
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="fw-normal">付款方式</div>
                    <div className="fw-bold">{paymentItm.payment_method}</div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <div style={{ marginTop: "25px", marginBottom: "25px" }}>
            <Button
              variant="outline-dark"
              onClick={() => {
                const emailA = auth.currentUser.email;
                let wConfirm = window.confirm("确认加载总账目吗?");
                if (wConfirm) {
                  //
                  setStripeLoad(true);
                  loadStripeInformation(adminStatus, currentEventDetails.id);
                }
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!stripeLoad}
              />{" "}
              加载总账目
            </Button>
            <CSVLink
              filename={"event-data-" + currentEventDetails.title + ".csv"}
              data={csvData}
              hidden={adminAllPaymentsPerEventId.length == 0}
              className="btn btn-dark"
              style={{ color: "white", marginLeft: "15px" }}
            >
              下载支付数据
            </CSVLink>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseCreateEventReportItem}
          >
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const createEventModal = () => {
    return (
      <Modal show={showAddTeam} onHide={handleCloseAddTeam}>
        <Modal.Header closeButton>
          <Modal.Title>邀请团队成员</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="invite-email" style={{ marginTop: "5px" }}>
              <Form.Label style={{ fontWeight: "bold" }}>电子邮箱 *</Form.Label>
              <Form.Control
                type="email"
                ref={inviteEmailRef}
                rows={1}
                required
              />
            </Form.Group>
            <Form.Group id="invite-name" style={{ marginTop: "15px" }}>
              <Form.Label style={{ fontWeight: "bold" }}>姓名 *</Form.Label>
              <Form.Control type="text" ref={inviteNameRef} rows={1} required />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddTeam}>
            关闭
          </Button>
          <Button variant="success" onClick={(e) => {}}>
            邀请
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const createNewMemberModal = () => {
    return (
      <Modal show={showAddMember} onHide={handleCloseAddMember}>
        <Modal.Header closeButton>
          <Modal.Title>邀请会员</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="invite-email" style={{ marginTop: "5px" }}>
              <Form.Label style={{ fontWeight: "bold" }}>电子邮箱 *</Form.Label>
              <Form.Control
                type="email"
                rows={1}
                ref={inviteNewMemberEmailRef}
                required
              />
            </Form.Group>
            <Form.Group id="invite-name" style={{ marginTop: "15px" }}>
              <Form.Label style={{ fontWeight: "bold" }}>
                姓名(名字) *
              </Form.Label>
              <Form.Control
                type="text"
                rows={1}
                ref={inviteNewMemberFirstNameRef}
                required
              />
            </Form.Group>
            <Form.Group id="invite-name" style={{ marginTop: "15px" }}>
              <Form.Label style={{ fontWeight: "bold" }}>姓名(姓) *</Form.Label>
              <Form.Control
                type="text"
                rows={1}
                ref={inviteNewMemberLastNameRef}
                required
              />
            </Form.Group>
            <Form.Group id="invite-instruction" style={{ marginTop: "15px" }}>
              <Form.Label style={{ fontWeight: "bold", fontSize: "15px" }}>
                * 备注：点击邀请您将会添加用户并发送一封电子邮件给该用户
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddMember}>
            关闭
          </Button>
          <Button
            variant="success"
            onClick={(e) => {
              const emailAddr = inviteNewMemberEmailRef.current.value;
              const fNameStr = inviteNewMemberFirstNameRef.current.value;
              const lNameStr = inviteNewMemberLastNameRef.current.value;
              if (
                !(
                  emailAddr != null &&
                  emailAddr !== "" &&
                  fNameStr != null &&
                  lNameStr != null
                )
              ) {
                return;
              }
              fires
                .collection("groups")
                .doc(gData.id)
                .collection("members")
                .doc(emailAddr)
                .set({
                  email: emailAddr,
                  userName: fNameStr + " " + lNameStr,
                  name: fNameStr + " " + lNameStr,
                  firstName: fNameStr,
                  lastName: lNameStr,
                  invitedBy: "admin",
                  fromUID: "",
                  status: "member",
                  member: true,
                  timestamp: timestampNow,
                });
              sendNewMemberEmail(
                emailAddr,
                gData.name,
                gData.id,
                "",
                gData.bannerURL,
                `${fNameStr} ${lNameStr}`
              );
              handleCloseAddMember();
            }}
          >
            邀请
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const createMemberRequestModel = () => {
    return (
      <Modal show={showMemberRequest} onHide={handleCloseMemberRequest}>
        <Modal.Header closeButton>
          <Modal.Title>会员入会请求({orgMemberRequests.length})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {orgMemberRequests.map((reqItem) => {
              let reqId = reqItem.id;
              var firstName = "";
              var lastName = "";
              var message = "";
              var reqEmail = "";
              if (reqItem.firstName != null && reqItem.lastName != null) {
                firstName = reqItem.firstName;
                lastName = reqItem.lastName;
              }
              if (reqItem.message != null) {
                message = reqItem.message;
              }
              if (reqItem.email != null) {
                reqEmail = reqItem.email;
              }
              return (
                <ListGroup.Item>
                  <Card.Title>{reqEmail}</Card.Title>
                  <Card.Text>
                    姓名: {firstName} {lastName}
                  </Card.Text>
                  <Card.Text>信息: {reqItem.message}</Card.Text>
                  <Card.Link
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "green",
                    }}
                    onClick={() => {
                      if (reqId != null && reqId !== "") {
                        const newMemberId = uuid().toUpperCase();
                        fires
                          .collection("groups")
                          .doc(gData.id)
                          .collection("members")
                          .doc(reqEmail)
                          .set({
                            email: reqEmail,
                            userName: `${firstName} ${lastName}`,
                            name: `${firstName} ${lastName}`,
                            firstName,
                            lastName,
                            fromUID: "",
                            status: "member",
                            memberId: newMemberId,
                            member: true,
                            timestamp: timestampNow,
                          })
                          .then(() => {
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .collection("public-members")
                              .doc(newMemberId)
                              .set({
                                name: firstName + " " + lastName,
                                status: "member",
                                memberId: newMemberId,
                                member: true,
                                profileURL: "",
                                timestamp: timestampNow,
                              });
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .collection("requests")
                              .doc(reqId)
                              .delete();
                            window.location.reload();
                          });
                      }
                    }}
                  >
                    同意请求
                  </Card.Link>
                  <br />
                  <Card.Link
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "red",
                    }}
                    onClick={() => {
                      if (reqId != null && reqId != "") {
                        fires
                          .collection("groups")
                          .doc(gData.id)
                          .collection("requests")
                          .doc(reqId)
                          .delete();
                        window.location.reload();
                      }
                    }}
                  >
                    拒绝请求
                  </Card.Link>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMemberRequest}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const createNewPriceListModal = () => {
    return (
      <Modal show={showAddNewPrice} onHide={handleCloseAddNewPrice}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              const priceDesc = e.target[0].value;
              const priceItem = e.target[1].value;
              if (priceItem !== "") {
                const orgName = gData.name;
                const orgEmail = auth.currentUser.email;
                const unitAmount = Number(priceItem) * 100;
                if (unitAmount > 99){
                  stripeAPICreatePrice(
                    gData.id,
                    unitAmount,
                    gData.productId,
                    "cad"
                  );
                  handleCloseAddNewPrice();
                }
                else{
                  alert('should be more than 0.99')
                }
              }
              else{}
              
            }}
          >
            <Form.Group style={{ marginBottom: "25px" }}>
              <FloatingLabel
                controlId="priceDescription"
                label="Price Description"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Form.Control
                  type="text"
                  placeholder="Please type to describe the price"
                  rows={1}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group style={{ marginBottom: "25px" }}>
              <FloatingLabel
                controlId="priceDescription"
                label="Price"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Form.Control type="text" placeholder="$CAD/USD" rows={1} />
              </FloatingLabel>
            </Form.Group>

            <Button variant="dark" className="w-100" type="submit">
              Submit Price
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const sendNewEventReportModal = () => {
    return (
      <Modal show={showCreateReport} onHide={handleCloseCreateReport}>
        <Modal.Header closeButton>
          <Modal.Title>发送活动报告</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              const emailToAddress = e.target[0].value;
              if (
                selectedEventId !== "" &&
                emailToAddress != null &&
                emailToAddress !== ""
              ) {
                handleCloseCreateReport();
              }
            }}
          >
            <Form.Group style={{ marginBottom: "25px" }}>
              <FloatingLabel
                controlId="priceDescription"
                label="发送的邮箱地址"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Form.Control
                  type="text"
                  placeholder="请输入您想发送的邮箱"
                  defaultValue={auth.currentUser.email}
                  rows={1}
                />
              </FloatingLabel>
            </Form.Group>

            <Button variant="dark" className="w-100" type="submit">
              发送
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const createNewEventModal = () => {
    return (
      <Modal show={showAddEvent} onHide={handleCloseAddEvent}>
        <Modal.Header closeButton>
          <Modal.Title>创建活动</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {eventCreateMessage && (
            <Alert variant="success">{eventCreateMessage}</Alert>
          )}
          <Stepper activeStep={newEventStep}>
            <Step label="活动基本信息" />
            <Step label="活动时间" />
            <Step label="活动地点" />
            <Step label="活动费用" />
            <Step label="其他选项" />
            <Step label="确认" />
          </Stepper>
          <Form>
            <Form.Group
              hidden={newEventStep != 0}
              style={{ marginBottom: "25px" }}
            >
              <Card.Img
                src={eventContent.photoURL}
                style={{ width: "100%", maxHeight: "650px" }}
              />
              <FloatingLabel
                controlId="floatingInput"
                label="活动背景图片"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Form.Control
                  type="url"
                  rows={1}
                  onChange={(e) => {
                    const eventImg = e.target.value;
                    if (eventImg === "") return;
                    eventContent.photoURL = eventImg;
                    setEventContent(eventContent);
                    setEventCreateMessage("图片展示完毕");
                  }}
                  placeholder="在此输入背景图片链接"
                  required
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group hidden={newEventStep !== 0}>
              <FloatingLabel
                controlId="floatingInput"
                label="活动名称"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  rows={1}
                  onChange={(e) => {
                    const eventT = e.target.value;
                    if (eventT === "") return;
                    eventContent.title = eventT;
                    setEventContent(eventContent);
                  }}
                  placeholder="xxx-xxx"
                  defaultValue={eventContent.title}
                  required
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group hidden={newEventStep !== 0}>
              <FloatingLabel
                controlId="floatingInput"
                label="活动介绍"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  rows={5}
                  onChange={(e) => {
                    const eventDes = e.target.value;
                    if (eventDes === "") return;
                    eventContent.description = eventDes;
                    let newDescs = eventDes.split("\n");
                    newDescs = newDescs.filter(Boolean);
                    eventContent.descriptions = {
                      zh: newDescs,
                      en: newDescs,
                    };
                    setEventContent(eventContent);
                  }}
                  placeholder="xxx-xxx"
                  defaultValue={eventContent.description}
                  required
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group hidden={newEventStep !== 1}>
              <Form.Label>开始日期和时间</Form.Label>
              <br />
              <Row md={2}>
                <Col>
                  <Form.Control
                    type="date"
                    onChange={(e) => {
                      const dateInfo = e.target.value;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    onChange={(e) => {
                      const timeInfo = e.target.value;
                    }}
                  />
                </Col>
              </Row>

              {/* <DateTimePicker 
       value={eventContent.startDate.toDate()} 
       minDate={new Date()}
       onChange={(date)=>{
        const newDate = date;
        var eventCC = eventContent
        eventCC.startDate = firebase.firestore.Timestamp.fromDate(newDate)
        setEventContent(eventCC)
        setEventCreateMessage("开始日期已设置")
      }}/> */}
            </Form.Group>

            <Form.Group
              hidden={newEventStep !== 1}
              style={{ marginTop: "15px" }}
            >
              <Form.Label>结束日期和时间</Form.Label>
              <br />
              <Row md={2}>
                <Col>
                  <Form.Control
                    type="date"
                    onChange={(e) => {
                      const dateInfo = e.target.value;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    onChange={(e) => {
                      const timeInfo = e.target.value;
                    }}
                  />
                </Col>
              </Row>
              {/* <DateTimePicker 
       value={eventContent.endDate.toDate()} 
       minDate={eventContent.startDate.toDate()}
       onChange={(date)=>{
        const newDate = date;
        var eventCC = eventContent
        eventCC.endDate = firebase.firestore.Timestamp.fromDate(newDate)
        setEventContent(eventCC)
        setEventCreateMessage("结束日期已设置")
      }}/> */}
            </Form.Group>

            <Form.Group hidden={newEventStep !== 2}>
              <FloatingLabel
                controlId="floatingInput"
                label="活动地点名称"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    const addressName = e.target.value;
                    eventContent.address.name = addressName;
                    setEventContent(eventContent);
                  }}
                  rows={1}
                  placeholder=""
                  defaultValue={eventContent.address.name}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group hidden={newEventStep !== 2}>
              <FloatingLabel
                controlId="floatingInput"
                label="Unit"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    const addressUnit = e.target.value;
                    var eventSet = eventContent;
                    eventSet.address.unit = addressUnit;
                    setEventContent(eventSet);
                  }}
                  rows={1}
                  placeholder=""
                  defaultValue={eventContent.address.unit}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group hidden={newEventStep !== 2}>
              <FloatingLabel
                controlId="floatingInput"
                label="活动地址"
                className="mb-3"
              >
                <Form.Control
                  type="address"
                  onChange={(e) => {
                    const addressTarget = e.target.value;
                    var eventSet = eventContent;
                    eventSet.address.fullAddress = addressTarget;
                    setEventContent(eventSet);
                  }}
                  rows={1}
                  placeholder="address"
                  defaultValue={eventContent.address.fullAddress}
                  required
                />
              </FloatingLabel>
            </Form.Group>

            <Button
              variant="dark"
              style={{ width: "100%" }}
              hidden={newEventStep !== 2}
              onClick={() => {
                Geocode.fromAddress(
                  eventContent.address.fullAddress,
                  process.env.REACT_APP_GOOGLE_API
                ).then(
                  (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    let address =
                      (eventContent.address.unit === ""
                        ? ""
                        : `Unit ${eventContent.address.unit}, `) +
                      response.results[0].formatted_address;
                    var city = "";
                    var state = "";
                    var country = "";
                    var zipCode = "";
                    var street_address = "";
                    for (
                      let i = 0;
                      i < response.results[0].address_components.length;
                      i++
                    ) {
                      for (
                        let j = 0;
                        j <
                        response.results[0].address_components[i].types.length;
                        j++
                      ) {
                        switch (
                          response.results[0].address_components[i].types[j]
                        ) {
                          case "locality":
                            city =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "administrative_area_level_1":
                            state =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "country":
                            country =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "postal_code":
                            zipCode =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "street_address":
                            street_address =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          default:
                            break;
                        }
                      }
                    }

                    eventContent.address = {
                      fullAddress: address,
                      name: eventContent.address.name,
                      city,
                      country,
                      state: state,
                      street: street_address,
                      zipCode: zipCode,
                      lat: lat + "",
                      lng: lng + "",
                      unit: eventContent.address.unit,
                      online: false,
                    };
                    alert("完整地址: " + address);
                    setEventContent(eventContent);
                  },
                  (error) => {
                    console.error(error);
                    alert(
                      "地址错误！无法识别您输入的地址，请检查是否为正确地址"
                    );
                  }
                );
              }}
            >
              确认地址
            </Button>

            <Form.Group hidden={newEventStep != 3}>
              {/* cost */}
              <Form.Label>活动费用</Form.Label>
            </Form.Group>

            <Form.Group hidden={newEventStep != 4}>
              {/* max number */}
              <Form.Label>其他选项</Form.Label>
            </Form.Group>

            <Form.Group hidden={newEventStep != 5}>
              <Card.Img src={eventContent.photoURL} />
              <br />
              <br />
              <Form.Label style={{ fontSize: "15px", fontWeight: "normal" }}>
                活动名称
              </Form.Label>
              <br />
              <Form.Label style={{ fontSize: "17px", fontWeight: "bold" }}>
                {eventContent.title}
              </Form.Label>
              <br />
              <br />
              <Form.Label style={{ fontSize: "15px", fontWeight: "normal" }}>
                活动介绍
              </Form.Label>
              <br />
              <Form.Label style={{ fontSize: "17px", fontWeight: "bold" }}>
                {eventContent.description}
              </Form.Label>
              <br />
              <br />
              <Form.Label style={{ fontSize: "15px", fontWeight: "normal" }}>
                活动开始时间
              </Form.Label>
              <br />
              <Form.Label style={{ fontSize: "17px", fontWeight: "bold" }}>
                {eventContent.startDate.toDate().toLocaleString("zh")}
              </Form.Label>
              <br />
              <br />
              <Form.Label style={{ fontSize: "15px", fontWeight: "normal" }}>
                活动结束时间
              </Form.Label>
              <br />
              <Form.Label style={{ fontSize: "17px", fontWeight: "bold" }}>
                {eventContent.endDate.toDate().toLocaleString("zh")}
              </Form.Label>
              <br />
              <br />
              <Form.Label style={{ fontSize: "15px", fontWeight: "normal" }}>
                活动地点
              </Form.Label>
              <br />
              <Form.Label style={{ fontSize: "17px", fontWeight: "bold" }}>
                {eventContent.address.fullAddress}
              </Form.Label>
              <br />
              <br />
            </Form.Group>

            <Button
              variant="success"
              style={{ width: "100%" }}
              hidden={newEventStep != 5}
              onClick={(e) => {
                const fNumber = Math.floor(
                  Math.random() * (999 - 100 + 1) + 100
                );
                const sNumber = Math.floor(
                  Math.random() * (999 - 100 + 1) + 100
                );
                const tNumber = Math.floor(
                  Math.random() * (999 - 100 + 1) + 100
                );
                const idString =
                  fNumber.toString() + sNumber.toString() + tNumber.toString();
                eventContent.pin = idString;
                if (userDetails.orgId != null) {
                  eventContent.orgId = userDetails.orgId;
                  fires
                    .collection("events")
                    .add(eventContent)
                    .then((docRef) => {
                      const eventNewId = docRef.id;
                      fires
                        .collection("events")
                        .doc(eventNewId)
                        .update({
                          id: eventNewId,
                          timestamp: timestampNow,
                          status: "live",
                          priceType: "FREE",
                        })
                        .then(() => {
                          window.location.href = "/";
                        });
                    });
                } else {
                  window.alert("无法创建活动");
                }
              }}
            >
              创建活动
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer className="align-items-start justify-content-between">
          <div>
            <Button
              variant="dark"
              onClick={(e) => {
                window.location.href = "/create-live-event";
              }}
            >
              进入活动创建页面
            </Button>
          </div>

          <div>
            <Button
              variant="dark"
              style={{}}
              hidden={newEventStep == 0}
              onClick={() => {
                setEventCreateMessage("");
                if (newEventStep == 5) {
                  setNewEventStep(4);
                } else if (newEventStep == 4) {
                  setNewEventStep(3);
                } else if (newEventStep == 3) {
                  setNewEventStep(2);
                } else if (newEventStep == 2) {
                  setNewEventStep(1);
                } else if (newEventStep == 1) {
                  setNewEventStep(0);
                }
              }}
            >
              上一步
            </Button>
            <Button
              variant="dark"
              style={{ marginLeft: "5px" }}
              hidden={newEventStep == 5}
              onClick={() => {
                setEventCreateMessage("");
                if (newEventStep == 0) {
                  setNewEventStep(1);
                } else if (newEventStep == 1) {
                  setNewEventStep(2);
                } else if (newEventStep == 2) {
                  setNewEventStep(3);
                } else if (newEventStep == 3) {
                  setNewEventStep(4);
                } else if (newEventStep == 4) {
                  setNewEventStep(5);
                }
              }}
            >
              下一步
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const showSidePaneAdminSection = () => {
    if (adminStatus) {
      return (
        <div>
          <ListGroup.Item className="border-0" style={{marginBottom: '15px'}}>
            <Card.Text
              style={{ color: "black", fontWeight: "bold", fontSize: "20px" }}
              hidden={!adminStatus}
            >
              Admin Permissions
            </Card.Text>
            <Card.Text
              style={{ color: "black", fontWeight: "normal", fontSize: "15px" }}
              hidden={!adminStatus}
            >
              {adminData.name}
            </Card.Text>
            <Card.Text
              style={{ color: "darkGray", fontWeight: "normal", fontSize: "15px" }}
              hidden={!adminStatus}
            >
              Your roles: {adminData.roles.join(', ')}
            </Card.Text>
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-user"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (adminTotalUserData.length == 0) {
                if (adminData.roles.includes('management')){
                  //
                  adminLoadAllUsers(adminStatus);
                }
                else{
                  window.location.href = "/"
                }
              }
              else{
                window.location.href = "/"
              }
            }}
          >
            View All Users
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-payments"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (adminAllPayoutList.length == 0) {
                if (adminData.roles.includes('management')){
                  //
                  adminLoadAllPayouts(adminStatus);
                }
                else{
                  window.location.href = "/"
                }
              }
              else{
                window.location.href = "/"
              }
              
            }}
          >
            View Payouts
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-email-templates"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (adminTotalEmailTemplates.length == 0) {
                adminLoadEmailTemplate(adminStatus);
              }
            }}
          >
            View Marketing Email Templates
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-marketing-email-address"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (adminTotalMarketingEmailAddress.length == 0) {
                adminLoadAllMarketingEmailAddress(adminStatus);
              }
            }}
          >
            View Marketing Emails
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-emails"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (adminTotalEmailData.length == 0) {
                adminLoadAllEmails(adminStatus);
              }
            }}
          >
            View Emails
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-sponsors"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (sponsors.length == 0) {
                loadSponsorRequests(adminStatus)
              }
            }}
          >
            View Sponsors Requests
          </ListGroup.Item>
          <ListGroup.Item
            action
            href="#admin-all-orders"
            className="d-flex justify-content-between align-items-start border-0"
            onClick={() => {
              if (sponsors.length == 0) {
                loadOrderDetails(adminStatus)
              }
            }}
          >
            View Order Details
          </ListGroup.Item>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const showAdminAllUserPane = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-user">
        <div className="d-flex justify-content-between align-items-start">
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "45px",
            }}
          >
            All Users({adminTotalUserData.length})
          </Card.Text>
        </div>
        <Row md={2}>
          <Col style={{marginTop: '15px'}}>
          <Card>
            <Card.Body>
              <div>Today</div>
              <div className="fw-bold">{adminUserData.today}</div>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{marginTop: '15px'}}>
          <Card>
            <Card.Body>
              <div>This Week</div>
              <div className="fw-bold">{adminUserData.week}</div>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{marginTop: '15px'}}>
          <Card>
            <Card.Body>
              <div>Total Unique Emails</div>
              <div className="fw-bold">{adminUserData.uniqueEmail}</div>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{marginTop: '15px'}}>
          <Card>
            <Card.Body>
              <div>Organizer Emails</div>
              <div className="fw-bold">{''}</div>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{marginTop: '15px'}}>
          <Card>
            <Card.Body>
              <div>Sponsors Emails</div>
              <div className="fw-bold">{''}</div>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{marginTop: '15px'}}>
          <Card>
            <Card.Body>
              <div>Unique Emails</div>
            </Card.Body>
          </Card>
        </Col>
        </Row>
        <ListGroup>
          <ListGroup.Item className="" style={{ backgroundColor: "#EEE", marginTop: '25px' }}>
            <Row md={4}>
              <Col>
                <Card.Text>Name</Card.Text>
              </Col>
              <Col>
                <Card.Text>Email</Card.Text>
              </Col>
              <Col>
                <Card.Text>Current Event</Card.Text>
              </Col>
              <Col>
                <Card.Text>Register Date</Card.Text>
              </Col>
            </Row>
          </ListGroup.Item>
          {adminTotalUserData.map((userItm) => {
            let userN = "";
            let userEmail = "";
            let currentEvent = "";
            let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
            if (userItm.userName != null) {
              userN = userItm.userName;
            }
            if (userItm.email != null) {
              userEmail = userItm.email;
            }
            if (userItm.timestamp != null) {
              timestamp = userItm.timestamp;
            }
            if (userItm.currentEvent != null) {
              currentEvent = userItm.currentEvent;
            }

            return (
              <ListGroup.Item className="" style={{}}>
                <Row md={4}>
                  <Col>
                    <Card.Text>{userN}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>{userEmail}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Link
                      href={"https://tickets.evtgo.com/" + currentEvent}
                    >
                      {currentEvent}
                    </Card.Link>
                  </Col>
                  <Col>
                    <Card.Text>
                      {timestamp.toDate().toLocaleString("en")}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginTop: "25px" }}
        >
          <Button
            variant="outline-dark"
            onClick={() => {
              setAdminLoadUserNumCount(adminLoadUserNumCount + 100);
              adminLoadAllUsers(adminStatus);
            }}
          >
            Load More
          </Button>
        </div>
      </Tab.Pane>
    ) : (
      <></>
    );
  };

  const showAdminAllPaymentPane = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-payments">
        <div className="d-flex justify-content-between align-items-start">
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "15px",
            }}
          >
            全部还款数据({adminAllPayoutList.length})
          </Card.Text>
          <div>
            <Button variant="success">发起还款</Button>
            <CSVLink
              filename={"bank_payout.csv"}
              data={adminPayoutCSV}
              className="btn btn-dark"
              style={{ color: "white", marginLeft: "15px" }}
            >
              导出
            </CSVLink>
          </div>
        </div>
        <div style={{ marginTop: "15px", marginBottom: "45px" }}>
          <Card.Text>
            总盈余: $
            {Math.round((adminAllPayoutData.balance / 100) * 100) / 100}{" "}
          </Card.Text>
          <Card.Text>
            全部进账额: $
            {Math.round((adminAllPayoutData.payOutTotal / 100) * 100) / 100}{" "}
          </Card.Text>
          <Card.Text>
            全部出账额: $
            {Math.round((adminAllPayoutData.payInTotal / 100) * 100) / 100}{" "}
          </Card.Text>
        </div>
        <ListGroup>
          <ListGroup.Item style={{ backgroundColor: "#EEE" }}>
            <Row md={4}>
              <Col>
                <div className="fw-bold">出入账</div>
              </Col>
              <Col>
                <div className="fw-bold">总额</div>
              </Col>
              <Col>
                <div className="fw-bold">预计时间</div>
              </Col>
              <Col>
                <div className="fw-bold">状态</div>
              </Col>
            </Row>
          </ListGroup.Item>
          {adminAllPayoutList.map((payoutItm) => {
            let pData = {
              id: "",
              amount: 0,
              arrival_date: 0,
              balance_transaction: "",
              created_date: 0,
              currency: "",
              description: "",
              method: "",
              source_type: "",
              status: "",
              type: "",
              destination: "",
            };
            pData = payoutItm;
            let incomeStatus = false;
            if (pData.amount < 0) {
              incomeStatus = false;
            } else {
              incomeStatus = true;
            }
            const arrivalDate = new Date(pData.arrival_date * 1000);

            return (
              <ListGroup.Item>
                <Row md={4}>
                  <Col>
                    <div className="fw-bold">
                      {incomeStatus ? "进账" : "出账"}
                    </div>
                  </Col>
                  <Col>
                    <div className="fw-bold">
                      ${Math.round((pData.amount / 100) * 100) / 100}{" "}
                      {pData.currency.toUpperCase()}
                    </div>
                  </Col>
                  <Col>
                    <div className="fw-normal">
                      {arrivalDate.toLocaleString("en")}
                    </div>
                  </Col>
                  <Col>
                    <div className="fw-normal">{pData.status}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Tab.Pane>
    ) : (
      <></>
    );
  };

  const [rawHTML, setRawHTML] = useState("");
  const showAdminAllEmailTemplates = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-email-templates">
        <ListGroup.Item className="d-flex justify-content-between align-items-start">
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "45px",
            }}
          >
            All Marketing Templates({adminTotalEmailTemplates.length})
          </Card.Text>
        </ListGroup.Item>
        <ListGroup>
          <ListGroup.Item className="" style={{ backgroundColor: "#EEE" }}>
            <Row md={4}>
              <Col>
                <Card.Text>Marketing ID</Card.Text>
              </Col>
              <Col>
                <Card.Text>Subject</Card.Text>
              </Col>
              <Col>
                <Card.Text>Status</Card.Text>
              </Col>
              <Col>
                <Card.Text>Date</Card.Text>
              </Col>
            </Row>
          </ListGroup.Item>
          {adminTotalEmailTemplates.map((templateItm) => {
            return (
              <ListGroup.Item className="">
                <Row md={4}>
                  <Col>
                    <Card.Text>{templateItm.id}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Link
                      onClick={() => {
                        if (templateItm.html != null) {
                          setRawHTML(templateItm.html);
                          window.alert("Loaded HTML");
                        }
                      }}
                    >
                      {templateItm.subject}
                    </Card.Link>
                  </Col>
                  <Col>
                    <Card.Text>{templateItm.status}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {templateItm.timestamp.toDate().toLocaleString("en")}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        <div style={{ marginTop: "25px" }}>
          <div>{rawHTML}</div>
        </div>
      </Tab.Pane>
    ) : (
      <></>
    );
  };

  const showAdminAllMarketingEmailAddress = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-marketing-email-address">
        <div className="d-flex justify-content-between align-items-start"
        style={{marginBottom: '25px', marginTop: '25px'}}>
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "45px",
            }}
          >
            All Emails ({adminTotalMarketingEmailAddress.length})
          </Card.Text>
        </div>
        <ListGroup>
          <ListGroup.Item>
            <Row md={5}>
              <Col>
                <Card.Text>
                  <b>Email</b>
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  <b>Marketing ID</b>
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  <b>EMAIL ID</b>
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  <b>Sent Date</b>
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  <b>Join Date</b>
                </Card.Text>
              </Col>
            </Row>
          </ListGroup.Item>
          {adminTotalMarketingEmailAddress.map((emailData) => {
            let sentTime = firebase.firestore.Timestamp.fromDate(new Date());
            let joinTime = firebase.firestore.Timestamp.fromDate(new Date());
            let emailId = "";
            let newsletterId = "";
            let emailAddress = "";
            if (emailData.newsletterId != null) {
              newsletterId = emailData.newsletterId;
            }
            if (emailData.emailId != null) {
              emailId = emailData.emailId;
            }
            if (emailData.email != null) {
              emailAddress = emailData.email;
            }
            if (emailData.timestamp != null) {
              joinTime = emailData.timestamp;
            }
            if (emailData.sentTime != null) {
              sentTime = emailData.sentTime;
            }

            return (
              <ListGroup.Item className="">
                <Row md={5}>
                  <Col>
                    <Card.Text>{emailAddress}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>{newsletterId}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>{emailId}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {sentTime.toDate().toLocaleDateString("en")}
                    </Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {joinTime.toDate().toLocaleDateString("en")}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Tab.Pane>
    ) : (
      <></>
    );
  };

  const showAdminAllSentEmails = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-emails">
        <div className="d-flex justify-content-between align-items-start"
        style={{marginBottom: '25px', marginTop: '25px'}}>
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "45px",
            }}
          >
            All Emails ({adminTotalEmailData.length})
          </Card.Text>
        </div>
        <ListGroup>
          <ListGroup.Item className="" style={{ backgroundColor: "#EEE" }}>
            <Row md={4}>
              <Col>
                <Card.Text>Email</Card.Text>
              </Col>
              <Col>
                <Card.Text>Subject</Card.Text>
              </Col>
              <Col>
                <Card.Text>Status</Card.Text>
              </Col>
              <Col>
                <Card.Text>Sent Date</Card.Text>
              </Col>
            </Row>
          </ListGroup.Item>
          {adminTotalEmailData.map((emailItm) => {
            let toEmail = "";
            let orgId = "";
            let deliveryState = "";
            let deliveryTime = timestampNow;
            let emailSubject = "";

            if (emailItm.delivery != null) {
              if (emailItm.delivery.startTime != null) {
                deliveryTime = emailItm.delivery.startTime;
              }
              if (emailItm.delivery.state != null) {
                deliveryState = emailItm.delivery.state;
              }
            }

            if (emailItm.to != null) {
              let deliveryTo = [];
              deliveryTo = emailItm.to;
              toEmail = deliveryTo;
            }
            if (emailItm.message?.subject != null) {
              emailSubject = emailItm.message.subject;
            }
            return (
              <ListGroup.Item className="">
                <Row md={4}>
                  <Col>
                    <Card.Text>{toEmail}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>{emailSubject}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>{deliveryState}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {deliveryTime.toDate().toLocaleString("en")}
                    </Card.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Tab.Pane>
    ) : (
      <></>
    );
  };

  const showAdminOrders = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-orders">
        <div className="d-flex justify-content-between align-items-start" 
        style={{marginBottom: '25px', marginTop: '25px'}}>
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "45px",
            }}
          >
            All Orders ({allOrders.length})
          </Card.Text>
          <div>
          <Button variant="dark" onClick={()=>{
            loadOrderDetails(adminStatus)
          }}>Reload</Button>
        </div>
        </div>
        <Accordion defaultActiveKey="0">
          {
          allOrders.map((orderItm) => {
            const eventId = orderItm.eventId
            const email = orderItm.userEmail
            let cart = []
            let amount = 0
            amount = orderItm.amount
            let amountStr = amount.toLocaleString('en-US',{currency: 'CAD', style: 'currency'})
            let eventTitle = ""
            if (Object.keys(eventReference).includes(eventId)){
              eventTitle = eventReference[eventId]
            }
            else {
              eventTitle = eventId
            }
            let timestamp = firebase.firestore.Timestamp.fromDate(new Date())
            timestamp = orderItm.timestamp
            
            if (orderItm.cartItems != null){
              cart = orderItm.cartItems
            }
            return (
              <Accordion.Item eventKey={orderItm.id}>
                <Accordion.Header>
                  <div className="fw-bold">{eventTitle} [{amountStr}] [{timestamp.toDate().toLocaleDateString('en')}]</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div>Event: <b>{eventTitle}</b></div>
                  <div>Amount: <b>{amountStr}</b></div>
                  <div>Email: <b>{email}</b></div>
                  <div>Orders: </div>
                  {
                    cart.map((cartItm) => {
                      return <div>
                        <div className="fw-bold">{cartItm.priceDescription}</div>
                        <div className="fw-bold">{cartItm.price.toLocaleString('en-US',{currency: 'CAD', style: 'currency'})} X {cartItm.quantity}</div>
                      </div>
                    })
                  }
                  <div>Date: {timestamp.toDate().toLocaleString('en')}</div>
                </Accordion.Body>
              </Accordion.Item>)
          })
        }
        </Accordion>
      </Tab.Pane>
    ) : (<></>)
  }


  const showAdminSponsors = () => {
    return adminStatus ? (
      <Tab.Pane eventKey="#admin-all-sponsors">
        <div className="d-flex justify-content-between align-items-start" 
        style={{marginBottom: '25px', marginTop: '25px'}}>
          <Card.Text
            className="text-center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              marginBottom: "45px",
            }}
          >
            All Sponsor Requests ({sponsors.length})
          </Card.Text>
        </div>
        <Row md={2} style={{marginTop: '5px', marginBottom: '25px'}}>
          <Col>
          <Card>
            <Card.Body>
              <Card.Title>Total Page Views</Card.Title>
              <Card.Text><b>{pageViews.length}</b></Card.Text>
            </Card.Body>
          </Card>
          </Col>

          <Col>
          <Card>
            <Card.Body>
              <Card.Title>Today's Page Views</Card.Title>
              <Card.Text><b>{todayPageViews.length}</b></Card.Text>
            </Card.Body>
          </Card>
          </Col>

        </Row>
        
        <Accordion defaultActiveKey="0">
          {sponsors.map((sponsorItm) => {
            const status = sponsorItm.status
            const email = sponsorItm.email
            const langId = sponsorItm.lang
            const sponsorDetails = sponsorItm.sponsor
            let userUID = ""
            let sponsorId = ""
            if (sponsorItm.id != null){
              sponsorId = sponsorItm.id
            }
            if (sponsorItm.uid != null){
              userUID = sponsorItm.uid
            }
            let questions = []
            if (sponsorItm.questions != null){
              questions = sponsorItm.questions
            }
            var questionst = []
            questions.forEach((quesItm) => {
              const qTitle = quesItm.title
              const qAnswers = quesItm.answers
              questionst.push({
                title: qTitle,
                answers: qAnswers,
              })
            })
            return (
              <Accordion.Item eventKey={sponsorItm.id}>
                <Accordion.Header>
                  <div className="fw-bold">[{status}] {sponsorDetails.companyName} ({sponsorItm.timestamp.toDate().toLocaleDateString('zh')})</div>
                </Accordion.Header>
                <Accordion.Body>
                <div className="fw-bold">{sponsorDetails.companyName}</div>
                <div>申请人：{sponsorDetails.name}</div>    
                <div>邮箱：{email}</div>
                <div>电话：{sponsorDetails.phone}</div>
                <div>语言：{langId}</div>
                <br/>用户ID：{userUID}<div>
                  {
                    questionst.map((qItm) => {
                      return <div>
                        <div>{qItm.title}</div>
                        <div className="fw-bold">{qItm.answers.join(', ')}</div>
                      </div>
                    })
                  }
                </div>
                <br/><div>申请状态 {status}</div>
                <div>时间 {sponsorItm.timestamp.toDate().toLocaleString('en')}</div>
                <br/>
                <div>
                  <Button variant="outline-success" onClick={()=>{

                  }}>{'同意'}</Button>
                  <Button style={{marginLeft: '5px'}} variant="outline-danger" onClick={()=>{
                    if (sponsorId != ""){
                      let confirmR = window.confirm("您确定要删除该项目吗？")
                      if (confirmR){
                        fires.collection("sponsors").doc(sponsorId).update({status: 'deleted', statusTime: timestampNow}).then((s)=>{
                          loadSponsorRequests(adminStatus)
                        })
                      }
                    }

                  }}>{'删除'}</Button>
                </div>
              </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Tab.Pane>
    ) : (
      <></>
    );
  };

  const [adminAllPaymentDataPerEventId, setAdminAllPaymentDataPerEventId] =
    useState({
      count: 0,
      data: [],
      eventId: "",
      totalSales: 0,
      totalQuantity: 0,
      timestamp: "",
    });
  const [adminAllPaymentsPerEventId, setAdminAllPaymentsPerEventId] = useState(
    []
  );
  const [adminAllUserInfoPerEventId, setAdminAllUserInfoPerEventId] = useState(
    {}
  );
  async function loadUserInfoPerEventId(eventId) {
    fires
      .collection("events")
      .doc(eventId)
      .collection("members")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshot) => {
        let userInfos = {};
        snapshot.docs.forEach((doc) => {
          const emailId = doc.id;
          const docData = doc.data();
          userInfos[emailId] = docData;
        });
        setAdminAllUserInfoPerEventId(userInfos);
      });
  }
  async function loadExportStripeCSVData(allPaymentList) {
    //load into csv data
    var stripeInfoData = [];

    let total_amount = 0;
    let total_fee = 0;
    let total_discount = 0;

    let stripeTitles = [
      "date",
      "name",
      "email",
      "quantity",
      "payment_id",
      "amount",
      "currency",
      "discount",
      "fee",
    ]
    formTitles.forEach((itm) => {
      stripeTitles.push(itm)
    })
    stripeInfoData.push(stripeTitles);
    

    Object.keys(adminAllUserInfoPerEventId).forEach((emailKey) => {
      const memberDetails = adminAllUserInfoPerEventId[emailKey];
      let dateInfo = "1";
      let payment_id = "";
      let cEmail = "";
      let cName = "";
      let currency = "";
      let quan = "0";
      let amount = 0;
      let fee = 0;
      let discount = 0;
      let forms = [];

      if (memberDetails.timestamp != null) {
        dateInfo = memberDetails.timestamp.toDate().toLocaleString("en");
      }
      if (memberDetails.forms != null) {
        memberDetails.forms.forEach((itm) => {
          let itmV = itm.value;
          forms.push(itmV);
        });
      }

      const paymentData = allPaymentList.filter((paymentItm) => {
        return paymentItm.registerEmail == emailKey;
      });
      if (paymentData.length > 0) {
        //match
        const paymentItm = paymentData[0];

        if (paymentItm.registerName != null) {
          cName = paymentItm.registerName;
        }
        if (paymentItm.registerEmail != null) {
          cEmail = paymentItm.registerEmail;
        }
        if (paymentItm.payment_id != null) {
          payment_id = paymentItm.payment_id;
        }
        if (paymentItm.quantity != null) {
          quan = paymentItm.quantity;
        }
        if (paymentItm.amount != null) {
          amount = paymentItm.amount;
        }
        if (paymentItm.amount_discount != null) {
          discount = paymentItm.amount_discount;
        }
        if (paymentItm.currency != null) {
          currency = paymentItm.currency;
        }
      }

      let quanNum = Number(quan);
      let dollarFee =
        Math.round(((amount * 0.08 + 30 * quanNum) / 100) * 100) / 100;
      let dollarAmount = Math.round((amount / 100) * 100) / 100;
      let dollarDiscount = Math.round((discount / 100) * 100) / 100;

      total_amount += dollarAmount;
      total_discount += dollarDiscount;
      total_fee += dollarFee;

      let stripeDetails = [
        dateInfo,
        memberDetails.name,
        memberDetails.email,
        memberDetails.quantity,
        payment_id,
        dollarAmount,
        currency,
        dollarDiscount,
        dollarFee,
      ]
      if (forms.length > 0){
        forms.forEach((itmV) => {
          stripeDetails.push(itmV)
        })
      }
      stripeInfoData.push(stripeDetails);
    });

    allPaymentList.forEach((paymentItm) => {
      let dateInfo = "1";
      let payment_id = "";
      let cEmail = "";
      let cName = "";
      let currency = "";
      let quan = "0";
      let amount = 0;
      let fee = 0;
      let discount = 0;

      if (paymentItm.registerName != null) {
        cName = paymentItm.registerName;
      }
      if (paymentItm.registerEmail != null) {
        cEmail = paymentItm.registerEmail;
      }
      if (paymentItm.payment_id != null) {
        payment_id = paymentItm.payment_id;
      }
      if (paymentItm.quantity != null) {
        quan = paymentItm.quantity;
      }
      if (paymentItm.amount != null) {
        amount = paymentItm.amount;
      }
      if (paymentItm.amount_discount != null) {
        discount = paymentItm.amount_discount;
      }
      if (paymentItm.currency != null) {
        currency = paymentItm.currency;
      }
      let quanNum = Number(quan);
      let dollarFee =
        Math.round(((amount * 0.08 + 30 * quanNum) / 100) * 100) / 100;
      let dollarAmount = Math.round((amount / 100) * 100) / 100;
      let dollarDiscount = Math.round((discount / 100) * 100) / 100;

      // total_amount += dollarAmount
      // total_discount += dollarDiscount
      // total_fee += dollarFee

      // stripeInfoData.push([
      //   dateInfo,
      //   cName,
      //   cEmail,
      //   quan,
      //   payment_id,
      //   dollarAmount,
      //   currency,
      //   dollarDiscount,
      //   dollarFee,
      // ])
    });
    stripeInfoData.push(["", "", "", "", "", "", "", "", ""]);
    stripeInfoData.push([
      "Fees",
      "8% + count * 0.3C",
      "",
      "",
      "Total: ",
      total_amount,
      "",
      total_discount,
      total_fee,
    ]);

    setCSVData(stripeInfoData);
  }
  const [formTitles, setFormTitles] = useState([])
  const [formData, setFormData] = useState({})

  const [sponsors, setSponsors] = useState([])
  const [pageViews, setPageViews] = useState([])
  const [todayPageViews, setTodayPageViews] = useState([])
  const [allOrders, setAllOrders] = useState([])
  const [allTickets, setAllTickets] = useState([])

  async function loadOrderDetails(adminS){
    if (adminS){
      fires
      .collection('orders')
      .orderBy('timestamp', 'desc')
      .get().then((snapshot) => {
        let orders = []
        snapshot.docs.forEach((orderDoc) => {
          let orderId = orderDoc.id
          let orderData = orderDoc.data()
          orderData.id = orderId
          orders.push(orderData)
        })
        setAllOrders(orders)
      })

      // fires
      // .collection('event_tickets').orderBy('timestamp','desc').get().then((snapshot) => {
      //   let tickets = []
      //   snapshot.docs.forEach((ticketDoc) => {
      //     let ticketD = ticketDoc.data()
      //     tickets.push(ticketD)
      //   })
      //   setAllTickets(tickets)
      // })
    }
  }

  async function loadSponsorRequests(adminS){
    if (adminS){
      fires
      .collection('page_views')
      .orderBy('timestamp', 'desc')
      .get()
      .then((snapshot) => {
        let pageVs = []
        let todayPageVs = []
        snapshot.docs.forEach((doc) => {
          var pageView = doc.data();
          let pageViewId = doc.id;
          let pageViewTime = pageView.timestamp
          if (pageView.currentPath != null){
            if (pageView.currentPath.includes('/become-eventgo-partner')){
              pageVs.push({id: pageViewId, ...pageView})
              if (pageViewTime != null){
                if (isToday(pageViewTime.toDate())){
                  todayPageVs.push({id: pageViewId, ...pageView})
                }
              }
            }
          }
        })
        setPageViews(pageVs)
        setTodayPageViews(todayPageVs)
      })
      fires
      .collection('sponsors')
      .orderBy('timestamp', 'desc')
      .get()
      .then((snapshot)=>{
        let sponsorI = []
        snapshot.docs.forEach((doc) => {
          let sponsorItm = doc.data();
          const sponsorId = doc.id;
          sponsorItm.id = sponsorId
          if (sponsorItm.status != null){
            if (sponsorItm.status == "pending" || sponsorItm.status == "approved"){
              sponsorI.push(sponsorItm)
            }
          }
        })
        setSponsors(sponsorI)
      })
    }
  }
  async function loadStripeInformation(adminS, eventId) {
    if (adminS) {
      auth.currentUser.getIdToken(true).then((idToken) => {
        const dataBody = `event_id=${eventId}&admin_uid=${auth.currentUser.uid}&admin_token=${idToken}`;
        fetch(
          "https://adminapigetallchargesbyeventid-verqhozorq-uc.a.run.app?" +
            dataBody,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then((response) => {
          response.json().then((data) => {
            let allPaymentList = [];
            if (data.data != null) {
              allPaymentList = data.data;
              setAdminAllPaymentsPerEventId(data.data);
            }
            setAdminAllPaymentDataPerEventId(data);
            setStripeLoad(false);
            fires.collection('events').doc(eventId).collection('forms').get().then((snapDoc) => {
              let formS = []
              let formD = {}
              snapDoc.docs.forEach((doc) => {
                const formData = doc.data();
                const formId = formData.id
                const formTitle = formData.title
                formS.push(formTitle)
                formD[formId] = formData
              })
              setFormTitles(formS)
              setFormData(formD)

              loadExportStripeCSVData(allPaymentList);
            })
            
          });
        });
      });
    } else {
      window.alert("您没有操作权限");
    }
  }

  function loadAllGroups(adminS) {
    if (adminS) {
      fires
        .collection("groups")
        .where("status", "==", "live")
        .orderBy("title", "asc")
        .get()
        .then((groupSnap) => {
          var gIds = [];
          var gAllData = {};
          groupSnap.docs.forEach((doc) => {
            const gId = doc.id;
            const gData = doc.data();
            gIds.push(gId);
            gAllData[gId] = gData;
          });
          setGroupIds(gIds);
          setGroupAllData(gAllData);
        });
    }
  }

  const [eventReference, setEventReference] = useState({})
  function loadAllEvents(adminS) {
    if (adminS) {
      fires
        .collection("events")
        .where(
          "startDate",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date())
        )
        .orderBy("startDate", "asc")
        .get()
        .then((groupSnap) => {
          var orggEvents = [];
          var orgEventRefer = {}
          groupSnap.docs.forEach((doc) => {
            const gId = doc.id;
            const gData = doc.data();
            orggEvents.push({ ...gData, cid: gId });
            if (gData.title != null){
              orgEventRefer[gId] = gData.title
            }
          });
          setOrgEvents(orggEvents);
          setAllEvents(orggEvents);
          setEventReference(orgEventRefer);
        });
    }
  }

  function loadAllPastEvents(adminS) {
    if (adminS) {
      fires
        .collection("events")
        .where(
          "startDate",
          "<",
          firebase.firestore.Timestamp.fromDate(new Date())
        )
        .orderBy("startDate", "desc")
        .get()
        .then((groupSnap) => {
          var orggEvents = [];
          var orgEventRefer = {}
          groupSnap.docs.forEach((doc) => {
            const gId = doc.id;
            const gData = doc.data();
            orggEvents.push({ ...gData, cid: gId });
            if (gData.title != null){
              orgEventRefer[gId] = gData.title
            }
          });
          setOrgEvents(orggEvents);
          setAllEvents(orggEvents);
          setEventReference(orgEventRefer);
        });
    }
  }

  const [adminTotalEmailTemplates, setAdminTotalEmailTemplates] = useState([]);
  async function adminLoadEmailTemplate(adminS) {
    if (adminS) {
      fires
        .collection("event_email_schedule")
        .orderBy("timestamp", "desc")
        .get()
        .then((snapshot) => {
          var campaignData = [];
          snapshot.docs.forEach((doc) => {
            const campId = doc.id;
            let campData = doc.data();
            campData.id = campId;
            campaignData.push(campData);
          });
          setAdminTotalEmailTemplates(campaignData);
        });
    } else {
      window.alert("您没有操作权限");
    }
  }

  const [adminTotalUserData, setAdminTotalUserData] = useState([]);
  const [adminLoadUserNumCount, setAdminLoadUserNumCount] = useState(500);
  const [adminOrganizerEmails, setAdminOrganizerEmails] = useState([])
  const [adminSponsorEmails, setAdminSponsorEmails] = useState([])
  const [adminUserData, setAdminUserData] = useState({
    today: 0,
    week: 0,
    uniqueEmail: 0,
    mostUserEvent: "",
    organizerEmails: [],
    uniqueEmails: [],
  })
  
  async function adminLoadAllUsers(adminS) {
    if (adminS && adminData.roles.includes('management')) {
      fires
        .collection("organizers")
        .get()
        .then((organizerSnap) =>{
          let organizerEmails = []
          organizerSnap.docs.forEach((organizerDoc) => {
            let organizerData = organizerDoc.data()
            if (organizerData.email != null && organizerData.email != ""){
              organizerEmails.push(organizerData.email)
            }
          })
          setAdminOrganizerEmails(organizerEmails)
        })
        fires
        .collection("sponsors")
        .get()
        .then((organizerSnap) =>{
          let organizerEmails = []
          organizerSnap.docs.forEach((organizerDoc) => {
            let organizerData = organizerDoc.data()
            if (organizerData.email != null && organizerData.email != ""){
              organizerEmails.push(organizerData.email)
            }
          })
          setAdminSponsorEmails(organizerEmails)
        })
      fires
        .collection("users")
        .orderBy("timestamp", "desc")
        .get()
        .then((userSnap) => {
          var userDdata = []
          
          var todayCount = 0
          var thisweekCount = 0
          var uniqueEmails = []
          var allUserEvents = {}
          var mostUserEvent = ""
          var organizerEmails = []

          userSnap.docs.forEach((doc) => {
            const gUid = doc.id;
            const gData = doc.data();
            userDdata.push({ ...gData, uid: gUid });

            let userTimestamp = getNow()
            if (gData.timestamp != null){
              userTimestamp = gData.timestamp
            }
            if (isToday(userTimestamp.toDate())){
              todayCount += 1
            }
            if (isThisWeek(userTimestamp.toDate())){
              thisweekCount += 1
            }

            
            
            if (gData.email != null && gData.email != ""){
              if (!uniqueEmails.includes(gData.email)){
                uniqueEmails.push(gData.email)
              }
              
            }
            
            if (gData.currentEvent != null){
              if (gData.currentEvent != ""){
                if (Object.keys(allUserEvents).includes(gData.currentEvent)){
                  allUserEvents[gData.currentEvent] += 1
                }
                else{
                  allUserEvents[gData.currentEvent] = 1
                }
              }
            }

          });
          
          setAdminUserData({
            today: todayCount,
            week: thisweekCount,
            uniqueEmail: uniqueEmails.length,
            uniqueEmails: uniqueEmails,
            mostUserEvent: '-',
            organizerEmails: organizerEmails
          })
          setAdminTotalUserData(userDdata);
        });
    } else {
      window.alert("You don't have permission!");
    }
  }

  const [adminTotalMarketingEmailAddress, setAdminTotalMarketingEmailAddress] =
    useState([]);
  async function adminLoadAllMarketingEmailAddress(adminS) {
    if (adminS) {
      fires
        .collection("email_list")
        .get()
        .then((emailSnap) => {
          var emailData = [];
          emailSnap.docs.forEach((doc) => {
            const emailId = doc.id;
            const gData = doc.data();
            emailData.push({ ...gData, id: emailId });
          });
          setAdminTotalMarketingEmailAddress(emailData);
        });
    } else {
      window.alert("您没有操作权限");
    }
  }

  const [adminTotalEmailData, setAdminTotalEmailData] = useState([]);
  async function adminLoadAllEmails(adminS) {
    if (adminS) {
      fires
        .collection("mail")
        .orderBy("delivery.startTime", "desc")
        .limit(100)
        .get()
        .then((emailSnap) => {
          var emailData = [];
          emailSnap.docs.forEach((doc) => {
            const gUid = doc.id;
            const gData = doc.data();
            emailData.push({ ...gData, uid: gUid });
          });
          setAdminTotalEmailData(emailData);
        });
    } else {
      window.alert("您没有操作权限");
    }
  }

  const [adminAllPayoutData, setAdminAllPayoutData] = useState({});
  const [adminAllPayoutList, setAdminAllPayoutList] = useState([]);
  const [adminPayoutCSV, setAdminPayoutCSV] = useState([]);
  async function adminLoadAllPayouts(adminS) {
    if (adminS && adminData.roles.includes('management')) {
      auth.currentUser.getIdToken(true).then((idToken) => {
        const dataBody = `admin_uid=${auth.currentUser.uid}&admin_token=${idToken}`;
        fetch(
          "https://adminapigetstripepayouts-verqhozorq-uc.a.run.app?" +
            dataBody,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then((response) => {
          response.json().then((data) => {
            let allPayoutList = [];
            let payoutCSV = [];
            payoutCSV.push([
              "type",
              "total",
              "date/time",
              "status",
              "bank_account",
            ]);
            if (data.data != null) {
              allPayoutList = data.data;
              var balance = 0;
              var payIn = 0;
              var payOut = 0;

              allPayoutList.forEach((pItm) => {
                balance += pItm.amount;
                if (pItm.amount > 0) {
                  payOut += Math.abs(pItm.amount);
                } else {
                  payIn += Math.abs(pItm.amount);
                }
                var arrivalDate = new Date(pItm.arrival_date * 1000);

                payoutCSV.push([
                  pItm.amount > 0 ? "入账" : "出账",
                  pItm.amount,
                  arrivalDate.toLocaleString("en"),
                  pItm.status,
                  pItm.destination,
                ]);
              });
              setAdminAllPayoutData({
                ...data,
                balance,
                payInTotal: payIn,
                payOutTotal: payOut,
              });
              setAdminAllPayoutList(allPayoutList);
              setAdminPayoutCSV(payoutCSV);
            }
          });
        });
      });
    } else {
      window.alert("您没有操作权限");
    }
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          setUserDetails({ ...userData });

          var orgId = userData.orgId;
          const uid = id;
          if (orgId == "") {
            routeToLogout();
          }
          if (userData.lang != null) {
            setUserLang(userData.lang);
          } else {
            setUserLang("zh");
          }

          //check if it's super admin
          let isSuperAdmin = false;
          const userUID = auth.currentUser.uid;
          if (userUID !== "") {
            fires
              .collection("admin")
              .doc(userUID)
              .get()
              .then((snapAdmin) => {
                const superAdmin = snapAdmin.exists;
                const adminD = snapAdmin.data();
                if (superAdmin) {
                  setAdminStatus(superAdmin);
                  isSuperAdmin = true;
                  setAdminData({...adminD})
                  //load all groups
                  setSuperAdminInEffect(true);
                  loadAllEvents(isSuperAdmin);
                  loadAllGroups(isSuperAdmin);
                } else {
                  fires
                    .collection("events")
                    .where("orgId", "==", orgId)
                    .orderBy("timestamp", "desc")
                    .get()
                    .then((snapshotB) => {
                      const orgEvents = [];
                      snapshotB.docs.forEach((doc) => {
                        const contentId = doc.id;
                        const contentData = doc.data();
                        orgEvents.push({ ...contentData, cid: contentId });
                      });
                      setOrgEvents(orgEvents);
                    });
                }
              });
          }

          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                const posts = [];
                const postData = snapshotA.data();
                setGData({ ...postData, id: orgId });
                if (postData.live != null && !postData.live) {
                  setOBError("您的网页还未上线，您可以在旁边选择发布网站");
                }
                if (postData.style != null) {
                  setGStyles(postData.style);
                }
                //setup quick event
                const nBannerURL =
                  postData.bannerURL != null && postData.bannerURL !== ""
                    ? postData.bannerURL
                    : "";
                eventContent.user = {
                  userName: postData.name,
                  userOrg: postData.name,
                  imageURL: postData.profileURL,
                  uid: auth.currentUser.uid,
                };
                eventContent.photoURL = nBannerURL;
                setEventContent(eventContent);

                posts.push({ ...postData, id: orgId });
                setPosts(posts);
                if (postData.contacts != null) {
                  setOrgLinks(postData.contacts);
                }
                if (postData.quotes != null) {
                  setOrgQuotes(postData.quotes);
                }
                if (postData.features != null) {
                  setOrgFeatures(postData.features);
                }
                if (postData.productId != null && postData.productId !== "") {
                  fires
                    .collection("service-stripe-products")
                    .doc(postData.productId)
                    .collection("prices")
                    .get()
                    .then((snapshotC) => {
                      const priceList = [];
                      snapshotC.docs.forEach((docC) => {
                        const priceId = docC.id;
                        const priceData = docC.data();
                        let priceCurrency = "cad";
                        const priceAmount = priceData.unit_amount ?? 0;
                        if (priceData.currency != null) {
                          priceCurrency = priceData.currency;
                        }
                        priceList.push({
                          id: priceId,
                          amount: priceAmount,
                          currency: priceCurrency,
                        });
                      });
                      setOrgPriceList(priceList);
                    });
                }

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("private")
                  .doc("plan")
                  .get()
                  .then((snapshotB) => {
                    let orgPlanInfo = {};
                    if (!snapshotB.exists) return;
                    const snapData = snapshotB.data();
                    orgPlanInfo = snapData;
                    setPlanInfo(orgPlanInfo);
                    return;
                  });

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("requests")
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotB) => {
                    var orgRequests = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgRequests.push({ ...contentData, id: contentId });
                    });
                    setOrgMemberRequests(orgRequests);
                  });

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("address")
                  .get()
                  .then((snapshotB) => {
                    const orgAddresses = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgAddresses.push({ ...contentData, id: contentId });
                    });
                    setOrgAddress(orgAddresses);
                  });

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("feedbacks")
                  .get()
                  .then((snapshotB) => {
                    const orgFeedbacks = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgFeedbacks.push({ ...contentData, id: contentId });
                    });
                    setOrgFeedbacks(orgFeedbacks);
                  });
                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("emails")
                  .get()
                  .then((snapshotB) => {
                    const orgEmails = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgEmails.push({ ...contentData, id: contentId });
                    });
                    setOrgEmailLists(orgEmails);
                  });
                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("teams")
                  .get()
                  .then((snapshotB) => {
                    const orgTeams = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgTeams.push({ ...contentData, id: contentId });
                    });
                    setOrgTeam(orgTeams);
                  });
                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("locations")
                  .get()
                  .then((snapshotB) => {
                    const orgLocationss = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgLocationss.push({ ...contentData, id: contentId });
                    });
                    setOrgLocations(orgLocationss);
                  });
                fires
                  .collection("posts")
                  .where("orgId", "==", orgId)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotB) => {
                    const orgPosts = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgPosts.push({ ...contentData, id: contentId });
                    });
                    setOrgPosts(orgPosts);
                  });
                //   fires.collection('services').where('orgId','==',orgId).orderBy('timestamp','desc').get().then((snapshotB) => {
                //     var orgServices = [];
                //     snapshotB.docs.forEach((doc) => {
                //       const contentId = doc.id;
                //       const contentData = doc.data();
                //       orgServices.push({ ...contentData, id: contentId })
                //     })
                //     setOrgServices(orgServices);
                // })
                //   fires.collection('services').where('approved','==',true).get().then((snapshotB) => {
                //     var allServices = [];
                //     snapshotB.docs.forEach((doc) => {
                //       const contentId = doc.id;
                //       const contentData = doc.data();
                //       allServices.push({ ...contentData, id: contentId })
                //     })
                //     setAllServices(allServices);
                // })
                // fires.collection('exps').where('orgId','==',orgId).orderBy('timestamp','desc').get().then((snapshotB) => {
                //     var orgExps = [];
                //     snapshotB.docs.forEach((doc) => {
                //       const contentId = doc.id;
                //       const contentData = doc.data();
                //       orgExps.push({ ...contentData, cid: contentId })
                //     })
                //     setOrgExps(orgExps)
                // })

                //   fires.collection('interactions').where('orgId','==',orgId).orderBy('timestamp','desc').get().then((snapshotB) => {
                //     var orgInteractions = [];
                //     snapshotB.docs.forEach((doc) => {
                //       const contentId = doc.id;
                //       const contentData = doc.data();
                //       orgInteractions.push({ ...contentData, id: contentId })
                //     })
                //     setOrgInteractions(orgInteractions)
                // })

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("followers")
                  .get()
                  .then((snapshotB) => {
                    var orgFollows = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();

                      var profileURL = "";
                      var userName = "";
                      if (contentData.profileURL != null) {
                        profileURL = contentData.profileURL;
                      }
                      if (contentData.userName != null) {
                        userName = contentData.userName;
                      }

                      orgFollows.push({
                        ...contentData,
                        id: contentId,
                        userName: userName,
                        profileURL: profileURL,
                      });
                    });

                    setOrgFans(orgFollows);
                  });
                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("members")
                  .get()
                  .then((snapshotB) => {
                    var orgFollows = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();

                      var profileURL = "";
                      var userName = "";
                      if (contentData.profileURL != null) {
                        profileURL = contentData.profileURL;
                      }
                      if (contentData.userName != null) {
                        userName = contentData.userName;
                      }

                      orgFollows.push({
                        ...contentData,
                        id: contentId,
                        userName: userName,
                        profileURL: profileURL,
                      });
                    });

                    setOrgMembers(orgFollows);
                  });

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("views")
                  .get()
                  .then((snapshotB) => {
                    var allViews = [];
                    var viewTypes = [];

                    var todayWeb = 0;
                    var todayMobile = 0;
                    var todayLink = 0;
                    snapshotB.docs.forEach((doc) => {
                      const viewId = doc.id;
                      const viewData = doc.data();
                      allViews.push({ ...viewData, vid: viewId });
                    });
                    allViews.forEach((vItm) => {
                      const vItmDate = vItm.timestamp.toDate();
                      const vURL = vItm.linkId;
                      if (
                        isToday(vItmDate) &&
                        vItm.platform == "web" &&
                        vItm.type == "page"
                      ) {
                        todayWeb += 1;
                      }
                      if (
                        isToday(vItmDate) &&
                        vItm.platform == "mobile" &&
                        vItm.type == "page"
                      ) {
                        todayMobile += 1;
                      }
                      if (isToday(vItmDate) && vItm.type == "link") {
                        todayLink += 1;
                      }
                    });
                    viewTypes.push(todayWeb);
                    viewTypes.push(todayMobile);
                    viewTypes.push(todayLink);
                    setViewTys(viewTypes);
                  });

                document.title = "EventGo Admin";
                document.description = "Manage your events";
                return;
              }
              routeToLogout();
              window.location.href = "/";
            });
        } else {
          //deleted
          routeToLogout();
          posts.push({
            id: id,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "EventGo Admin";
          document.description = "EventGo Admin Helps You Manage All Events";
        }
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        var error = post.error;
        var coverImgURL = "";
        var profileURL = "";
        var professionalType = "";
        var promoStr = "";
        var orgContacts = [];
        if (post.coverURL == null) {
          coverImgURL =
            "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)";
        } else {
          coverImgURL = "url(" + post.coverURL + ")";
        }
        if (post.promo != null) {
          promoStr = post.promo;
        }
        profileURL = post.profileURL;
        professionalType = post.typeCN;

        if (post.contacts != null) {
          orgContacts = post.contacts;
        }

        var feedbackss = [];
        var youtubes = [];
        var gallerys = [];
        var bottomBar = [];
        var allowFeedback = false;
        var allowYoutube = false;
        var allowGallery = false;
        var postStyle = {};
        //configure styles
        if (post.style != null) {
          postStyle = post.style;
          if (post.style.feedback != null) {
            allowFeedback = post.style.feedback;
            if (post.style.feedback) {
              if (post.style.feedbacks != null) {
                post.style.feedbacks.forEach((feedB) => {
                  feedbackss.push(feedB);
                });
                //setFeedbacks(feedbackss)
              }
            }
          }
          if (post.style.youtube != null) {
            allowYoutube = post.style.youtube;
            if (post.style.youtube) {
              if (post.style.youtubeId != null) {
                youtubes.push(post.style.youtubeId);
                //setYoutube(youtubes)
              }
            }
          }
          if (post.style.gallery != null) {
            allowGallery = post.style.gallery;
            if (post.style.gallery) {
              if (post.style.photos != null) {
                post.style.photos.forEach((photo) => {
                  gallerys.push(photo);
                });
                //setGallery(gallerys)
              }
            }
          }
        }

        var userInfo = {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          wechat: "",
          work: "",
        };
        if (post.info != null) {
          userInfo = post.info;
        }

        if (error == null) {
          return (
            <>
              <Container
                className="d-flex justify-content-center align-items-center"
                style={{ minWidth: "100vw", minHeight: "100vh" }}
              >
                {createNewEventModal()}
                {createNewMemberModal()}
                {createMemberRequestModel()}
                {createAllNewModal()}
                {createNewPriceListModal()}
                {sendNewEventReportModal()}
                {createEventReportModel()}

                <div
                  className="w-100"
                  style={{
                    width: maxW,
                    marginTop: "15px",
                    marginBottom: "50px",
                  }}
                >
                  <Card className="border-0">
                    <Card.Body className="align-items-center justify-content-center">
                      {/* <SuperAdminAlert
                        superAdminInEffect={adminStatus}
                      /> */}
                      <ListGroup.Item className="d-flex justify-content-between align-items-start">
                        <Card.Title
                          className="text-center"
                          style={{
                            fontSize: isMobile ? "25px" : "40px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          Dashboard 
                          {superAdminInEffect
                            ? "(" + userDetails.orgName + ")"
                            : ""}
                        </Card.Title>
                        <div style={{ display: "flex" }}>
                          <DropdownButton
                            className="border-0"
                            title={"Super Admin"}
                            id="dropdown-menu-align-right"
                            variant="outline-dark"
                            hidden={!adminStatus}
                            style={{ border: "0px", marginRight: "10px" }}
                            onSelect={(e) => {
                              if (e == 'admin-and-roles'){
                                window.location.href = "/view-admin-and-roles"
                              }
                            }}
                          >
                            <Dropdown.Item
                              eventKey="admin-and-roles"
                              style={{ color: "black" }}
                            >
                              View Admin and Roles
                            </Dropdown.Item>
                            
                           
                          </DropdownButton>

                          <DropdownButton
                            className="border-0"
                            alignRight
                            title={"Account"}
                            id="dropdown-menu-align-right"
                            variant="outline-dark"
                            style={{ border: "0px" }}
                            onSelect={handleActionSelect}
                          >
                            <Dropdown.Item eventKey="account-modify">
                              {"Modify Password"}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              eventKey="logout"
                              style={{ color: "red" }}
                            >
                              {"Logout"}
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </ListGroup.Item>

                      <Tab.Container
                        id="list-group-tabs-example"
                        defaultActiveKey={
                          window.location.href.includes("#")
                            ? "#" + window.location.href.split("#")[1]
                            : "#events"
                        }
                        style={{ marginTop: "15px" }}
                      >
                        <Row style={{ marginTop: "50px" }}>
                          <Col sm={2}>
                            <ListGroup variant="dark" className="border-0">
                              <ListGroup.Item className="border-0">
                                <Card.Text
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  Complete Info
                                </Card.Text>
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                href="#onboard"
                                className="border-0"
                              >
                                Complete Info
                              </ListGroup.Item>

                              <ListGroup.Item className="border-0">
                                <Card.Text
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  Events
                                </Card.Text>
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                href="#events"
                                className="d-flex justify-content-between align-items-start border-0"
                              >
                                <div>
                                  My Events
                                </div>
                                <Badge bg="success" pill>
                                  {orgEvents.length}
                                </Badge>
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                href="#messages"
                                className="d-flex justify-content-between align-items-start border-0"
                              >
                                <div>
                                  My Messages
                                </div>
                                <Badge bg="success" pill>
                                  {orgFeedbacks.length}
                                </Badge>
                              </ListGroup.Item>

                              <ListGroup.Item className="border-0">
                                <Card.Text
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  Organizational Information
                                </Card.Text>
                              </ListGroup.Item>
                              {/* <ListGroup.Item
                                action
                                href="#showdata"
                                className="border-0"
                              >
                                Page Setup
                              </ListGroup.Item> */}
                              <ListGroup.Item
                                action
                                href="#company"
                                className="border-0"
                              >
                                Organization Information
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                href="#saveddata"
                                className="border-0"
                              >
                                Saved Information
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                href="#members"
                                className="d-flex justify-content-between align-items-start border-0"
                              >
                                <div>My Members</div>
                                <Badge bg="success" pill>
                                  {orgMembers.length}
                                </Badge>
                              </ListGroup.Item>
                              {/* <ListGroup.Item
                                action
                                href="#links"
                                className="d-flex justify-content-between align-items-start border-0"
                              >
                                <div>My Links</div>
                                <Badge bg="success" pill>
                                  {orgLinks.length}
                                </Badge>
                              </ListGroup.Item> */}

                              {/* <ListGroup.Item  className="border-0">
              <Card.Text style={{color: 'black', fontWeight: 'bold', fontSize: '20px'}}>设置和帮助</Card.Text>
            </ListGroup.Item>
            <ListGroup.Item  action href="#about-organizer" className="d-flex justify-content-between align-items-start border-0">
              关于组织者信息
            </ListGroup.Item>
            <ListGroup.Item  action href="https://evtgo.com" className="d-flex justify-content-between align-items-start border-0">
              关于产品
            </ListGroup.Item>
            <ListGroup.Item  action href="https://evtgo.com/help" className="d-flex justify-content-between align-items-start border-0">
              帮助中心
            </ListGroup.Item> */}

                              {showSidePaneAdminSection()}
                            </ListGroup>
                          </Col>
                          <Col sm={10}>
                            <Tab.Content>

                              
                              <Tab.Pane eventKey="#account">
                                <Card.Text
                                  className="text-center"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                    padding: "8px",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "12px",
                                  }}
                                >
                                  Account Information
                                </Card.Text>
                                <ListGroup>
                                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">Email Address</div>
                                    <div className="fw-bold">
                                      {auth.currentUser.email}
                                    </div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">Password</div>
                                    <div className="fw-bold">******</div>
                                  </ListGroup.Item>
                                </ListGroup>
                                <Button
                                  className="w-100 mt3"
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "#000000",
                                    borderRadius: "25px",
                                    borderColor: "#ffffff",
                                    borderWidth: "3px",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    height: "50px",
                                    marginTop: "25px",
                                    marginBottom: "15px",
                                  }}
                                  onClick={() => routeChangeProfile()}
                                >
                                  修改账户信息
                                </Button>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#saveddata">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    Saved Information
                                  </Card.Text>
                                </ListGroup.Item>

                                <Row md={2} style={{ marginTop: "35px" }}>
                                  <Col>
                                    <div className="d-flex justify-content-between align-items-start">
                                      <Card.Text
                                        className="text-center"
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                          borderRadius: "12px",
                                        }}
                                      >
                                        Saved Address ({orgAddress.length})
                                      </Card.Text>
                                    </div>
                                    <ListGroup>
                                      {orgAddress.map((addressItm) => {
                                        var addressOnline = false;
                                        if (addressItm.online != null) {
                                          addressOnline = addressItm.online;
                                        }
                                        return (
                                          <ListGroup.Item className="align-items-start">
                                            <div className="fw-bold">
                                              Address (
                                              {addressOnline
                                                ? "Online"
                                                : "Offline"}
                                              )
                                            </div>
                                            <div
                                              className="fw-normal"
                                              style={{ marginTop: "5px" }}
                                            >
                                              {addressItm.fullAddress}
                                            </div>
                                          </ListGroup.Item>
                                        );
                                      })}
                                    </ListGroup>
                                  </Col>
                                  <Col>
                                    <div className="d-flex justify-content-between align-items-start">
                                      <Card.Text
                                        className="text-center"
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "#000000",
                                          padding: "8px",
                                          backgroundColor: "#ffffff",
                                          borderRadius: "12px",
                                        }}
                                      >
                                        Pricelist ({orgPriceList.length})
                                      </Card.Text>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          setShowAddNewPrice(true);
                                        }}
                                      >
                                        Add New
                                      </Button>
                                    </div>
                                    <ListGroup>
                                      {orgPriceList.map((priceItm) => {
                                        var priceAmount = 0;
                                        var priceCurrency = "";
                                        if (priceItm.amount != null) {
                                          priceAmount =
                                            Math.round(
                                              (priceItm.amount / 100) * 100
                                            ) / 100;
                                        }
                                        if (priceItm.currency != null) {
                                          priceCurrency =
                                            priceItm.currency.toUpperCase();
                                        }

                                        return (
                                          <ListGroup.Item className="align-items-start">
                                            <div className="fw-normal">
                                              Price (Active)
                                            </div>
                                            <div
                                              className="fw-bold"
                                              style={{ marginTop: "5px" }}
                                            >
                                              {priceAmount} {priceCurrency}
                                            </div>
                                          </ListGroup.Item>
                                        );
                                      })}
                                    </ListGroup>
                                  </Col>
                                  
                                </Row>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#company">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    Organization Setting
                                  </Card.Text>
                                </ListGroup.Item>

                                <Row md={2} style={{ marginTop: "35px" }}>
                                  <Col>
                                    <Card.Text
                                      className="text-center"
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        padding: "8px",
                                        backgroundColor: "#ffffff",
                                        borderRadius: "12px",
                                      }}
                                    >
                                      Organization
                                    </Card.Text>

                                    <ListGroup>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">Name</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.name}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">ID</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.id}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">Type</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.typeCN}
                                        </div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>

                                  <Col>
                                    <Card.Text
                                      className="text-center"
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        padding: "8px",
                                        backgroundColor: "#ffffff",
                                        borderRadius: "12px",
                                      }}
                                    >
                                      Personal Information
                                    </Card.Text>
                                    <ListGroup>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">
                                          Name（First Name）
                                        </div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.info.firstName}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">
                                          Name（Last Name）
                                        </div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.info.lastName}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">Introduction</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.description}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">Phone</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.info.phone}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">WeChat</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.info.wechat}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-bold">Work</div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {post.info.work}
                                        </div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                </Row>

                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ marginTop: "25px" }}
                                >
                                  <Button
                                    variant="dark"
                                    style={{
                                      marginTop: "15px",
                                      width: "75%",
                                      height: "40px",
                                      borderRadius: "20px",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => routeChangeGroupInfo()}
                                  >
                                    Modify Info
                                  </Button>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#onboard">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    {post.live
                                      ? "Onboarding"
                                      : "Onboarding (Not Live)"}
                                  </Card.Text>
                                  <DropdownButton
                                    alignRight
                                    title={!post.live ? "Publish" : "Already Live"}
                                    id="dropdown-menu-align-right"
                                    variant={
                                      !post.live
                                        ? "outline-danger"
                                        : "outline-success"
                                    }
                                    onSelect={(e) => {
                                      if (e == "publish-web") {
                                        //publish
                                        fires
                                          .collection("groups")
                                          .doc(post.id)
                                          .update({
                                            live: true,
                                            liveAt: timestampNow,
                                            status: "live",
                                          })
                                          .then((error) => {
                                            window.location.reload();
                                          });
                                      } else if (e == "unpublish-web") {
                                        fires
                                          .collection("groups")
                                          .doc(post.id)
                                          .update({
                                            live: false,
                                            status: "pending",
                                          })
                                          .then((error) => {
                                            window.location.reload();
                                          });
                                      } else if (e == "view-onboard") {
                                        window.location.href = "/onboarding";
                                      }
                                    }}
                                  >
                                    <Dropdown.Item
                                      eventKey="publish-web"
                                      style={{
                                        color: "darkgreen",
                                        fontWeight: "bold",
                                      }}
                                      hidden={post.live}
                                    >
                                      Go LIVE!
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="unpublish-web"
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                      hidden={!post.live}
                                    >
                                      Take it down
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item eventKey="view-onboard">
                                      Onboard
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </ListGroup.Item>
                                {obError && (
                                  <Alert variant="danger">{obError}</Alert>
                                )}
                                
                              </Tab.Pane>

                              {showAdminAllUserPane()}
                              {showAdminAllPaymentPane()}
                              {showAdminAllSentEmails()}
                              {showAdminAllEmailTemplates()}
                              {showAdminAllMarketingEmailAddress()}
                              {showAdminSponsors()}
                              {showAdminOrders()}

                              <Tab.Pane eventKey="#showdata">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    主页展示
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <ButtonGroup className="align-items-center justify-content-center">
                                      <Button
                                        variant={
                                          post.namecardType == 0
                                            ? "dark"
                                            : "outline-dark"
                                        }
                                        onClick={() => {}}
                                      >
                                        Personal Information
                                      </Button>
                                      <Button
                                        variant={
                                          post.namecardType == 1
                                            ? "dark"
                                            : "outline-dark"
                                        }
                                        onClick={() => {}}
                                      >
                                        Organization Information
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </ListGroup.Item>

                                {actionSuccess && (
                                  <Alert variant="success">
                                    {actionSuccess}
                                  </Alert>
                                )}

                                <Row md={2}>
                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          语言
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            post.lang == "en"
                                              ? "English"
                                              : post.lang == "zhT"
                                              ? "繁体中文"
                                              : "简体中文"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={"outline-dark"}
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "zh") {
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ lang: "zh" })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "改变语言为中文"
                                                  );
                                                });
                                            } else if (e == "en") {
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ lang: "en" })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "改变语言为英语"
                                                  );
                                                });
                                            } else if (e == "zhT") {
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ lang: "zhT" })
                                                .then((error) => {});
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="en"
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            English
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="zh"
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            简体中文
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="zhT"
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            繁体中文
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        请您选择您想要显示的语言，语言包括简体中文和英语，繁体中文的版本正在制作中
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          是否允许我们帮助推荐您的网站
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            post.status == "live"
                                              ? "已允许"
                                              : "不允许"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            post.status == "live"
                                              ? "outline-success"
                                              : "outline-danger"
                                          }
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "yes") {
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ status: "live" })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "设置推荐成功"
                                                  );
                                                });
                                            } else {
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ status: "pending" })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "取消推荐成功"
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="yes"
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            允许
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="no"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            不允许
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        您的网站将会出现在推荐首页，会被更多人看到，为您带来额外的流量和曝光
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          添加YouTube视频
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            gStyles.youtube
                                              ? "已添加"
                                              : "未添加"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            gStyles.youtube
                                              ? "outline-success"
                                              : "outline-danger"
                                          }
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "yes") {
                                              window.location.href =
                                                "/onboarding#4";
                                            } else {
                                              gStyles.youtube = false;
                                              gStyles.youtubeId = "";
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已取消YouTube视频"
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="yes"
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            添加
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="no"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            不添加
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        您将会有一个YouTube视频出现在您的首页
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          添加互动
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            gStyles.qa ? "已添加" : "未添加"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            gStyles.qa
                                              ? "outline-success"
                                              : "outline-dark"
                                          }
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "create") {
                                              window.location.href =
                                                "/create-interaction";
                                            } else if (e == "cancel") {
                                              gStyles.qa = false;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已取消互动"
                                                  );
                                                });
                                            } else {
                                              gStyles.qa = true;
                                              gStyles.qaId = e;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已添加" + gStyles.qaId
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          {orgInteractions.map((interItem) => {
                                            return (
                                              <Dropdown.Item
                                                eventKey={interItem.id}
                                                style={{
                                                  color: "black",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {interItem.title}
                                              </Dropdown.Item>
                                            );
                                          })}
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="create"
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            新建匿名问答
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="cancel"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            取消
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        {"您可以添加匿名Q&A"}
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          回复表单
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            gStyles.feedback
                                              ? "已启动"
                                              : "未启动"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            gStyles.feedback
                                              ? "outline-success"
                                              : "outline-danger"
                                          }
                                          style={{ marginTop: "15px" }}
                                          onSelect={(e) => {
                                            if (e == "start") {
                                              gStyles.feedback = true;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已启动回复表单"
                                                  );
                                                });
                                            } else if (e == "stop") {
                                              gStyles.feedback = false;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已取消回复表单"
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="start"
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            启动
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="stop"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            禁止
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        {gStyles.feedback
                                          ? "回复表单已启动，当前访问您的页面的用户可进行留言反馈"
                                          : "回复表单未启动，如果开启将允许访问您页面的用户留言反馈"}
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          内容导航栏
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            gStyles.navigation
                                              ? "已启动"
                                              : "未启动"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            gStyles.navigation
                                              ? "outline-success"
                                              : "outline-danger"
                                          }
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "start") {
                                              gStyles.navigation = true;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已启动内容导航栏"
                                                  );
                                                });
                                            } else if (e == "stop") {
                                              gStyles.navigation = false;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已启动内容导航栏"
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="start"
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            启动
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="stop"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            禁止
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        内容导航栏将帮助您的用户更快找到信息，一经启动，将会展示在您的名字下方
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          显示文章
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            gStyles.article
                                              ? "已显示"
                                              : "未显示"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            gStyles.article
                                              ? "outline-success"
                                              : "outline-danger"
                                          }
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "start") {
                                              gStyles.article = true;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已显示文章"
                                                  );
                                                });
                                            } else if (e == "stop") {
                                              gStyles.article = false;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已关闭文章显示"
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="start"
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            显示
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="stop"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            不显示
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        您发布的文章将会出现在您的名片页面，如果您不想展示文章请选择不展示
                                      </Card.Text>
                                    </Card>
                                  </Col>

                                  <Col>
                                    <Card
                                      style={{
                                        marginTop: "35px",
                                        padding: "25px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-start">
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          显示活动
                                        </Form.Label>
                                        <DropdownButton
                                          title={
                                            gStyles.event ? "已显示" : "未显示"
                                          }
                                          id="dropdown-menu-align-right"
                                          variant={
                                            gStyles.event
                                              ? "outline-success"
                                              : "outline-danger"
                                          }
                                          style={{}}
                                          onSelect={(e) => {
                                            if (e == "start") {
                                              gStyles.event = true;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已显示活动"
                                                  );
                                                });
                                            } else if (e == "stop") {
                                              gStyles.event = false;
                                              setGStyles(gStyles);
                                              fires
                                                .collection("groups")
                                                .doc(post.id)
                                                .update({ style: gStyles })
                                                .then((error) => {
                                                  setActionSuccess(
                                                    "已关闭活动显示"
                                                  );
                                                });
                                            }
                                          }}
                                        >
                                          <Dropdown.Item
                                            eventKey="start"
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            显示活动
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          <Dropdown.Item
                                            eventKey="stop"
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            不显示活动
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                      <Card.Text style={{ marginTop: "10px" }}>
                                        您发布的活动将会出现在您的名片页面，如果您不想展示活动请选择不展示
                                      </Card.Text>
                                    </Card>
                                  </Col>
                                </Row>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#links">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    Links
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        routeToCreateLinks();
                                      }}
                                    >
                                      New
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"选项"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {}}
                                    ></DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">我的链接</div>
                                    <div className="fw-bold">
                                      查看和修改链接
                                    </div>
                                  </ListGroup.Item>
                                  {orgLinks.map((linkItm) => {
                                    return (
                                      <ListGroup.Item className="align-items-start">
                                        <div
                                          className="fw-bold"
                                          style={{ marginBottom: "15px" }}
                                        >
                                          {linkItm.name}
                                        </div>
                                        <div className="fw-normal">
                                          {linkItm.url}
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#resources">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    资源库
                                  </Card.Text>
                                </ListGroup.Item>
                                <ListGroup>
                                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">我的资源库</div>
                                    <Button
                                      variant="dark"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        routeToResources();
                                      }}
                                    >
                                      查看我的上传
                                    </Button>
                                  </ListGroup.Item>
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#team">
                                <div className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "25px",
                                    }}
                                  >
                                    我的团队
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        handleShowAddTeam();
                                      }}
                                    >
                                      邀请团队成员
                                    </Button>
                                    <Button
                                      variant="dark"
                                      alignRight
                                      onClick={() => {
                                        routeChangeGroupInfo();
                                      }}
                                    >
                                      组织信息
                                    </Button>
                                  </div>
                                </div>

                                <ListGroup style={{ marginTop: "15px" }}>
                                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">我的账户</div>
                                    <div className="fw-bold">
                                      {auth.currentUser.email}
                                    </div>
                                  </ListGroup.Item>
                                </ListGroup>

                                <ListGroup style={{ marginTop: "15px" }}>
                                  {orgTeam.map((teamItm) => {
                                    return (
                                      <ListGroup.Item>
                                        <div className="d-flex justify-content-between align-items-start">
                                          <div className="fw-bold">
                                            {teamItm.name}
                                          </div>
                                          <div className="fw-normal">
                                            {teamItm.email}
                                          </div>
                                        </div>
                                        <div style={{ marginTop: "5px" }}>
                                          <Card.Link
                                            style={{
                                              color: "darkgreen",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {teamItm.role == "member"
                                              ? "<成员>"
                                              : "<管理员>"}
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {teamItm.status == "pending"
                                              ? "已邀请"
                                              : "已绑定"}
                                          </Card.Link>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>

                                <Modal
                                  show={showAddTeam}
                                  onHide={handleCloseAddTeam}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>邀请团队成员</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Form>
                                      <Form.Group
                                        id="invite-email"
                                        style={{ marginTop: "5px" }}
                                      >
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          电子邮箱 *
                                        </Form.Label>
                                        <Form.Control
                                          type="email"
                                          ref={inviteEmailRef}
                                          rows={1}
                                          required
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        id="invite-name"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          姓名 *
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          ref={inviteNameRef}
                                          rows={1}
                                          required
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        id="invite-name"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                        >
                                          成员角色(默认为团队成员)
                                        </Form.Label>
                                        <br />
                                        <Button variant="outline-dark">
                                          团队成员
                                        </Button>
                                      </Form.Group>
                                    </Form>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseAddTeam}
                                    >
                                      关闭
                                    </Button>
                                    <Button
                                      variant="success"
                                      onClick={(e) => {
                                        const emailInv =
                                          inviteEmailRef.current.value;
                                        const nameInv =
                                          inviteNameRef.current.value;
                                        if (emailInv != "" && nameInv != "") {
                                          const teamMemberData = {
                                            email: emailInv,
                                            name: nameInv,
                                            status: "pending",
                                            statusComplete: false,
                                            isAdmin: false,
                                            role: "member",
                                            timestamp: timestampNow,
                                          };
                                          fires
                                            .collection("groups")
                                            .doc(post.id)
                                            .collection("teams")
                                            .doc(emailInv)
                                            .set(teamMemberData)
                                            .then(() => {
                                              handleCloseAddTeam();
                                            });
                                        } else {
                                        }
                                      }}
                                    >
                                      邀请
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#locations">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    我的门店地址
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {}}
                                    >
                                      新增地址
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"门店选项"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {
                                        if (e == "locations") {
                                        } else if (e == "create-new") {
                                        }
                                      }}
                                    >
                                      <Dropdown.Item eventKey="locations">
                                        我的门店
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="create-new">
                                        新增门店地址
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">我的门店</div>
                                    <div className="fw-bold">
                                      {orgLocations.length}个地址
                                    </div>
                                  </ListGroup.Item>
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#posts">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    我的动态
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        routeToCreatePost();
                                      }}
                                    >
                                      发布动态
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"动态选项"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {
                                        if (e == "posts") {
                                          routeToPost();
                                        } else if (e == "create-new") {
                                          routeToCreatePost();
                                        }
                                      }}
                                    >
                                      <Dropdown.Item eventKey="posts">
                                        我的动态
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="create-new">
                                        发布动态
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">我的动态</div>
                                    <div className="fw-bold">
                                      {orgPosts.length}个动态
                                    </div>
                                  </ListGroup.Item>
                                  {orgPosts.map((postItm) => {
                                    return (
                                      <ListGroup.Item className="align-items-start">
                                        <div className="d-flex justify-content-between align-items-start">
                                          <div className="fw-bold">
                                            {postItm.visibility == "all"
                                              ? "[全体可见]"
                                              : "[草稿]"}{" "}
                                            {postItm.postContent.substring(
                                              0,
                                              100
                                            )}
                                          </div>
                                          <DropdownButton
                                            alignRight
                                            title={"动态设置"}
                                            id="dropdown-menu-align-right"
                                            variant="outline-dark"
                                            onSelect={(e) => {
                                              if (e == "delete") {
                                                var confirmA =
                                                  window.confirm(
                                                    "你想要删除这个动态吗?"
                                                  );
                                                if (confirmA) {
                                                  fires
                                                    .collection("posts")
                                                    .doc(postItm.id)
                                                    .delete()
                                                    .then(() => {
                                                      window.location.reload();
                                                    });
                                                }
                                              }
                                            }}
                                          >
                                            <Dropdown.Item
                                              eventKey="modify"
                                              style={{
                                                color: "green",
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              修改动态
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              eventKey="delete"
                                              style={{
                                                color: "red",
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              删除动态
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        </div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "15px" }}
                                        >
                                          图片 {postItm.imageURLs.length} 发布于
                                          {postItm.timestamp
                                            .toDate()
                                            .toLocaleDateString("zh")}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              window.location.href =
                                                "/post/" + postItm.id;
                                            }}
                                          >
                                            查看动态
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {}}
                                          >
                                            修改动态
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "darkred",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              var confirmA =
                                                window.confirm(
                                                  "你想要删除这个动态吗?"
                                                );
                                              if (confirmA) {
                                                fires
                                                  .collection("posts")
                                                  .doc(postItm.id)
                                                  .delete()
                                                  .then(() => {
                                                    window.location.reload();
                                                  });
                                              }
                                            }}
                                          >
                                            删除动态
                                          </Card.Link>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#experiences">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    我的文章
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        routeToCreateExp();
                                      }}
                                    >
                                      写文章
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"文章选项"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {
                                        if (e == "exps") {
                                          routeToExps();
                                        } else if (e == "create-new") {
                                          routeToCreateExp();
                                        }
                                      }}
                                    >
                                      <Dropdown.Item eventKey="exps">
                                        我的文章
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="create-new">
                                        新建文章
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">我的文章</div>
                                    <div className="fw-bold">
                                      查看和修改文章
                                    </div>
                                  </ListGroup.Item>
                                  {orgExps.map((expItm) => {
                                    return (
                                      <ListGroup.Item className="align-items-start">
                                        <div className="d-flex justify-content-between align-items-start">
                                          <div className="fw-bold">
                                            {expItm.status == "live"
                                              ? "[已发布]"
                                              : "[草稿]"}{" "}
                                            {expItm.title}
                                          </div>
                                          <DropdownButton
                                            alignRight
                                            title={"文章设置"}
                                            id="dropdown-menu-align-right"
                                            variant="outline-dark"
                                            onSelect={(e) => {
                                              if (e == "publish") {
                                                if (expItm.status != "live") {
                                                  var confirmA =
                                                    window.confirm(
                                                      "你想要发布这篇文章吗?"
                                                    );
                                                  if (confirmA) {
                                                    timestampNow =
                                                      firebase.firestore.Timestamp.fromDate(
                                                        new Date()
                                                      );
                                                    fires
                                                      .collection("exps")
                                                      .doc(expItm.id)
                                                      .update({
                                                        status: "live",
                                                        timestamp: timestampNow,
                                                      })
                                                      .then((error) => {
                                                        if (error == null) {
                                                          window.location.href =
                                                            "/exp/" + expItm.id;
                                                        }
                                                      });
                                                  }
                                                }
                                              } else if (e == "delete") {
                                                var confirmA =
                                                  window.confirm(
                                                    "你想要删除这篇文章吗?"
                                                  );
                                                if (confirmA) {
                                                  fires
                                                    .collection("exps")
                                                    .doc(expItm.id)
                                                    .delete()
                                                    .then(() => {
                                                      window.location.reload();
                                                    });
                                                }
                                              }
                                            }}
                                          >
                                            <Dropdown.Item
                                              eventKey="publish"
                                              style={{
                                                color: "green",
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              发布文章
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              eventKey="delete"
                                              style={{
                                                color: "red",
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              删除文章
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        </div>

                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "15px" }}
                                        >
                                          阅读量 {expItm.viewCount} 发布于
                                          {expItm.timestamp
                                            .toDate()
                                            .toLocaleDateString("zh")}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              window.location.href =
                                                "/exp/" + expItm.id;
                                            }}
                                          >
                                            查看文章
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              window.location.href =
                                                "/create-exp/" + expItm.id;
                                            }}
                                          >
                                            修改文章
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              window.location.href =
                                                "/article-analytics/" +
                                                expItm.id;
                                            }}
                                          >
                                            查看数据和评论
                                          </Card.Link>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane
                                eventKey="#events"
                                style={{ marginTop: "25px" }}
                              >
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    {"All Events "}
                                    ({orgEvents.length})
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        if (adminStatus) {
                                          window.location.href =
                                            "/create-live-event";
                                        } else {
                                          window.location.href =
                                            "/create-live-event";
                                        }
                                      }}
                                    >
                                      {"Create"}
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"Options"}
                                      hidden={!adminStatus}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {
                                        if (e == "upcoming-events") {
                                          loadAllEvents(adminStatus);
                                        } else if (e == "past-events") {
                                          loadAllPastEvents(adminStatus);
                                        }
                                      }}
                                    >
                                      <Dropdown.Item eventKey="upcoming-events">
                                        Upcoming
                                      </Dropdown.Item>
                                      <Dropdown.Item eventKey="past-events">
                                        Ended
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="create-event">
                                        Create Event
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>
                                {orgEvents.map((eventItm) => {
                                  var drawId = "";
                                  var eventStartDate = firebase.firestore.Timestamp.fromDate(new Date()).toDate()
                                  var eventEndDate = firebase.firestore.Timestamp.fromDate(new Date()).toDate()
                                  let currentD = new Date()
                                  var eventStatus = "current"
                                  var eventPriceCount = 1

                                  if (eventItm.startDate != null){
                                    eventStartDate = eventItm.startDate.toDate()
                                  }
                                  if (eventItm.endDate != null){
                                    eventEndDate = eventItm.endDate.toDate()
                                  }

                                  
                                  if (currentD < eventStartDate){
                                    eventStatus = "future"
                                  }
                                  else if (eventStartDate <= currentD && eventEndDate > currentD){
                                    eventStatus = "current"
                                  }
                                  else{
                                    eventStatus = "past"
                                  }
                                  if (eventItm.prices != null){
                                    eventPriceCount = Object.keys(eventItm.prices).length
                                  }

                                  if (eventItm.drawItems != null) {
                                    if (
                                      Object.keys(eventItm.drawItems).length > 0
                                    ) {
                                      drawId = Object.keys(
                                        eventItm.drawItems
                                      )[0];
                                    }
                                  }
                                  return (
                                    <Card
                                      className="border-0"
                                      style={{
                                        width: "100%",
                                        marginTop: "25px",
                                      }}
                                    >
                                      <Card.Body>
                                        <div
                                          className="d-flex align-items-center"
                                          style={{
                                            alignSelf: "center",
                                            display: "flex",
                                            width: "100%",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <Card.Img
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              objectFit: "cover",
                                            }}
                                            hidden={true}
                                            src={eventItm.photoURL}
                                            
                                          />
                                          <div style={{ marginLeft: "0px" }}>
                                            <div
                                              className="d-flex align-items-start"
                                              style={{ marginBottom: "8px" }}
                                            >
                                              <Badge
                                                bg={
                                                  eventStatus == "past"
                                                    ? "danger"
                                                    : "success"
                                                }
                                                style={{}}
                                                pill
                                              >
                                                {eventStatus == "future"
                                                  ? "Upcoming"
                                                  : eventStatus == "current" 
                                                  ? "Ongoing" 
                                                  : "Past"}
                                              </Badge>
                                              <Badge
                                                bg={
                                                  eventItm.priceType == "FREE"
                                                    ? "success"
                                                    : "danger"
                                                }
                                                style={{ marginLeft: "8px" }}
                                                pill
                                              >
                                                {eventItm.priceType == "FREE"
                                                  ? "Free"
                                                  : eventPriceCount + " Prices From ($" +
                                                    eventItm.price +
                                                    ")"}
                                              </Badge>
                                              <Badge
                                                bg={
                                                  eventItm.approved
                                                    ? "success"
                                                    : "danger"
                                                }
                                                hidden={!adminStatus}
                                                style={{ marginLeft: "8px" }}
                                                pill
                                              >
                                                {eventItm.approved
                                                  ? "Recommended"
                                                  : "NA Recom"}
                                              </Badge>
                                            </div>

                                            <Card.Text
                                              style={{
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                                color: "black",
                                                margin: 0,
                                              }}
                                            >
                                              {eventItm.title}
                                            </Card.Text>
                                            <Card.Text
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "normal",
                                                color: "black",
                                                margin: 0,
                                              }}
                                            >
                                              {eventItm.address.fullAddress}
                                            </Card.Text>
                                            <Card.Text
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "normal",
                                                color: "black",
                                                margin: 0,
                                              }}
                                            >
                                              {eventStartDate
                                                .toLocaleString("en")}
                                              {" - "}
                                              {eventEndDate
                                                .toLocaleString("en")}
                                            </Card.Text>
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            marginTop: "15px",
                                            marginBottom: "15px",
                                          }}
                                        >
                                          <Button
                                            variant="secondary"
                                            hidden={isMobile}
                                            style={{
                                              width: "120px",
                                              height: "40px",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              marginTop: "5px",
                                              marginBottom: "15px",
                                              marginRight: "15px",
                                              marginLeft: "0px",
                                              borderRadius: "20px",
                                            }}
                                            onClick={() => {
                                              window.location.href =
                                                "/manage-event/" +
                                                eventItm.id +
                                                "#basic-info";
                                            }}
                                          >
                                            {"Manage"}
                                          </Button>

                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              window.open(
                                                "https://tickets.evtgo.com/" +
                                                  eventItm.id
                                              );
                                            }}
                                          >
                                            {"View Event"}
                                          </Card.Link>

                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            href={
                                              "/event-ticket/" + eventItm.id
                                            }
                                            onClick={() => {
                                              window.location.href =
                                                "/event-ticket/" + eventItm.id;
                                            }}
                                          >
                                            {"Ticketing"}
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            href={
                                              "/event-checkin/" + eventItm.id
                                            }
                                            onClick={() => {
                                              window.location.href =
                                                "/event-checkin/" + eventItm.id;
                                            }}
                                          >
                                            {"Check-Ins"}
                                          </Card.Link>
                                          <Card.Link
                                            hidden={!adminStatus}
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              if (
                                                adminStatus &&
                                                eventItm.id != null &&
                                                eventItm.id != ""
                                              ) {
                                                if (eventItm.approved) {
                                                  //disapprove
                                                  const dConfirm =
                                                    window.confirm(
                                                      "您确定要取消推荐吗？取消推荐会损失自然流量"
                                                    );
                                                  if (dConfirm) {
                                                    fires
                                                      .collection("events")
                                                      .doc(eventItm.id)
                                                      .update({
                                                        approved: false,
                                                      })
                                                      .then((updated) => {
                                                        loadAllEvents(
                                                          adminStatus
                                                        );
                                                      });
                                                  }
                                                } else {
                                                  const dConfirm =
                                                    window.confirm(
                                                      "Are you sure to approve?"
                                                    );
                                                  if (dConfirm) {
                                                    fires
                                                      .collection("events")
                                                      .doc(eventItm.id)
                                                      .update({
                                                        approved: true,
                                                      })
                                                      .then((updated) => {
                                                        loadAllEvents(
                                                          adminStatus
                                                        );
                                                      });
                                                  }
                                                }
                                              }
                                            }}
                                          >
                                            {eventItm.approved
                                              ? "Cancel Approve"
                                              : "Approve"}
                                          </Card.Link>
                                          <Card.Link
                                            hidden={!adminStatus}
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              //reset report
                                              setAdminAllPaymentDataPerEventId(
                                                {}
                                              );
                                              setAdminAllPaymentsPerEventId([]);
                                              setCurrentEventViews({
                                                total: 0,
                                                today: 0,
                                                sourceTypes: [],
                                                sources: {
                                                  wechat: 0,
                                                  browser: 0,
                                                  linkedin: 0,
                                                  fb: 0,
                                                },
                                              });
                                              setCurrentEventTickets({
                                                total: 0,
                                                today: 0,
                                                sources: {
                                                  paid: 0,
                                                  paidToday: 0,
                                                  free: 0,
                                                  freeToday: 0,
                                                },
                                              });
                                              setCurrentEventDetails(eventItm);
                                              loadCurrentEventWithDetailsReport(
                                                eventItm
                                              );
                                              handleShowCreateEventReportItem();
                                              if (
                                                eventItm.id != "" &&
                                                eventItm.id != null
                                              ) {
                                                loadUserInfoPerEventId(
                                                  eventItm.id
                                                );
                                              }
                                            }}
                                          >
                                            Event Report
                                          </Card.Link>
                                          
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  );
                                })}

                                {/* <Row md={3}>
              {
                orgEvents.map((eveItm)=>{
                  var drawId = ""
              if (eveItm.drawItems != null){
                if (Object.keys(eveItm.drawItems).length > 0){
                  drawId = Object.keys(eveItm.drawItems)[0]
                }
              }
                  return <Col>
                  <Card style={{marginTop: '25px'}}>
                    <Card.Img variant="top" src={eveItm.photoURL} style={{}}/>
                    <Card.Title className="text-center" style={{marginTop: '15px', marginBottom: '15px'}}>{eveItm.title}</Card.Title>
                    <div className="d-flex justify-content-center align-items-center" style={{marginBottom: '10px'}}>
                      <Badge bg={eveItm.status == 'ended' ? 'danger' : 'success'} style={{width: "100px"}}>{eveItm.status == 'ended' ? '已结束' : '正在进行'}</Badge>
                    </div>
                    <Card.Text style={{marginLeft: '15px', marginRight: '15px', fontWeight: 'bold'}}>活动地址: {eveItm.address.fullAddress}</Card.Text>
                    <Card.Text style={{marginLeft: '15px', marginRight: '15px', fontWeight: 'bold'}}>开始时间: {eveItm.startDate.toDate().toLocaleString()}</Card.Text>
                    <Button variant="secondary"
                            style={{
                              width: '120px',
                              height: '40px',
                              alignItems:'center', 
                              justifyContent:'space-between', 
                              fontSize: '15px', 
                              fontWeight:'bold', 
                              marginTop: "5px", 
                              marginBottom: "15px",
                              marginLeft: "15px",
                              borderRadius: '20px',
                              }} onClick={()=> {
                                window.location.href = '/manage-event/'+ eveItm.id + '#basic-info'
                              } }>管理活动 →</Button>

                    <Button variant="success"
                            style={{
                              width: '150px',
                              height: '40px',
                              alignItems:'center', 
                              justifyContent:'space-between', 
                              fontSize: '15px', 
                              fontWeight:'bold', 
                              marginTop: "5px", 
                              marginBottom: "15px",
                              marginLeft: "15px",
                              borderRadius: '20px',
                              }} onClick={()=> {
                                window.location.href = "/live-event-dashboard/"+eveItm.id
                              } }>开始活动/抽奖 →</Button>

                    <div style={{
                      marginBottom: "15px",
                      marginLeft: "15px",
                    }}>
                          <Card.Link style={{color: 'black'}} href={"https://rileyevents.com/"+eveItm.id}
                          >查看活动</Card.Link>
                          <Card.Link style={{color: 'black'}} href={"/modify-event/"+eveItm.id} onClick={()=>{
                            window.location.href = "/modify-event/"+eveItm.id
                          }}
                          >修改活动</Card.Link>
                          <Card.Link style={{color: 'black'}} href={"/event-ticket/"+eveItm.id} onClick={()=>{
                            window.location.href = "/event-ticket/"+eveItm.id
                          }}
                          >活动出票</Card.Link>
                          <Card.Link style={{color: 'black'}} href={"/event-checkin/"+eveItm.id} onClick={()=>{
                            window.location.href = "/event-checkin/"+eveItm.id
                          }}
                          >活动签到</Card.Link>
                          

                    </div>
                  </Card>
                  </Col>
                })
              }
            </Row> */}
                              </Tab.Pane>

                              <Tab.Pane eventKey="#members">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    Membership
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        handleShowAddMember();
                                      }}
                                    >
                                      Invite
                                    </Button>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        handleShowMemberRequest();
                                      }}
                                    >
                                      Requests
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"Members"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {}}
                                    ></DropdownButton>
                                  </div>
                                </ListGroup.Item>
                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">Members</div>
                                    <div className="fw-bold">
                                      {orgMembers.length} Members
                                    </div>
                                  </ListGroup.Item>
                                  {orgMembers.map((memberItm) => {
                                    return (
                                      <ListGroup.Item className="align-items-start">
                                        <div style={{ marginLeft: "5px" }}>
                                          <Card.Text
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              marginBottom: "0px",
                                              color: "black",
                                            }}
                                          >
                                            {memberItm.name}
                                          </Card.Text>
                                          <Card.Text
                                            class="text-secondary"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            Time:{" "}
                                            {memberItm.timestamp
                                              .toDate()
                                              .toLocaleString("zh")}
                                          </Card.Text>
                                        </div>
                                        <div style={{ marginLeft: "5px" }}>
                                          <Card.Text
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "normal",
                                              marginBottom: "0px",
                                              color: "black",
                                            }}
                                          >
                                            Email: {memberItm.email}
                                          </Card.Text>
                                          <Card.Text
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "normal",
                                              color: "black",
                                            }}
                                          >
                                            Status: {memberItm.status}
                                          </Card.Text>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#service-extension">
                                <div className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    发现更多服务
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {}}
                                    >
                                      我的服务
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"创建服务"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {}}
                                    ></DropdownButton>
                                  </div>
                                </div>

                                <Row md={3}>
                                  {allServices.map((serviceItm) => {
                                    return (
                                      <Col>
                                        <Card>
                                          <div className="d-flex align-items-center justify-content-center">
                                            <img
                                              src={serviceItm.photoURL}
                                              style={{
                                                width: "250px",
                                                height: "250px",
                                              }}
                                              hidden={
                                                serviceItm.photoURL == "" ||
                                                serviceItm.photoURL == null
                                              }
                                            />
                                          </div>
                                          <div className="d-flex align-items-center justify-content-center">
                                            <Badge
                                              bg="success"
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                marginTop: "25px",
                                              }}
                                              pill
                                            >
                                              {serviceItm.service}
                                            </Badge>
                                          </div>
                                          <Card.Title
                                            className="text-center"
                                            style={{
                                              margin: "8px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {serviceItm.name}
                                          </Card.Title>
                                          <Card.Text style={{ margin: "16px" }}>
                                            {serviceItm.description}
                                          </Card.Text>
                                          <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{ margin: "16px" }}
                                          >
                                            <Button
                                              className="border-0"
                                              variant="dark"
                                              style={{
                                                fontWeight: "bold",
                                                marginTop: "25px",
                                                width: "125px",
                                              }}
                                              onClick={() => {}}
                                            >
                                              查看详情
                                            </Button>
                                          </div>
                                        </Card>
                                      </Col>
                                    );
                                  })}
                                </Row>
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ marginTop: "50px" }}
                                >
                                  <Card.Link
                                    style={{
                                      fontSize: "22px",
                                      fontWeight: "bold",
                                    }}
                                    href="/extensions"
                                  >
                                    发现更多服务
                                  </Card.Link>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#interactions">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    我的互动
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {}}
                                    >
                                      我的互动
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"创建互动"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {
                                        if (e == "qna") {
                                          routeToCreateQnA();
                                        } else if (e == "forms") {
                                          routeToCreateForms();
                                        }
                                      }}
                                    >
                                      <Dropdown.Item eventKey="qna">
                                        {"创建问答Q&A"}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="forms">
                                        {"创建问卷FORMS"}
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  {orgInteractions.map((interactItm) => {
                                    return (
                                      <ListGroup.Item
                                        className="align-items-start"
                                        onClick={() => {
                                          window.location.href =
                                            "/view-interaction/" +
                                            interactItm.id;
                                        }}
                                      >
                                        <div className="fw-bold">
                                          {interactItm.title}{" "}
                                          {interactItm.type == "q&a"
                                            ? "(Q&A问答)"
                                            : "(Form问卷)"}
                                        </div>
                                        <div className="fw-normal">
                                          {interactItm.description}
                                        </div>
                                        <div
                                          className="fw-normal"
                                          style={{ marginTop: "15px" }}
                                        >
                                          创建时间:{" "}
                                          {interactItm.timestamp
                                            .toDate()
                                            .toLocaleString("zh")}
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#quotes">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    我的报价
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        routeToQuotes();
                                      }}
                                    >
                                      我的报价
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"创建互动"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {}}
                                    >
                                      <Dropdown.Item eventKey="qna">
                                        {"我的报价"}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="forms">
                                        {"其他"}
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">报价清单</div>
                                    <div className="fw-normal">
                                      {orgQuotes.length}个项目表单
                                    </div>
                                  </ListGroup.Item>
                                  {orgQuotes.map((quoItm) => {})}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#emaillists">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    我的邮件模版
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="success"
                                      style={{ marginRight: "5px" }}
                                      onClick={() => {
                                        routeToQuotes();
                                      }}
                                    >
                                      我的邮件群
                                    </Button>
                                    <DropdownButton
                                      alignRight
                                      title={"选项"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {
                                        if (e == "qna") {
                                        }
                                      }}
                                    >
                                      <Dropdown.Item eventKey="qna">
                                        {"我的报价"}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="forms">
                                        {"其他"}
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">电子邮件</div>
                                    <div className="fw-normal">
                                      {orgEmailLists.length}个电子邮件表单
                                    </div>
                                  </ListGroup.Item>
                                  {orgEmailLists.map((emailItm) => {
                                    return (
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-normal">
                                          活动名称: {emailItm.eventTitle}
                                        </div>
                                        <div className="fw-normal">
                                          电子邮箱数: {emailItm.emails.length}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          <Card.Link
                                            style={{
                                              color: "darkgreen",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              if (emailItm.eventId != null) {
                                                window.location.href =
                                                  "/send-emails/" + emailItm.id;
                                              }
                                            }}
                                          >
                                            查看
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              if (
                                                emailItm.id != "" &&
                                                gData.id != ""
                                              ) {
                                                fires
                                                  .collection("groups")
                                                  .doc(gData.id)
                                                  .collection("emails")
                                                  .doc(emailItm.id)
                                                  .delete()
                                                  .then(() => {
                                                    window.location.reload();
                                                  });
                                              }
                                            }}
                                          >
                                            删除
                                          </Card.Link>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>

                              <Tab.Pane eventKey="#messages">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                      marginBottom: "45px",
                                    }}
                                  >
                                    Messages
                                  </Card.Text>
                                  <div style={{ display: "flex" }}>
                                    <DropdownButton
                                      alignRight
                                      title={"Options"}
                                      id="dropdown-menu-align-right"
                                      variant={"outline-dark"}
                                      onSelect={(e) => {}}
                                    >
                                      <Dropdown.Item eventKey="qna">
                                        {"Message"}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item eventKey="forms">
                                        {"Other"}
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </div>
                                </ListGroup.Item>

                                <ListGroup>
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ backgroundColor: "#EEE" }}
                                  >
                                    <div className="fw-bold">Messages</div>
                                    <div className="fw-normal">
                                      {orgFeedbacks.length} Messages
                                    </div>
                                  </ListGroup.Item>
                                  {orgFeedbacks.map((fbItm) => {
                                    return (
                                      <ListGroup.Item className="align-items-start">
                                        <div className="fw-normal">
                                          Name: {fbItm.name}
                                        </div>
                                        <div className="fw-normal">
                                          Email: {fbItm.email}
                                        </div>
                                        <div className="fw-normal">
                                          Content: {fbItm.content}
                                        </div>
                                        <div className="fw-normal">
                                          Date: {" "}
                                          {fbItm.timestamp
                                            .toDate()
                                            .toLocaleString("zh")}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          <Card.Link
                                            style={{
                                              color: "darkgreen",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                              window.location.href =
                                                "mailto:" + fbItm.email;
                                            }}
                                          >
                                            Reply
                                          </Card.Link>
                                          <Card.Link
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => {}}
                                          >
                                            Delete
                                          </Card.Link>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                </div>
              </Container>
            </>
          );
        } else {
          if (error == "NotFound") {
            return <Navigate to="/404" />;
          } else if (error == "NoAccess") {
            return <Navigate to="/404" />;
          }
        }
      })}
    </div>
  );
}
